import React, { FC, useState } from "react";
import styles from "./CompanyPage.module.scss";
import localized from "helpers/localized";
import Input from "kit/input/Input";
import Button from "kit/button/Button";
import Switch from "kit/switch/Switch";
import Select from "kit/select/Select";
import { mainApi } from "modules/main/store/MainApi";
import { fetchNewEmployee } from "./store/CompanyAC";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { ReactComponent as DownloadIcon } from "assets/icons/Download.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/Delete.svg";
import { setError, setUserWithPhone } from "modules/main/store/MainSlice";
import {
  validateEmail,
  validateLengthEquals,
  validatePhone,
} from "helpers/validators";
import { fetchChangeUser } from "modules/profile/store/ProfileAC";
import { fetchChangeDealer } from "modules/dealers/store/DealersAC";

const CompanyPage: FC = () => {
  const dispatch = useDispatch();

  const main = useSelector((state: RootState) => state.main);
  const users = useSelector((state: RootState) => state.users.usersAll.items);

  const company = useSelector(
    (state: RootState) => state.profile.profile.dealers[0],
  );

  // данные ответственного лица
  const [firstName, setFirstName] = useState(
    company?.dealerManagers[0]?.firstName,
  );
  const [middleName, setMiddleName] = useState(
    company?.dealerManagers[0]?.middleName,
  );
  const [lastName, setLastName] = useState(
    company?.dealerManagers[0]?.lastName,
  );
  const [position, setPosition] = useState(
    company?.dealerManagers[0]?.position,
  );
  const [email, setEmail] = useState(company?.dealerManagers[0]?.email);
  const [phone, setPhone] = useState(company?.dealerManagers[0]?.phone);

  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);

  // данные о компании
  const [name, setName] = useState(company?.name || "");
  const [inn, setInn] = useState(company?.inn || "");
  const [ogrn, setOgrn] = useState(company?.ogrn || "");
  const [kpp, setKpp] = useState(company?.kpp || "");
  const [legalAddress, setLegalAddress] = useState(company?.legalAddress || "");
  const [address, setAddress] = useState(company?.address || "");

  const [innValid, setInnValid] = useState(true);
  const [ogrnValid, setOgrnValid] = useState(true);
  const [kppValid, setKppValid] = useState(true);

  // платежные данные
  const [bankAccount, setBankAccount] = useState(company?.bankAccount || "");
  const [bankName, setBankName] = useState(company?.bankName || "");
  const [bankBik, setBankBik] = useState(company?.bankBik || "");
  const [bankCorAccount, setBankCorAccount] = useState(
    company?.bankCorAccount || "",
  );

  const [bankAccountValid, setBankAccountValid] = useState(true);
  const [bankBikValid, setBankBikValid] = useState(true);
  const [bankCorAccountValid, setBankCorAccountValid] = useState(true);
  const [managers, setManagers] = useState(company?.dealerManagers || []);

  // договор и карта
  const [contract, setContract] = useState(null);
  const [card, setCard] = useState(null);

  // новый сотрудник
  const [newEmail, setNewEmail] = useState("");
  const [newPhone, setNewPhone] = useState("");

  const [newEmailValid, setNewEmailValid] = useState(true);
  const [newPhoneValid, setNewPhoneValid] = useState(true);

  const validateDataCompany = async () => {
    setInnValid(validateLengthEquals(inn, 10, true));
    setOgrnValid(validateLengthEquals(ogrn, 13, true));
    setKppValid(validateLengthEquals(kpp, 9, true));
    setBankAccountValid(validateLengthEquals(bankAccount, 20, false));
    setBankBikValid(validateLengthEquals(bankBik, 9, false));
    setBankCorAccountValid(validateLengthEquals(bankCorAccount, 20, false));

    if (
      validateLengthEquals(inn, 10, true) &&
      validateLengthEquals(ogrn, 13, true) &&
      validateLengthEquals(kpp, 9, true) &&
      validateLengthEquals(bankAccount, 20, false) &&
      validateLengthEquals(bankBik, 9, false) &&
      validateLengthEquals(bankCorAccount, 20, false)
    ) {
      dispatch(
        fetchChangeDealer({
          id: company.id,
          inn,
          kpp,
          ogrn,
          legalAddress,
          address,
          name,
          bankName,
          bankAccount,
          bankCorAccount,
          bankBik,
          userIds: managers?.map((a) => a.id) || [],
          contract,
          card,
        }),
      );
    }
  };

  const newUsers = () => {
    const newArray = [];
    for (let i = 0; i < users?.length; i++) {
      const newUser = { ...users[i] };
      delete newUser.dealers;
      delete newUser.login;
      delete newUser.loginType;
      newArray.push(newUser);
    }
    return newArray;
  };

  const validateDataUser = async () => {
    setEmailValid(validateEmail(email, true));
    setPhoneValid(validatePhone(phone, true));

    if (validateEmail(email, true) && validatePhone(phone, true)) {
      dispatch(
        fetchChangeUser({
          id: company?.dealerManagers[0]?.id,
          firstName,
          lastName,
          middleName,
          position,
          phone,
          email,
        }),
      );
    }
  };

  const validateNewEmployeeData = async () => {
    if (main.userWithPhone) {
      setNewPhoneValid(validatePhone(newPhone, true));
    } else {
      setNewEmailValid(validateEmail(newEmail, true));
    }

    if (
      main.userWithPhone
        ? validatePhone(newPhone, true)
        : validateEmail(newEmail, true)
    ) {
      dispatch(
        fetchNewEmployee({
          login: main.userWithPhone ? newPhone : newEmail,
          loginType: main.userWithPhone ? "phone" : "email",
          dealerIds: [company.id],
        }),
      );
      setNewPhone("");
      setNewEmail("");
    }
  };

  const contractSelected = async (e: any) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      if (
        file.name?.toLowerCase().split(".").pop() === "doc" ||
        file?.name?.toLowerCase().split(".").pop() === "docx"
      ) {
        setContract(e.target.files[0]);
      } else {
        dispatch(setError(localized.contractErrorDoc));
      }
    }
  };

  const cardSelected = async (e: any) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      if (
        file.name?.toLowerCase().split(".").pop() === "doc" ||
        file?.name?.toLowerCase().split(".").pop() === "docx"
      ) {
        setCard(e.target.files[0]);
      } else {
        dispatch(setError(localized.contractErrorDoc));
      }
    }
  };

  const inputContractRef = React.useRef();
  const inputCardRef = React.useRef();

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleFirst}>{localized.responsiblePerson}</div>
      <div className={styles.block}>
        <div className={styles.element}>
          <Input
            disabled
            id="firstName"
            value={firstName}
            label={localized.name}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFirstName(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            disabled
            id="middleName"
            value={middleName}
            label={localized.lastName}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMiddleName(e);
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            disabled
            id="lastName"
            value={lastName}
            label={localized.lastName}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLastName(e);
            }}
          />
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.element}>
          <Input
            disabled
            id="position"
            value={position}
            label={localized.position}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPosition(e);
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            disabled
            type="email"
            id="email"
            errorMessage={localized.emailError}
            value={email}
            label={localized.email + "*"}
            error={!emailValid}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e);
              setEmailValid(true);
            }}
            onBlur={() => {
              setEmailValid(validateEmail(email, false));
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            disabled
            type="phone"
            id="phone"
            label={localized.phone + "*"}
            value={phone}
            errorMessage={localized.phoneError}
            error={!phoneValid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPhone(e);
              setPhoneValid(true);
            }}
            onBlur={() => {
              setPhoneValid(validatePhone(phone, false));
            }}
          />
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.element}>
          <Button
            disabled
            id="saveDataUser"
            onClick={() => {
              validateDataUser();
            }}
          >
            {localized.saveDataUser}
          </Button>
        </div>
      </div>

      <div className={styles.title}>{localized.companyTitle}</div>

      <div className={styles.block}>
        <div className={styles.element}>
          <Input
            id="name"
            value={name}
            label={localized.companyName}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setName(e);
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            id="inn"
            error={!innValid}
            errorMessage={localized.innError}
            value={inn}
            allowed="numbers"
            label={localized.inn}
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInn(e);
              setInnValid(true);
            }}
            onBlur={() => {
              setInnValid(validateLengthEquals(inn, 10, false));
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            id="ogrn"
            error={!ogrnValid}
            errorMessage={localized.ogrnError}
            value={ogrn}
            allowed="numbers"
            label={localized.ogrn}
            length={13}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setOgrn(e);
              setOgrnValid(true);
            }}
            onBlur={() => {
              setOgrnValid(validateLengthEquals(ogrn, 13, false));
            }}
          />
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.element}>
          <Input
            id="kpp"
            error={!kppValid}
            errorMessage={localized.kppError}
            value={kpp}
            allowed="numbers"
            label={localized.kpp}
            length={9}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setKpp(e);
              setKppValid(true);
            }}
            onBlur={() => {
              setKppValid(validateLengthEquals(kpp, 9, false));
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            id="legalAddress"
            value={legalAddress}
            label={localized.legalAddress}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLegalAddress(e);
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            id="address"
            value={address}
            label={localized.actualAddress}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAddress(e);
            }}
          />
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.element}>
          <Button
            id="downloadContract"
            secondary
            onClick={() => {
              mainApi.downloadFile({
                url: "https://api.widget-ci.mgc-loyalty.ru/templates/examples/wholesale-example-phone.xls",
                name: localized.contractTemplate + ".doc",
              });
            }}
          >
            <div className={styles.downloadText}>
              {localized.downloadContract}
            </div>
            <div className={styles.download}>
              <DownloadIcon fill={"#000000"} />
            </div>
          </Button>
        </div>
        <div className={styles.fileWrapper}>
          <div className={styles.element}>
            <Button
              id="uploadContract"
              secondary
              onClick={() => {
                if (contract) {
                  setContract(null);
                } else {
                  inputContractRef.current.click();
                }
              }}
            >
              <div className={styles.downloadText}>
                {contract ? localized.deleteContract : localized.uploadContract}
              </div>
              <div className={contract ? styles.download : styles.upload}>
                {contract ? (
                  <DeleteIcon stroke={"#000000"} />
                ) : (
                  <DownloadIcon fill={"#000000"} />
                )}
              </div>
            </Button>
          </div>
          <input
            id="upload-file"
            className={styles.file}
            type="file"
            name="upload-file"
            value=""
            ref={inputContractRef}
            onChange={contractSelected}
          />
        </div>

        <div className={styles.fileWrapper}>
          <div className={styles.element}>
            <Button
              id="downloadOrganizationCard"
              secondary
              onClick={() => {
                if (card) {
                  setCard(null);
                } else {
                  inputCardRef.current.click();
                }
              }}
            >
              <div className={styles.downloadText}>
                {card
                  ? localized.deleteOrganizationCard
                  : localized.downloadOrganizationCard}
              </div>
              <div className={card ? styles.download : styles.upload}>
                {card ? (
                  <DeleteIcon stroke={"#000000"} />
                ) : (
                  <DownloadIcon fill={"#000000"} />
                )}
              </div>
            </Button>
          </div>
          <input
            id="upload-file"
            className={styles.file}
            type="file"
            name="upload-file"
            value=""
            ref={inputCardRef}
            onChange={cardSelected}
          />
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.element}>
          <Input
            id="bankName"
            value={bankName}
            label={localized.bankName}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBankName(e);
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            id="bankAccount"
            value={bankAccount}
            allowed="numbers"
            label={localized.rs}
            error={!bankAccountValid}
            errorMessage={localized.errorRS}
            length={20}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBankAccount(e);
            }}
            onBlur={() => {
              setBankAccountValid(validateLengthEquals(bankAccount, 20, false));
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            id="bankBik"
            allowed="numbers"
            value={bankBik}
            error={!bankBikValid}
            errorMessage={localized.errorBIK}
            label={localized.bik}
            length={9}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBankBik(e);
            }}
            onBlur={() => {
              setBankBikValid(validateLengthEquals(bankBik, 9, false));
            }}
          />
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.element}>
          <Input
            id="bankCorAccount"
            allowed="numbers"
            value={bankCorAccount}
            error={!bankCorAccountValid}
            errorMessage={localized.errorCor}
            label={localized.cr}
            length={20}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBankCorAccount(e);
            }}
            onBlur={() => {
              setBankCorAccountValid(
                validateLengthEquals(bankCorAccount, 20, false),
              );
            }}
          />
        </div>
        <div className={styles.element}>
          <Select
            id="managers"
            multiple
            label={localized.dealerManagers}
            value={managers}
            onChange={(value) => {
              setManagers(value);
            }}
            source="phone"
            list={newUsers()}
          />
        </div>
        <div className={styles.element}>
          <Button
            id="saveDataCompany"
            onClick={() => {
              validateDataCompany();
            }}
          >
            {localized.saveDataCompany}
          </Button>
        </div>
      </div>

      <div className={styles.title}>{localized.newEmp}</div>

      <div className={styles.blockEmployee}>
        <div className={styles.switch}>
          <Switch
            id="emailSwitch"
            title={localized.email.toLowerCase()}
            value={!main.userWithPhone}
            onChange={() => {
              dispatch(setUserWithPhone(!main.userWithPhone));
            }}
          />
        </div>
        {main.userWithPhone ? (
          <div className={styles.element}>
            <Input
              type="phone"
              id="newPhone"
              label={localized.phone}
              value={newPhone}
              errorMessage={localized.phoneError}
              error={!newPhoneValid}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewPhone(e);
                setNewPhoneValid(true);
              }}
              onBlur={() => {
                setNewPhoneValid(validatePhone(newPhone, true));
              }}
            />
          </div>
        ) : (
          <div className={styles.element}>
            <Input
              type="email"
              id="email"
              errorMessage={localized.emailError}
              value={newEmail}
              label={localized.email}
              length={50}
              error={!newEmailValid}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewEmail(e);
                setNewEmailValid(true);
              }}
              onBlur={() => {
                setNewEmailValid(validateEmail(newEmail, true));
              }}
            />
          </div>
        )}

        <div className={styles.element}>
          <Button
            id="addEmployee"
            onClick={() => {
              validateNewEmployeeData();
            }}
          >
            {localized.addEmployee}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompanyPage;
