import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboard: (state, action) => {},
  },
});

export const { setDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
