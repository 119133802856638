import React, { FC, useState } from "react";
import Input from "kit/input/Input";
import localized from "helpers/localized";
import styles from "./ProductsFilter.module.scss";

const ProductsFilter: FC = () => {
  const [number, setNumber] = useState("");
  const [productName, setProductName] = useState("");
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [collection, setCollection] = useState("");
  const [article, setArticle] = useState("");
  const [barcode, setBarcode] = useState("");
  const [gender, setGender] = useState("");
  const [reserve, setReserve] = useState("");
  const [remainderFrom, setRemainderFrom] = useState("");
  const [remainderTo, setRemainderTo] = useState("");
  const [ordersFrom, setOrdersFrom] = useState("");
  const [ordersTo, setOrdersTo] = useState("");
  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{localized.tableFilter}</div>

      <div className={styles.elements}>
        <div className={styles.element}>
          <Input
            id="number"
            value={number}
            label={localized.number}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setNumber(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="productName"
            value={productName}
            label={localized.productName}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setProductName(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="category"
            value={category}
            label={localized.category}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCategory(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="brand"
            value={brand}
            label={localized.brand}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBrand(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="collection"
            value={collection}
            label={localized.collection}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCollection(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="article"
            value={article}
            label={localized.article}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setArticle(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="barcode"
            value={barcode}
            label={localized.barcode}
            allowed="numbers"
            length={20}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBarcode(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="gender"
            value={gender}
            label={localized.gender}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setGender(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="reserve"
            value={reserve}
            label={localized.reserve}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setReserve(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="remainderFrom"
            value={remainderFrom}
            label={localized.remainder + " " + localized.from}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setRemainderFrom(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="remainderTo"
            value={remainderTo}
            label={localized.remainder + " " + localized.to}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setRemainderTo(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="ordersFrom"
            value={ordersFrom}
            label={localized.orders + " " + localized.from}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setOrdersFrom(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="ordersTo"
            value={ordersTo}
            label={localized.orders + " " + localized.to}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setOrdersTo(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="priceFrom"
            value={priceFrom}
            label={localized.price + " " + localized.from}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPriceFrom(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="priceTo"
            value={priceTo}
            label={localized.price + " " + localized.to}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPriceTo(e);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductsFilter;
