// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginPage_modal__ycIfP {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 500px;
  height: 458px;
}

.LoginPage_auth__xRn5I {
  width: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #000000;
  padding: 35px;
}

.LoginPage_icon__BOjXP {
  width: 25px;
  height: 25px;
  margin-bottom: 10px;
}

.LoginPage_background__81H4N {
  width: 100vw;
  position: absolute;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ebebeb;
}

.LoginPage_block__HDRGE {
  display: flex;
  align-items: center;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/modules/login/LoginPage.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,kBAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,UAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;EACA,UAAA;EACA,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,aAAA;EACA,2BAAA;EACA,4BAAA;EACA,sBAAA;EACA,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;AACF","sourcesContent":[".modal {\n  z-index: 2;\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  margin: auto;\n  width: 500px;\n  height: 458px;\n}\n\n.auth {\n  width: 500px;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  z-index: 2;\n  background-color: white;\n  border-radius: 20px;\n  border: 1px solid #000000;\n  padding: 35px;\n}\n\n.icon {\n  width: 25px;\n  height: 25px;\n  margin-bottom: 10px;\n}\n\n.background {\n  width: 100vw;\n  position: absolute;\n  z-index: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  height: 100vh;\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-color: #ebebeb;\n}\n\n.block {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `LoginPage_modal__ycIfP`,
	"auth": `LoginPage_auth__xRn5I`,
	"icon": `LoginPage_icon__BOjXP`,
	"background": `LoginPage_background__81H4N`,
	"block": `LoginPage_block__HDRGE`
};
export default ___CSS_LOADER_EXPORT___;
