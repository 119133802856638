// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductsPage_wrapper__B-bIO {
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100vw - 190px);
  padding: 10px;
  flex-direction: column;
}

.ProductsPage_title__BqqQC {
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  text-align: center;
  margin-bottom: 10px;
}

.ProductsPage_titleWrapper__kXbCQ {
  display: flex;
}

.ProductsPage_text__rQ5Rw {
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 0;
  margin-top: 5px;
  margin-bottom: 20px;
}

.ProductsPage_number__ji8XA {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.6;
}

.ProductsPage_filterWrapper__7qpjJ {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProductsPage_filter__\\+FooK {
  display: flex;
}

.ProductsPage_buttons__GWQvR {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.ProductsPage_button__Uarmx {
  margin-right: 15px;
  width: 250px;
}

.ProductsPage_extraButton__c8Vwr {
  margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/modules/products/ProductsPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,2BAAA;EACA,0BAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".wrapper {\n  height: 100vh;\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  width: calc(100vw - 190px);\n  padding: 10px;\n  flex-direction: column;\n}\n\n.title {\n  font-size: 24px;\n  font-weight: 400;\n  line-height: 31px;\n  text-align: center;\n  margin-bottom: 10px;\n}\n\n.titleWrapper {\n  display: flex;\n}\n\n.text {\n  color: #000000;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 0;\n  margin-top: 5px;\n  margin-bottom: 20px;\n}\n\n.number {\n  margin-left: 10px;\n  font-size: 13px;\n  font-weight: 400;\n  line-height: 20px;\n  opacity: 0.6;\n}\n\n.filterWrapper {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.filter {\n  display: flex;\n}\n\n.buttons {\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 20px;\n}\n\n.button {\n  margin-right: 15px;\n  width: 250px;\n}\n\n.extraButton {\n  margin-top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ProductsPage_wrapper__B-bIO`,
	"title": `ProductsPage_title__BqqQC`,
	"titleWrapper": `ProductsPage_titleWrapper__kXbCQ`,
	"text": `ProductsPage_text__rQ5Rw`,
	"number": `ProductsPage_number__ji8XA`,
	"filterWrapper": `ProductsPage_filterWrapper__7qpjJ`,
	"filter": `ProductsPage_filter__+FooK`,
	"buttons": `ProductsPage_buttons__GWQvR`,
	"button": `ProductsPage_button__Uarmx`,
	"extraButton": `ProductsPage_extraButton__c8Vwr`
};
export default ___CSS_LOADER_EXPORT___;
