// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UsersList_wrapperTable__jy53g {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100vw - 190px);
  padding: 10px;
  flex-direction: column;
}

.UsersList_titleTable__kAH6I {
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  text-align: center;
  margin-bottom: 10px;
}

.UsersList_filterWrapper__2XEY7 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.UsersList_filter__5vH5U {
  display: flex;
}

.UsersList_titleWrapper__k9YPC {
  display: flex;
}

.UsersList_number__EcbjF {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.6;
}

.UsersList_text__De0MW {
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 0;
  margin-top: 5px;
  margin-bottom: 20px;
}

.UsersList_table__z2VtC {
  width: calc(100vw - 210px);
}

.UsersList_extraButton__XIEGR {
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/modules/users/components/UsersList/UsersList.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,2BAAA;EACA,0BAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".wrapperTable {\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  width: calc(100vw - 190px);\n  padding: 10px;\n  flex-direction: column;\n}\n\n.titleTable {\n  font-size: 24px;\n  font-weight: 400;\n  line-height: 31px;\n  text-align: center;\n  margin-bottom: 10px;\n}\n\n.filterWrapper {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.filter {\n  display: flex;\n}\n\n.titleWrapper {\n  display: flex;\n}\n\n.number {\n  margin-left: 10px;\n  font-size: 13px;\n  font-weight: 400;\n  line-height: 20px;\n  opacity: 0.6;\n}\n\n.text {\n  color: #000000;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 0;\n  margin-top: 5px;\n  margin-bottom: 20px;\n}\n\n.table {\n  width: calc(100vw - 210px);\n}\n\n.extraButton {\n  margin-right: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperTable": `UsersList_wrapperTable__jy53g`,
	"titleTable": `UsersList_titleTable__kAH6I`,
	"filterWrapper": `UsersList_filterWrapper__2XEY7`,
	"filter": `UsersList_filter__5vH5U`,
	"titleWrapper": `UsersList_titleWrapper__k9YPC`,
	"number": `UsersList_number__EcbjF`,
	"text": `UsersList_text__De0MW`,
	"table": `UsersList_table__z2VtC`,
	"extraButton": `UsersList_extraButton__XIEGR`
};
export default ___CSS_LOADER_EXPORT___;
