// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RegistrationPage_modal__dKXyy {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 500px;
  height: 458px;
}

.RegistrationPage_modalStep4__Qu8KJ {
  width: 700px;
  height: 673px;
}

.RegistrationPage_auth__lcgGJ {
  width: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #000000;
  padding: 35px;
}

.RegistrationPage_authStep4__JrxGh {
  width: 700px;
}

.RegistrationPage_wrap__V829y {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.RegistrationPage_icon__It6X0 {
  width: 25px;
  height: 25px;
  margin-bottom: 10px;
}

.RegistrationPage_background__ATIYu {
  width: 100vw;
  position: absolute;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ebebeb;
}`, "",{"version":3,"sources":["webpack://./src/modules/registration/RegistrationPage.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,kBAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,UAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;EACA,UAAA;EACA,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,aAAA;EACA,2BAAA;EACA,4BAAA;EACA,sBAAA;EACA,yBAAA;AACF","sourcesContent":[".modal {\n  z-index: 2;\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  margin: auto;\n  width: 500px;\n  height: 458px;\n}\n\n.modalStep4 {\n  width: 700px;\n  height: 673px;\n}\n\n.auth {\n  width: 500px;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  z-index: 2;\n  background-color: white;\n  border-radius: 20px;\n  border: 1px solid #000000;\n  padding: 35px;\n}\n\n.authStep4 {\n  width: 700px;\n}\n\n.wrap {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n\n.icon {\n  width: 25px;\n  height: 25px;\n  margin-bottom: 10px;\n}\n\n.background {\n  width: 100vw;\n  position: absolute;\n  z-index: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  height: 100vh;\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-color: #ebebeb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `RegistrationPage_modal__dKXyy`,
	"modalStep4": `RegistrationPage_modalStep4__Qu8KJ`,
	"auth": `RegistrationPage_auth__lcgGJ`,
	"authStep4": `RegistrationPage_authStep4__JrxGh`,
	"wrap": `RegistrationPage_wrap__V829y`,
	"icon": `RegistrationPage_icon__It6X0`,
	"background": `RegistrationPage_background__ATIYu`
};
export default ___CSS_LOADER_EXPORT___;
