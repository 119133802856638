import React, { FC } from "react";
import styles from "./ReportGoodsPopup.module.scss";
import localized from "helpers/localized";
import moment from "moment";
import Popup from "kit/popup/Popup";

const ReportGoodsPopup: FC = ({ show, setOpenPopup, activeRow }) => {
  return (
    <Popup
      show={show}
      closeText={localized.close}
      infoText={localized.info}
      close={setOpenPopup}
    >
      <div className={styles.content}>
        <h1>{localized.report}</h1>
        <div className={styles.rows}>
          <div className={styles.row}>
            <p className={styles.left}>{localized.orderNumber}</p>
            <p className={styles.right}>{activeRow.string1}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.caseNumber}</p>
            <p className={styles.right}>{activeRow.string2}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.status}</p>
            <p className={styles.right}>{activeRow.string3}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.created}</p>
            <p className={styles.right}>
              {moment(activeRow.datetime1).format("DD.MM.YYYY, HH:mm")}
            </p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.changed}</p>
            <p className={styles.right}>
              {moment(activeRow.datetime2).format("DD.MM.YYYY, HH:mm")}
            </p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.showcase}</p>
            <p className={styles.right}>{activeRow.string4}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.product}</p>
            <p className={styles.right}>{activeRow.string5}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.manufacturer}</p>
            <p className={styles.right}>{activeRow.string6}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.number}</p>
            <p className={styles.right}>{activeRow.string7}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.categoryName}</p>
            <p className={styles.right}>{activeRow.string8}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.fullCategoryName}</p>
            <p className={styles.right}>{activeRow.string9}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.provider}</p>
            <p className={styles.right}>{activeRow.string10}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.denomination}</p>
            <p className={styles.right}>{activeRow.string11}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.price}</p>
            <p className={styles.right}>{activeRow.string12}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.quantity}</p>
            <p className={styles.right}>{activeRow.string13}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.price}</p>
            <p className={styles.right}>{activeRow.string14}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.userPrice}</p>
            <p className={styles.right}>{activeRow.string15}</p>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default ReportGoodsPopup;
