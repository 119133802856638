import React, { FC } from "react";
import styles from "./DealersHeader.module.scss";
import localized from "helpers/localized";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { setPageDealers } from "modules/dealers/store/DealersSlice";
import { RootState } from "store/store";
import { useNavigate } from "react-router-dom";

const DealersHeader: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const role = useSelector((state: RootState) => state.profile.profile.role);
  const pageDealers = useSelector(
    (state: RootState) => state.dealers.pageDealers,
  );

  return (
    <div className={styles.navigation}>
      <div
        onClick={() => {
          dispatch(setPageDealers("list"));
          navigate("/?p=dealers&i=list");
        }}
        className={clsx(
          styles.navigationItem,
          pageDealers === "list" && styles.navigationItemActive,
        )}
      >
        {localized.listDealers}
      </div>
      <div
        onClick={() => {
          dispatch(setPageDealers("change"));
          navigate("/?p=dealers&i=change");
        }}
        className={clsx(
          styles.navigationItem,
          pageDealers === "change" && styles.navigationItemActive,
        )}
      >
        {localized.changeDealer}
      </div>
      <div
        onClick={() => {
          dispatch(setPageDealers("new"));
          navigate("/?p=dealers&i=new");
        }}
        className={clsx(
          styles.navigationItem,
          pageDealers === "new" && styles.navigationItemActive,
        )}
      >
        {localized.addDealer}
      </div>
    </div>
  );
};

export default DealersHeader;
