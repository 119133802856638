import React, { FC } from "react";
import UserList from "./components/UsersList/UsersList";
import UsersHeader from "./components/UsersHeader/UsersHeader";
import NewUser from "./components/NewUser/NewUser";
import ChangeUser from "./components/ChangeUser/ChangeUser";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

const UsersPage: FC = () => {
  const pageUsers = useSelector((state: RootState) => state.users.pageUsers);
  return (
    <div>
      <UsersHeader />
      <SwitchTransition mode="out-in">
        <CSSTransition key={pageUsers} timeout={500} classNames="fade">
          {pageUsers === "list" ? (
            <UserList />
          ) : pageUsers === "change" ? (
            <ChangeUser />
          ) : pageUsers === "new" ? (
            <NewUser />
          ) : (
            <div />
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default UsersPage;
