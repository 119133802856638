import React, { FC, useState } from "react";
import styles from "./ProductsPage.module.scss";
import Table from "kit/table/Table";
import localized from "helpers/localized";
import { CSSTransition } from "react-transition-group";
import ProductPopup from "./ProductPopup/ProductPopup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import Pagination from "kit/pagination/Pagination";
import { fetchProducts } from "./store/ProductsAC";
import Button from "kit/button/Button";
import ProductsFilter from "./ProductsFilter/ProductsFilter";
import AddingProductInputs from "./AddingProduct/AddingProductInputs";
import AddingProductExcel from "./AddingProduct/AddingProductExcel";
import AddingProductYML from "./AddingProduct/AddingProductYML";

const ProductsPage: FC = () => {
  const dispatch = useDispatch();

  const products = useSelector((state: RootState) => state.products);

  const [openPopup, setOpenPopup] = useState(false);
  const [openInputs, setOpenInputs] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [openYML, setOpenYML] = useState(false);

  const [openFilter, setOpenFilter] = useState(false);
  const [activeRow, setActiveRow] = useState({});

  const openRow = (row: any) => {
    setActiveRow(row);
    setOpenPopup(true);
  };

  const changePagePagination = (page) => {
    dispatch(fetchProducts({ page }));
  };

  return (
    <>
      <ProductPopup
        show={openPopup}
        setOpenPopup={setOpenPopup}
        activeRow={activeRow}
      />

      <CSSTransition
        in={openInputs}
        timeout={700}
        mountOnEnter
        unmountOnExit
        classNames="my-node"
      >
        <AddingProductInputs
          setOpenInputs={setOpenInputs}
          activeRow={activeRow}
        />
      </CSSTransition>

      <CSSTransition
        in={openExcel}
        timeout={700}
        mountOnEnter
        unmountOnExit
        classNames="my-node"
      >
        <AddingProductExcel setOpenExcel={setOpenExcel} activeRow={activeRow} />
      </CSSTransition>

      <CSSTransition
        in={openYML}
        timeout={700}
        mountOnEnter
        unmountOnExit
        classNames="my-node"
      >
        <AddingProductYML setOpenYML={setOpenYML} activeRow={activeRow} />
      </CSSTransition>

      <div className={styles.wrapper}>
        <div className={styles.title}>{localized.addProduct}</div>
        <div className={styles.buttons}>
          <div className={styles.button}>
            <Button
              id="manually"
              onClick={() => {
                setOpenInputs(true);
              }}
            >
              вручную
            </Button>
          </div>
          <div className={styles.button}>
            <Button
              id="excel"
              onClick={() => {
                setOpenExcel(true);
              }}
            >
              excel
            </Button>
          </div>
          <div className={styles.button}>
            <Button
              id="YML"
              onClick={() => {
                setOpenYML(true);
              }}
            >
              YML
            </Button>
          </div>
        </div>

        <div className={styles.filterWrapper}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>{localized.products}</div>
            <div className={styles.number}>12</div>
          </div>

          <div className={styles.filter}>
            {openFilter ? (
              <>
                <div className={styles.extraButton}>
                  <Button
                    id="close"
                    small
                    onClick={() => {
                      setOpenFilter(false);
                    }}
                  >
                    {localized.close}
                  </Button>
                </div>
                <Button
                  id="apply"
                  small
                  onClick={() => {
                    dispatch(fetchProducts({ page: 1 }));
                  }}
                >
                  {localized.apply}
                </Button>
              </>
            ) : (
              <Button
                small
                id="tableFilter"
                onClick={() => {
                  setOpenFilter(true);
                }}
              >
                {localized.tableFilter}
              </Button>
            )}
          </div>
        </div>
        <div className={styles.text}>{localized.doubleClickInfo}</div>

        {openFilter && <ProductsFilter />}

        <Table
          onOpenRow={openRow}
          columns={[
            {
              title: localized.number,
              key: "string1",
              type: "string",
            },
            {
              title: localized.productName,
              key: "string2",
              type: "string",
            },
            {
              title: localized.category,
              key: "string3",
              type: "string",
            },
            {
              title: localized.brand,
              key: "string4",
              type: "string",
            },
            {
              title: localized.collection,
              key: "string5",
              type: "string",
            },
            {
              title: localized.article,
              key: "string6",
              type: "string",
            },
            {
              title: localized.barcode,
              key: "string7",
              type: "string",
            },
            {
              title: localized.gender,
              key: "string8",
              type: "string",
            },
            {
              title: localized.reserve,
              key: "string9",
              type: "string",
            },
            {
              title: localized.remainder,
              key: "string10",
              type: "string",
            },
            {
              title: localized.orders,
              key: "string11",
              type: "string",
            },
            {
              title: localized.price,
              key: "string12",
              type: "string",
            },
          ]}
          rows={[
            {
              string1: "9946",
              string2: "Шапка",
              string3: "Шапки Elit",
              string4: "Brand",
              string5: "asd@mail.ru",
              string6: "2",
              string7: "ASDASD",
              string8: "-",
              string9: "-",
              string10: "15",
              string11: "1",
              string12: "123",
            },
            {
              string1: "9946",
              string2: "Шапка",
              string3: "Шапки Elit",
              string4: "Brand",
              string5: "asd@mail.ru",
              string6: "2",
              string7: "ASDASD",
              string8: "-",
              string9: "-",
              string10: "15",
              string11: "1",
              string12: "123",
            },
            {
              string1: "9946",
              string2: "Шапка",
              string3: "Шапки Elit",
              string4: "Brand",
              string5: "asd@mail.ru",
              string6: "2",
              string7: "ASDASD",
              string8: "-",
              string9: "-",
              string10: "15",
              string11: "1",
              string12: "123",
            },
            {
              string1: "9946",
              string2: "Шапка",
              string3: "Шапки Elit",
              string4: "Brand",
              string5: "asd@mail.ru",
              string6: "2",
              string7: "ASDASD",
              string8: "-",
              string9: "-",
              string10: "15",
              string11: "1",
              string12: "123",
            },
            {
              string1: "9946",
              string2: "Шапка",
              string3: "Шапки Elit",
              string4: "Brand",
              string5: "asd@mail.ru",
              string6: "2",
              string7: "ASDASD",
              string8: "-",
              string9: "-",
              string10: "15",
              string11: "1",
              string12: "123",
            },
          ]}
          noData={localized.noData}
        />
        <Pagination
          currentPage={products.page}
          lastPage={10}
          onAccept={changePagePagination}
        />
      </div>
    </>
  );
};

export default ProductsPage;
