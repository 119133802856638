import React, { FC, useState } from "react";
import localized from "helpers/localized";
import styles from "./RegistrationPage4.module.scss";
import Button from "kit/button/Button";
import Input from "kit/input/Input";
import Drop from "kit/drop/Drop";
import { useDispatch } from "react-redux";
import { mainApi } from "modules/main/store/MainApi";
import { fetchRegistrationCompanyData } from "modules/registration/store/RegistrationAC";
import { checkPage } from "modules/main/store/MainAC";
import { ReactComponent as DownloadIcon } from "assets/icons/Download.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/Delete.svg";
import { validateLengthEquals } from "helpers/validators";
import { setStep } from "modules/registration/store/RegistrationSlice";
import { setError } from "modules/main/store/MainSlice";

const RegistrationPageStep4: FC = () => {
  const dispatch = useDispatch();

  const [companyName, setCompanyName] = useState("");
  const [companyINN, setCompanyINN] = useState("");
  const [companyOGRN, setCompanyOGRN] = useState("");
  const [companyKPP, setCompanyKPP] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [position, setPosition] = useState("");

  const [companyINNValid, setCompanyINNValid] = useState(true);
  const [companyOGRNValid, setCompanyOGRNValid] = useState(true);
  const [companyKPPValid, setCompanyKPPValid] = useState(true);

  // договор и карта
  const [contract, setContract] = useState(null);

  const validateData = async () => {
    setCompanyINNValid(validateLengthEquals(companyINN, 10, false));
    setCompanyOGRNValid(validateLengthEquals(companyOGRN, 13, false));
    setCompanyKPPValid(validateLengthEquals(companyKPP, 9, false));

    if (
      validateLengthEquals(companyINN, 10, false) &&
      validateLengthEquals(companyOGRN, 13, false) &&
      validateLengthEquals(companyKPP, 9, false)
    ) {
      dispatch(
        fetchRegistrationCompanyData({
          firstName: name,
          lastName,
          middleName,
          position,
          companyName,
          companyINN,
          companyOGRN,
          companyKPP,
          contract,
        }),
      );
    }
  };

  const contractSelected = async (e: any) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      if (
        file.name?.toLowerCase().split(".").pop() === "doc" ||
        file?.name?.toLowerCase().split(".").pop() === "docx"
      ) {
        setContract(e.target.files[0]);
      } else {
        dispatch(setError(localized.contractErrorDoc));
      }
    }
  };

  const inputFileRef = React.useRef();

  const fileDragAndDrop = async (file: any) => {
    if (file) {
      if (
        file.name?.toLowerCase().split(".").pop() === "doc" ||
        file?.name?.toLowerCase().split(".").pop() === "docx"
      ) {
        setContract(file);
      } else {
        dispatch(setError(localized.contractErrorDoc));
      }
    }
  };

  return (
    <>
      <Drop upload={fileDragAndDrop} />
      <div className={styles.titleCompany}>{localized.companyTitle}</div>
      <div className={styles.block}>
        <div className={styles.elemCompany}>
          <Input
            id="companyName"
            value={companyName}
            label={localized.companyName}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCompanyName(e);
            }}
          />
        </div>
        <div className={styles.elementCompany}>
          <Input
            id="companyINN"
            error={!companyINNValid}
            errorMessage={localized.innError}
            value={companyINN}
            allowed="numbers"
            label={localized.inn}
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCompanyINN(e);
              setCompanyINNValid(true);
            }}
            onBlur={() => {
              setCompanyINNValid(validateLengthEquals(companyINN, 10, false));
            }}
          />
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.elemCompany}>
          <Input
            id="companyOGRN"
            error={!companyOGRNValid}
            errorMessage={localized.ogrnError}
            value={companyOGRN}
            allowed="numbers"
            label={localized.ogrn}
            length={13}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCompanyOGRN(e);
              setCompanyOGRNValid(true);
            }}
            onBlur={() => {
              setCompanyOGRNValid(validateLengthEquals(companyOGRN, 13, false));
            }}
          />
        </div>
        <div className={styles.elementCompany}>
          <Input
            id="companyKPP"
            error={!companyKPPValid}
            errorMessage={localized.kppError}
            value={companyKPP}
            allowed="numbers"
            label={localized.kpp}
            length={9}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCompanyKPP(e);
              setCompanyKPPValid(true);
            }}
            onBlur={() => {
              setCompanyKPPValid(validateLengthEquals(companyKPP, 9, false));
            }}
          />
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.elemCompany}>
          <Input
            id="name"
            value={name}
            label={localized.name}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setName(e);
            }}
          />
        </div>
        <div className={styles.elementCompany}>
          <Input
            id="lastName"
            value={lastName}
            label={localized.lastName}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLastName(e);
            }}
          />
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.elemCompany}>
          <Input
            id="middleName"
            value={middleName}
            label={localized.middleName}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMiddleName(e);
            }}
          />
        </div>
        <div className={styles.elementCompany}>
          <Input
            id="position"
            value={position}
            label={localized.position}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPosition(e);
            }}
          />
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.elemCompany}>
          <Button
            id="downloadContract"
            secondary
            onClick={() => {
              mainApi.downloadFile({
                url: "https://api.widget-ci.mgc-loyalty.ru/templates/examples/wholesale-example-phone.xls",
                name: localized.contractTemplate + ".doc",
              });
            }}
          >
            <div className={styles.downloadText}>
              {localized.downloadContract}
            </div>
            <div className={styles.download}>
              <DownloadIcon fill={"#000000"} />
            </div>
          </Button>
        </div>

        <div className={styles.fileWrapper}>
          <Button
            id="uploadContract"
            secondary
            onClick={() => {
              if (contract) {
                setContract(null);
              } else {
                inputFileRef.current.click();
              }
            }}
          >
            <div className={styles.downloadText}>
              {contract ? localized.deleteContract : localized.uploadContract}
            </div>
            <div className={contract ? styles.download : styles.upload}>
              {contract ? (
                <DeleteIcon stroke={"#000000"} />
              ) : (
                <DownloadIcon fill={"#000000"} />
              )}
            </div>
          </Button>
          <input
            id="upload-file"
            className={styles.file}
            type="file"
            name="upload-file"
            value=""
            ref={inputFileRef}
            onChange={contractSelected}
          />
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.elemCompany}>
          <Button
            id="save"
            onClick={() => {
              validateData();
            }}
          >
            {localized.save}
          </Button>
        </div>
        <div className={styles.elementCompany}>
          <Button
            id="skip"
            onClick={() => {
              dispatch(checkPage());
              dispatch(setStep("step1"));
            }}
          >
            {localized.skip}
          </Button>
        </div>
      </div>
    </>
  );
};

export default RegistrationPageStep4;
