// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-node-enter {
  opacity: 0;
}

.my-node-enter-active {
  opacity: 1;
  transition: all 700ms;
}

.my-node-exit {
  opacity: 1;
}

.my-node-exit-active {
  opacity: 0;
  transition: all 700ms;
}`, "",{"version":3,"sources":["webpack://./src/kit/kit.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAEA;EACE,UAAA;EACA,qBAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,UAAA;EACA,qBAAA;AACF","sourcesContent":[".my-node-enter {\n  opacity: 0;\n}\n\n.my-node-enter-active {\n  opacity: 1;\n  transition: all 700ms;\n}\n\n.my-node-exit {\n  opacity: 1;\n}\n\n.my-node-exit-active {\n  opacity: 0;\n  transition: all 700ms;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
