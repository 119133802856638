// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChangeDealer_wrapper__2Alkz {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0px 40px 20px;
}

.ChangeDealer_elements__ZPJ4j {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.ChangeDealer_element__oXpJV {
  margin-right: 20px;
  width: 300px;
  margin-top: 20px;
}

.ChangeDealer_headline__Is0v6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.ChangeDealer_firstBlock__yXBJf {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.ChangeDealer_block__DaSi7 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.ChangeDealer_fileWrapper__fz0jY {
  height: 60px;
  margin-bottom: 20px;
}

.ChangeDealer_downloadText__n8w9e {
  font-weight: 400;
  margin-right: 15px;
}

.ChangeDealer_upload__gfHoa {
  width: 25px;
  height: 25px;
  transform: rotate(180deg);
}

.ChangeDealer_file__VcDCH {
  width: 0;
  height: 0;
  opacity: 0;
}

.ChangeDealer_download__-nVyn {
  width: 25px;
  height: 25px;
}`, "",{"version":3,"sources":["webpack://./src/modules/dealers/components/ChangeDealer/ChangeDealer.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,2BAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;AACF;;AAEA;EACE,QAAA;EACA,SAAA;EACA,UAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 20px 0px 40px 20px;\n}\n\n.elements {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  margin-top: 10px;\n}\n\n.element {\n  margin-right: 20px;\n  width: 300px;\n  margin-top: 20px;\n}\n\n.headline {\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 20px;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px;\n}\n\n.firstBlock {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-wrap: wrap;\n}\n\n.block {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-wrap: wrap;\n}\n\n.fileWrapper {\n  height: 60px;\n  margin-bottom: 20px;\n}\n\n.downloadText {\n  font-weight: 400;\n  margin-right: 15px;\n}\n\n.upload {\n  width: 25px;\n  height: 25px;\n  transform: rotate(180deg);\n}\n\n.file {\n  width: 0;\n  height: 0;\n  opacity: 0;\n}\n\n.download {\n  width: 25px;\n  height: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ChangeDealer_wrapper__2Alkz`,
	"elements": `ChangeDealer_elements__ZPJ4j`,
	"element": `ChangeDealer_element__oXpJV`,
	"headline": `ChangeDealer_headline__Is0v6`,
	"firstBlock": `ChangeDealer_firstBlock__yXBJf`,
	"block": `ChangeDealer_block__DaSi7`,
	"fileWrapper": `ChangeDealer_fileWrapper__fz0jY`,
	"downloadText": `ChangeDealer_downloadText__n8w9e`,
	"upload": `ChangeDealer_upload__gfHoa`,
	"file": `ChangeDealer_file__VcDCH`,
	"download": `ChangeDealer_download__-nVyn`
};
export default ___CSS_LOADER_EXPORT___;
