import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  page: "list",
  pageList: 1,
  pageStatistics: 1,
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setPageOrders: (state, action) => {
      state.page = action.payload;
    },
    setPageList: (state, action) => {
      state.pageList = action.payload;
    },
    setPageStatistics: (state, action) => {
      state.pageStatistics = action.payload;
    },
  },
});

export const { setPageOrders, setPageList, setPageStatistics } =
  ordersSlice.actions;

export default ordersSlice.reducer;
