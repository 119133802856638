import React, { FC, useEffect } from "react";
import LoginPage from "modules/login/LoginPage";
import AdminPage from "modules/admin/AdminPage";
import Message from "kit/message/Message";
import Preloader from "kit/preloder/Preloader";
import RegistrationPage from "../registration/RegistrationPage";
import StubMobile from "kit/stubMobile/StubMobile";
import localized from "helpers/localized";
import { useDispatch, useSelector } from "react-redux";
import { closeMessage, setLanguage, setPage } from "./store/MainSlice";
import { RootState } from "store/store";
import { fetchCheckToken } from "./store/MainAC";
import { setStep } from "modules/login/store/LoginSlice";

const MainPage: FC = () => {
  const dispatch = useDispatch();
  const main = useSelector((state: RootState) => state.main);
  const loading = useSelector((state: RootState) => state.main.loading);

  useEffect(() => {
    // есть ли токен
    if (window.location.href.includes("admin/users/password/set")) {
      localStorage.removeItem("token");
      dispatch(setPage("login"));
      dispatch(setStep("resetPassword"));
    } else {
      if (localStorage.getItem("token")) {
        // если нет, то редирект на логин страницу, если да, его надо проверить
        dispatch(fetchCheckToken());
      } else {
        // если токен устарел то редирект на логин опять, если токен не устарел то показываем логин
        dispatch(setPage("login"));
      }
    }
  }, []);

  const closeInfo = () => {
    dispatch(closeMessage());
  };

  const changeLanguage = (code: string) => {
    dispatch(setLanguage(code));
    localized.setLanguage(code);
  };

  return (
    <div>
      <Preloader loading={loading} />
      <StubMobile
        maxWidth={639}
        title={localized.mobileErrorTitle}
        text={localized.mobileErrorText}
        language={main.language}
        changeLanguage={changeLanguage}
      />
      <Message
        show={main.message}
        id="main"
        title={main.infoTitle}
        message={main.infoText}
        isError={main.isError}
        onAccept={closeInfo}
      />
      {main.page === "login" && <LoginPage />}
      {main.page === "registration" && <RegistrationPage />}
      {main.page === "admin" && <AdminPage />}
    </div>
  );
};

export default MainPage;
