import React, { FC, useEffect, useState } from "react";
import styles from "./ChangeDealer.module.scss";
import localized from "helpers/localized";
import Input from "kit/input/Input";
import Select from "kit/select/Select";
import Button from "kit/button/Button";
import { validateLength, validateLengthEquals } from "helpers/validators";
import {
  fetchChangeDealer,
  fetchDealerInfo,
} from "modules/dealers/store/DealersAC";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { translateCodes } from "helpers/translateCodes";
import { mainApi } from "modules/main/store/MainApi";
import { ReactComponent as DownloadIcon } from "assets/icons/Download.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/Delete.svg";
import { setError } from "modules/main/store/MainSlice";

const ChangeDealer: FC = () => {
  const dispatch = useDispatch();

  const dealer = useSelector((state: RootState) => state.dealers.dealer);
  const users = useSelector((state: RootState) => state.users.usersAll.items);

  const [id, setId] = useState("");
  const [idValid, setIdValid] = useState(true);

  const [inn, setInn] = useState("");
  const [innValid, setInnValid] = useState(true);
  const [kpp, setKpp] = useState("");
  const [kppValid, setKppValid] = useState(true);
  const [ogrn, setOgrn] = useState("");
  const [ogrnValid, setOgrnValid] = useState(true);
  const [legalAddress, setLegalAddress] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");

  const [bankName, setBankName] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [bankAccountValid, setBankAccountValid] = useState(true);
  const [bankCorAccount, setBankCorAccount] = useState("");
  const [bankCorAccountValid, setBankCorAccountValid] = useState(true);
  const [bankBik, setBankBik] = useState("");
  const [bankBikValid, setBankBikValid] = useState(true);
  const [managers, setManagers] = useState([]);
  const [status, setStatus] = useState("");
  const [contract, setContract] = useState(null);
  const [card, setCard] = useState(null);

  useEffect(() => {
    setId(dealer.id ? String(dealer.id) : "");
    setInn(dealer.inn || "");
    setInnValid(true);
    setKpp(dealer.kpp || "");
    setKppValid(true);
    setOgrn(dealer.ogrn || "");
    setOgrnValid(true);
    setLegalAddress(dealer.legalAddress || "");
    setAddress(dealer.address || "");
    setName(dealer.name || "");
    setBankName(dealer.bankName || "");
    setBankAccount(dealer.bankAccount || "");
    setBankCorAccount(dealer.bankCorAccount || "");
    setBankBik(dealer.bankBik || "");
    setManagers(dealer.dealerManagers || []);
    setStatus(dealer.status || "");
  }, [dealer]);

  const newUsers = () => {
    const newArray = [];
    for (let i = 0; i < users?.length; i++) {
      const newUser = { ...users[i] };
      delete newUser.dealers;
      delete newUser.login;
      delete newUser.loginType;
      newArray.push(newUser);
    }
    return newArray;
  };

  const validateDataNewUser = () => {
    setIdValid(validateLength(id, 0, true));
    setInnValid(validateLengthEquals(inn, 10, false));
    setKppValid(validateLengthEquals(kpp, 9, false));
    setOgrnValid(validateLengthEquals(ogrn, 13, false));

    if (
      validateLength(id, 0, true) &&
      validateLengthEquals(inn, 10, false) &&
      validateLengthEquals(kpp, 9, false) &&
      validateLengthEquals(ogrn, 13, false)
    ) {
      dispatch(
        fetchChangeDealer({
          id,
          inn,
          kpp,
          ogrn,
          legalAddress,
          address,
          name,
          bankName,
          bankAccount,
          bankCorAccount,
          bankBik,
          contract,
          card,
          userIds: managers.map((a) => a.id),
        }),
      );
    }
  };

  const contractSelected = async (e: any) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      if (
        file.name?.toLowerCase().split(".").pop() === "doc" ||
        file?.name?.toLowerCase().split(".").pop() === "docx"
      ) {
        setContract(e.target.files[0]);
      } else {
        dispatch(setError(localized.contractErrorDoc));
      }
    }
  };

  const cardSelected = async (e: any) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      if (
        file.name?.toLowerCase().split(".").pop() === "doc" ||
        file?.name?.toLowerCase().split(".").pop() === "docx"
      ) {
        setCard(e.target.files[0]);
      } else {
        dispatch(setError(localized.contractErrorDoc));
      }
    }
  };

  const inputContractRef = React.useRef();
  const inputCardRef = React.useRef();

  return (
    <div className={styles.wrapper}>
      <div className={styles.elements}>
        <div className={styles.headline}>{localized.changeDealer}</div>
        <div className={styles.firstBlock}>
          <div className={styles.element}>
            <Input
              disabled={!status}
              value={name}
              length={50}
              label={localized.companyName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setName(e);
              }}
            />
          </div>
          <div className={styles.element}>
            <Input
              disabled={!status}
              id="inn"
              value={inn}
              label={localized.inn}
              allowed="numbers"
              error={!innValid}
              errorMessage={localized.innError}
              length={10}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInn(e);
                setInnValid(true);
              }}
              onBlur={() => {
                setInnValid(validateLengthEquals(inn, 10, false));
              }}
            />
          </div>

          <div className={styles.element}>
            <Input
              disabled={!status}
              id="kpp"
              value={kpp}
              error={!kppValid}
              errorMessage={localized.kppError}
              label={localized.kpp}
              length={9}
              allowed="numbers"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setKpp(e);
                setKppValid(true);
              }}
              onBlur={() => {
                setKppValid(validateLengthEquals(kpp, 9, false));
              }}
            />
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.element}>
            <Input
              disabled={!status}
              id="ogrn"
              value={ogrn}
              error={!ogrnValid}
              errorMessage={localized.ogrnError}
              label={localized.ogrn}
              allowed="numbers"
              length={13}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setOgrn(e);
                setOgrnValid(true);
              }}
              onBlur={() => {
                setOgrnValid(validateLengthEquals(ogrn, 13, false));
              }}
            />
          </div>

          <div className={styles.element}>
            <Input
              disabled={!status}
              id="legalAddress"
              value={legalAddress}
              label={localized.legalAddress}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLegalAddress(e);
              }}
            />
          </div>
          <div className={styles.element}>
            <Input
              disabled={!status}
              id="address"
              label={localized.actualAddress}
              value={address}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAddress(e);
              }}
            />
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.element}>
            <Input
              disabled={!status}
              id="bankName"
              value={bankName}
              label={localized.bankName}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setBankName(e);
              }}
            />
          </div>
          <div className={styles.element}>
            <Input
              disabled={!status}
              id="bankAccount"
              value={bankAccount}
              allowed="numbers"
              label={localized.rs}
              error={!bankAccountValid}
              errorMessage={localized.errorRS}
              length={20}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setBankAccount(e);
              }}
              onBlur={() => {
                setBankAccountValid(
                  validateLengthEquals(bankAccount, 20, false),
                );
              }}
            />
          </div>
          <div className={styles.element}>
            <Input
              disabled={!status}
              id="bankCorAccount"
              allowed="numbers"
              value={bankCorAccount}
              error={!bankCorAccountValid}
              errorMessage={localized.errorCor}
              label={localized.cr}
              length={20}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setBankCorAccount(e);
              }}
              onBlur={() => {
                setBankCorAccountValid(
                  validateLengthEquals(bankCorAccount, 20, false),
                );
              }}
            />
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.element}>
            <Input
              disabled={!status}
              id="bankBik"
              allowed="numbers"
              value={bankBik}
              error={!bankBikValid}
              errorMessage={localized.errorBIK}
              label={localized.bik}
              length={9}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setBankBik(e);
              }}
              onBlur={() => {
                setBankBikValid(validateLengthEquals(bankBik, 9, false));
              }}
            />
          </div>

          <div className={styles.element}>
            <Select
              disabled={!status}
              id="managers"
              multiple
              label={localized.dealerManagers}
              value={managers}
              onChange={(value) => {
                setManagers(value);
              }}
              source="phone"
              list={newUsers()}
            />
          </div>
          <div className={styles.element}>
            <Input
              id="status"
              label={localized.status}
              value={status && translateCodes(status)}
              disabled
            />
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.element}>
            <Button
              id="downloadContract"
              disabled={!status}
              secondary
              onClick={() => {
                mainApi.downloadFile({
                  url: "https://api.widget-ci.mgc-loyalty.ru/templates/examples/wholesale-example-phone.xls",
                  name: localized.contractTemplate + ".doc",
                });
              }}
            >
              <div className={styles.downloadText}>
                {localized.downloadContract}
              </div>
              <div className={styles.download}>
                <DownloadIcon fill={"#000000"} />
              </div>
            </Button>
          </div>

          <div className={styles.fileWrapper}>
            <div className={styles.element}>
              <Button
                id="uploadContract"
                disabled={!status}
                secondary
                onClick={() => {
                  if (contract) {
                    setContract({});
                  } else {
                    inputContractRef.current.click();
                  }
                }}
              >
                <div className={styles.downloadText}>
                  {contract
                    ? localized.deleteContract
                    : localized.uploadContract}
                </div>
                <div className={contract ? styles.download : styles.upload}>
                  {contract ? (
                    <DeleteIcon stroke={"#000000"} />
                  ) : (
                    <DownloadIcon fill={"#000000"} />
                  )}
                </div>
              </Button>
            </div>
            <input
              id="upload-file"
              className={styles.file}
              type="file"
              name="upload-file"
              value=""
              ref={inputContractRef}
              onChange={contractSelected}
            />
          </div>

          <div className={styles.fileWrapper}>
            <div className={styles.element}>
              <Button
                id="downloadOrganizationCard"
                disabled={!status}
                secondary
                onClick={() => {
                  if (card) {
                    setCard({});
                  } else {
                    inputCardRef.current.click();
                  }
                }}
              >
                <div className={styles.downloadText}>
                  {card
                    ? localized.deleteOrganizationCard
                    : localized.downloadOrganizationCard}
                </div>
                <div className={card ? styles.download : styles.upload}>
                  {card ? (
                    <DeleteIcon stroke={"#000000"} />
                  ) : (
                    <DownloadIcon fill={"#000000"} />
                  )}
                </div>
              </Button>
            </div>
            <input
              id="upload-file"
              className={styles.file}
              type="file"
              name="upload-file"
              value=""
              ref={inputCardRef}
              onChange={cardSelected}
            />
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.element}>
            <Input
              id="id"
              value={id}
              allowed="numbers"
              label={localized.idDealer + "*"}
              error={!idValid}
              length={10}
              errorMessage={localized.enterUserId}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setId(e);
              }}
            />
          </div>
          <div className={styles.element}>
            <Button
              id="dealerData"
              onClick={() => {
                setIdValid(validateLength(id, 0, true));

                if (validateLength(id, 0, true)) {
                  setContract(null);
                  setCard(null);
                  dispatch(
                    fetchDealerInfo({
                      id,
                    }),
                  );
                }
              }}
            >
              {localized.dealerData}
            </Button>
          </div>
          <div className={styles.element}>
            <Button
              id="changeDealer"
              disabled={!status}
              onClick={() => {
                validateDataNewUser();
              }}
            >
              {localized.changeDealer}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeDealer;
