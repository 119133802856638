import React, { FC } from "react";
import Input from "kit/input/Input";
import Select from "kit/select/Select";
import localized from "helpers/localized";
import styles from "./DealersFilter.module.scss";
import { validateLengthEquals } from "helpers/validators";
import { translateCodes } from "helpers/translateCodes";

const DealersFilter: FC = ({
  id,
  setId,
  inn,
  setInn,
  innValid,
  setInnValid,
  kpp,
  setKpp,
  kppValid,
  setKppValid,
  ogrn,
  setOgrn,
  ogrnValid,
  setOgrnValid,
  legalAddress,
  setLegalAddress,
  address,
  setAddress,
  name,
  setName,
  status,
  setStatus,
  bankName,
  setBankName,
  bankAccount,
  setBankAccount,
  bankAccountValid,
  setBankAccountValid,
  bankCorAccount,
  setBankCorAccount,
  bankCorAccountValid,
  setBankCorAccountValid,
  bankBik,
  setBankBik,
  bankBikValid,
  setBankBikValid,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{localized.tableFilter}</div>
      <div className={styles.elements}>
        <div className={styles.element}>
          <Input
            id="id"
            value={id}
            label={localized.userId}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setId(e);
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            value={name}
            label={localized.companyName}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setName(e);
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            id="inn"
            value={inn}
            label={localized.inn}
            allowed="numbers"
            error={!innValid}
            errorMessage={localized.innError}
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInn(e);
              setInnValid(true);
            }}
            onBlur={() => {
              setInnValid(validateLengthEquals(inn, 10, false));
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="kpp"
            value={kpp}
            error={!kppValid}
            errorMessage={localized.kppError}
            label={localized.kpp}
            allowed="numbers"
            length={9}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setKpp(e);
              setKppValid(true);
            }}
            onBlur={() => {
              setKppValid(validateLengthEquals(kpp, 9, false));
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="ogrn"
            value={ogrn}
            error={!ogrnValid}
            errorMessage={localized.ogrnError}
            label={localized.ogrn}
            allowed="numbers"
            length={13}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setOgrn(e);
              setOgrnValid(true);
            }}
            onBlur={() => {
              setOgrnValid(validateLengthEquals(ogrn, 13, false));
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="legalAddress"
            value={legalAddress}
            label={localized.legalAddress}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLegalAddress(e);
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            id="address"
            label={localized.actualAddress}
            value={address}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAddress(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="bankName"
            value={bankName}
            label={localized.bankName}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBankName(e);
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            id="bankAccount"
            value={bankAccount}
            allowed="numbers"
            label={localized.rs}
            error={!bankAccountValid}
            errorMessage={localized.errorRS}
            length={20}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBankAccount(e);
            }}
            onBlur={() => {
              setBankAccountValid(validateLengthEquals(bankAccount, 20, false));
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            id="bankCorAccount"
            allowed="numbers"
            value={bankCorAccount}
            error={!bankCorAccountValid}
            errorMessage={localized.errorCor}
            label={localized.cr}
            length={20}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBankCorAccount(e);
            }}
            onBlur={() => {
              setBankCorAccountValid(
                validateLengthEquals(bankCorAccount, 20, false),
              );
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="bankBik"
            allowed="numbers"
            value={bankBik}
            error={!bankBikValid}
            errorMessage={localized.errorBIK}
            label={localized.bik}
            length={9}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBankBik(e);
            }}
            onBlur={() => {
              setBankBikValid(validateLengthEquals(bankBik, 9, false));
            }}
          />
        </div>

        <div className={styles.element}>
          <Select
            id="status"
            label={localized.status}
            value={status}
            onChange={(value) => {
              setStatus(value);
            }}
            source="title"
            list={[
              { value: "NEW", title: translateCodes("NEW") },
              { value: "REGISTERED", title: translateCodes("REGISTERED") },
              { value: "ACTIVE", title: translateCodes("ACTIVE") },
              { value: "DELETED", title: translateCodes("DELETED") },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default DealersFilter;
