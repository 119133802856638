import React, { FC } from "react";
import Input from "kit/input/Input";
import localized from "helpers/localized";
import styles from "./UsersFilter.module.scss";

import { validateEmail, validatePhone } from "helpers/validators";

import Select from "kit/select/Select";
import { translateCodes } from "helpers/translateCodes";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

const UsersFilter: FC = ({
  id,
  setId,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  middleName,
  setMiddleName,
  position,
  setPosition,
  phone,
  setPhone,
  email,
  setEmail,
  role,
  setRole,
  dealerIds,
  setDealerIds,
  status,
  setStatus,
  loginType,
  setLoginType,
  phoneValid,
  setPhoneValid,
  emailValid,
  setEmailValid,
}) => {
  const dealers = useSelector(
    (state: RootState) => state.dealers.dealersAll.items,
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{localized.tableFilter}</div>
      <div className={styles.elements}>
        <div className={styles.element}>
          <Input
            id="id"
            value={id}
            label={localized.userId}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setId(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="firstName"
            value={firstName}
            label={localized.name}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFirstName(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="lastName"
            value={lastName}
            label={localized.lastName}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLastName(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="middleName"
            value={middleName}
            label={localized.middleName}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMiddleName(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="position"
            value={position}
            label={localized.position}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPosition(e);
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            type="phone"
            error={!phoneValid}
            errorMessage={localized.phoneError}
            id="phone"
            label={localized.phone}
            value={phone}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPhone(e);
              setPhoneValid(true);
            }}
            onBlur={() => {
              setPhoneValid(validatePhone(phone, false));
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            type="email"
            id="email"
            error={!emailValid}
            errorMessage={localized.emailError}
            value={email}
            label={localized.email}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e);
              setEmailValid(true);
            }}
            onBlur={() => {
              setEmailValid(validateEmail(email, false));
            }}
          />
        </div>

        <div className={styles.element}>
          <Select
            id="role"
            label={localized.role}
            value={role}
            onChange={(value) => {
              setRole(value);
            }}
            source="title"
            list={[
              { value: "ADMIN", title: translateCodes("ADMIN") },
              { value: "MGC_EMPLOYEE", title: translateCodes("MGC_EMPLOYEE") },
              {
                value: "DEALER_MANAGER",
                title: translateCodes("DEALER_MANAGER"),
              },
              {
                value: "DEALER_EMPLOYEE",
                title: translateCodes("DEALER_EMPLOYEE"),
              },
            ]}
          />
        </div>

        <div className={styles.element}>
          <Select
            id="dealers"
            multiple
            label={localized.dealers}
            value={dealerIds}
            onChange={(value) => {
              setDealerIds(value);
            }}
            source="name"
            list={dealers}
          />
        </div>

        <div className={styles.element}>
          <Select
            id="status"
            label={localized.status}
            value={status}
            onChange={(value) => {
              setStatus(value);
            }}
            source="title"
            list={[
              { value: "NEW", title: translateCodes("NEW") },
              { value: "REGISTERED", title: translateCodes("REGISTERED") },
              { value: "ACTIVE", title: translateCodes("ACTIVE") },
              { value: "DELETED", title: translateCodes("DELETED") },
            ]}
          />
        </div>

        <div className={styles.element}>
          <Select
            id="loginType"
            label={localized.login}
            value={loginType}
            onChange={(value) => {
              setLoginType(value);
            }}
            source="title"
            list={[
              { value: "phone", title: localized.phone },
              { value: "email", title: localized.email },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersFilter;
