import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  token: "",
  tokenCode: "",
  step: "step1",
  login: "",
  password: "",
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setTokenCode: (state, action) => {
      state.tokenCode = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setLogin: (state, action) => {
      state.login = action.payload.login;
      state.password = action.payload.password;
    },
  },
});

export const { setToken, setTokenCode, setStep, setLogin } = loginSlice.actions;

export default loginSlice.reducer;
