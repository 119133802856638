import axios from "axios";

export const profileApi = {
  getProfile(token) {
    return axios({
      url: process.env.REACT_APP_REST_API + "users/self",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },
  changePhone({ phone, token }) {
    const formData = new FormData();
    formData.append("phone", phone);
    return axios({
      url: process.env.REACT_APP_REST_API + "users/change/phone",
      method: "POST",
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },
  sendPhoneCode({ code, token }) {
    const formData = new FormData();
    formData.append("code", code);
    return axios({
      url: process.env.REACT_APP_REST_API + "users/change/phone/confirm",
      method: "POST",
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },
  changeEmail({ email, token }) {
    const formData = new FormData();
    formData.append("email", email);
    return axios({
      url: process.env.REACT_APP_REST_API + "users/change/email",
      method: "POST",
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },
  sendEmailCode({ code, token }) {
    const formData = new FormData();
    formData.append("code", code);
    return axios({
      url: process.env.REACT_APP_REST_API + "users/change/email/confirm",
      method: "POST",
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },
  changePassword({ oldPassword, newPassword, token }) {
    const formData = new FormData();
    formData.append("oldPassword", oldPassword);
    formData.append("newPassword", newPassword);
    return axios({
      url: process.env.REACT_APP_REST_API + "users/change/password",
      method: "POST",
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },
};
