import React, { FC } from "react";
import styles from "./RegistrationPage3.module.scss";
import Button from "kit/button/Button";
import localized from "helpers/localized";
import { useDispatch } from "react-redux";
import { setStep } from "modules/registration/store/RegistrationSlice";

const RegistrationPageStep3: FC = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div className={styles.title}>{localized.congratulations}</div>
      <div className={styles.element}>
        <div className={styles.textFirst}>{localized.registrationInfo1}</div>
        <div className={styles.text}>{localized.registrationInfo2}</div>
        <div className={styles.text}>{localized.registrationInfo3}</div>
        <div className={styles.text}>{localized.registrationInfo4}</div>
        <div className={styles.text}>{localized.registrationInfo5}</div>

        <div className={styles.element}>
          <Button
            id="next"
            onClick={() => {
              dispatch(setStep("step4"));
            }}
          >
            {localized.next}
          </Button>
        </div>
      </div>
    </>
  );
};

export default RegistrationPageStep3;
