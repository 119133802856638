import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import styles from "./AdminPage.module.scss";
import Navigation from "../navigation/Navigation";
import ProfilePage from "../profile/ProfilePage";
import OrdersPage from "../orders/OrdersPage";
import CategoriesPage from "../categories/CategoriesPage";
import DeliveryPage from "../delivery/DeliveryPage";
import CompanyPage from "../company/CompanyPage";
import SupportPage from "../support/SupportPage";
import DashboardPage from "../dashboard/DashboardPage";
import RulesPage from "../rules/RulesPage";
import OfferPage from "../offer/OfferPage";
import AnswersPage from "../answers/AnswersPage";
import ProductsPage from "../products/ProductsPage";
import UsersPage from "../users/UsersPage";
import DealersPage from "../dealers/DealersPage";
import ReportsPage from "../reports/ReportsPage";
import Page404 from "modules/page404/Page404";
import localized from "helpers/localized";

const AdminPage: FC = () => {
  const adminPage = useSelector((state: RootState) => state.main.adminPage);

  return (
    <div className={styles.wrapper}>
      <Navigation />
      <div className={styles.adminPage}>
        <SwitchTransition mode="out-in">
          <CSSTransition key={adminPage} timeout={500} classNames="fade">
            {adminPage === "profile" ? (
              <ProfilePage />
            ) : adminPage === "dashboard" ? (
              <DashboardPage />
            ) : adminPage === "orders" ? (
              <OrdersPage />
            ) : adminPage === "categories" ? (
              <CategoriesPage />
            ) : adminPage === "products" ? (
              <ProductsPage />
            ) : adminPage === "delivery" ? (
              <DeliveryPage />
            ) : adminPage === "reports" ? (
              <ReportsPage />
            ) : adminPage === "company" ? (
              <CompanyPage />
            ) : adminPage === "support" ? (
              <SupportPage />
            ) : adminPage === "rules" ? (
              <RulesPage />
            ) : adminPage === "offer" ? (
              <OfferPage />
            ) : adminPage === "answers" ? (
              <AnswersPage />
            ) : adminPage === "users" ? (
              <UsersPage />
            ) : adminPage === "dealers" ? (
              <DealersPage />
            ) : (
              <Page404 text={localized.error404} />
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
};

export default AdminPage;
