// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportsGoodsPage_wrapper__nz1lC {
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100vw - 190px);
  padding: 10px;
  flex-direction: column;
}

.ReportsGoodsPage_title__0JGfV {
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  text-align: center;
  margin-bottom: 10px;
}

.ReportsGoodsPage_titleWrapper__FasNB {
  display: flex;
}

.ReportsGoodsPage_text__B56VR {
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 0;
  margin-top: 5px;
  margin-bottom: 20px;
}

.ReportsGoodsPage_number__ik9E6 {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.6;
}

.ReportsGoodsPage_filterWrapper__oADwa {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ReportsGoodsPage_filter__bpJgu {
  display: flex;
}

.ReportsGoodsPage_extraButton__-\\+IkF {
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/modules/reports/reportsGoods/ReportsGoodsPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,2BAAA;EACA,0BAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".wrapper {\n  height: 100vh;\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  width: calc(100vw - 190px);\n  padding: 10px;\n  flex-direction: column;\n}\n\n.title {\n  font-size: 24px;\n  font-weight: 400;\n  line-height: 31px;\n  text-align: center;\n  margin-bottom: 10px;\n}\n\n.titleWrapper {\n  display: flex;\n}\n\n.text {\n  color: #000000;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 0;\n  margin-top: 5px;\n  margin-bottom: 20px;\n}\n\n.number {\n  margin-left: 10px;\n  font-size: 13px;\n  font-weight: 400;\n  line-height: 20px;\n  opacity: 0.6;\n}\n\n.filterWrapper {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.filter {\n  display: flex;\n}\n\n.extraButton {\n  margin-right: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ReportsGoodsPage_wrapper__nz1lC`,
	"title": `ReportsGoodsPage_title__0JGfV`,
	"titleWrapper": `ReportsGoodsPage_titleWrapper__FasNB`,
	"text": `ReportsGoodsPage_text__B56VR`,
	"number": `ReportsGoodsPage_number__ik9E6`,
	"filterWrapper": `ReportsGoodsPage_filterWrapper__oADwa`,
	"filter": `ReportsGoodsPage_filter__bpJgu`,
	"extraButton": `ReportsGoodsPage_extraButton__-+IkF`
};
export default ___CSS_LOADER_EXPORT___;
