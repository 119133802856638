import React, { FC } from "react";
import styles from "./LoginPage.module.scss";
import LanguageMenu from "kit/languageMenu/LanguageMenu";
import LoginPageStep1 from "./components/LoginPageStep1/LoginPageStep1";
import LoginPageStep2 from "./components/LoginPageStep2/LoginPageStep2";
import SetPassword from "./components/SetPassword/SetPassword";
import localized from "helpers/localized";
import { setLanguage } from "modules/main/store/MainSlice";
import { languages } from "helpers/const";
import { RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as UserIcon } from "assets/icons/User.svg";

const LoginPage: FC = () => {
  const dispatch = useDispatch();

  const login = useSelector((state: RootState) => state.login);
  const language = useSelector((state: RootState) => state.main.language);

  const changeLanguage = (code: string) => {
    dispatch(setLanguage(code));
    localized.setLanguage(code);
  };

  return (
    <div>
      <div className={styles.modal}>
        <div className={styles.auth}>
          <LanguageMenu
            languages={languages}
            language={language}
            changeLanguage={changeLanguage}
          />
          <div className={styles.block}>
            <UserIcon className={styles.icon} />
            {login.step === "resetPassword" && <SetPassword />}
            {login.step === "step1" && <LoginPageStep1 />}
            {login.step === "step2" && <LoginPageStep2 />}
          </div>
        </div>
      </div>
      <div
        className={styles.background}
        style={{ backgroundImage: `url(/assets/img/LoginPage.jpg)` }}
      />
    </div>
  );
};

export default LoginPage;
