import React, { FC } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import DealersHeader from "./components/DealersHeader/DealersHeader";
import DealersList from "./components/DealersList/DealersList";
import ChangeDealer from "./components/ChangeDealer/ChangeDealer";
import NewDealer from "./components/NewDealer/NewDealer";

const DealersPage: FC = () => {
  const pageDealers = useSelector(
    (state: RootState) => state.dealers.pageDealers,
  );
  return (
    <div>
      <DealersHeader />
      <SwitchTransition mode="out-in">
        <CSSTransition key={pageDealers} timeout={500} classNames="fade">
          {pageDealers === "list" ? (
            <DealersList />
          ) : pageDealers === "change" ? (
            <ChangeDealer />
          ) : pageDealers === "new" ? (
            <NewDealer />
          ) : (
            <div />
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default DealersPage;
