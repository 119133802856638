import React, { FC } from "react";
import styles from "./CategoriesPage.module.scss";
import localized from "helpers/localized";
import Tree from "./component/Tree/Tree";

const CategoriesPage: FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{localized.categories}</div>
      <Tree />
    </div>
  );
};

export default CategoriesPage;
