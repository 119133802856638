export const languages = [
  {
    code: "ru",
    name: "Русский",
  },
  {
    code: "en",
    name: "English",
  },
  {
    code: "kz",
    name: "Қазақ",
  },
];
