import React from "react";
import styles from "./Navigation.module.scss";
import clsx from "clsx";
import localized from "helpers/localized";
import { RootState } from "store/store";
import { useNavigate } from "react-router-dom";
import { setAdminPage } from "modules/main/store/MainSlice";
import { useDispatch, useSelector } from "react-redux";

let NavigationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const adminPage = useSelector((state: RootState) => state.main.adminPage);
  const role = useSelector((state: RootState) => state.profile.profile.role);

  const ordersPage = useSelector((state: RootState) => state.orders.page);
  const reportsPage = useSelector((state: RootState) => state.reports.page);
  const users = useSelector((state: RootState) => state.users);

  const dealersPage = useSelector(
    (state: RootState) => state.dealers.pageDealers,
  );

  return (
    <div className={styles.items}>
      <div
        onClick={() => {
          dispatch(setAdminPage("dashboard"));
          navigate("/?p=dashboard");
        }}
        className={clsx(
          styles.item,
          adminPage === "dashboard" && styles.itemActive,
        )}
      >
        {localized.dashboard}
      </div>
      <div
        onClick={() => {
          dispatch(setAdminPage("orders"));
          navigate("/?p=orders&i=" + ordersPage);
        }}
        className={clsx(
          styles.itemParent,
          adminPage === "orders" && styles.itemActive,
        )}
      >
        {localized.orders}
      </div>
      <div
        onClick={() => {
          dispatch(setAdminPage("categories"));
          navigate("/?p=categories");
        }}
        className={clsx(
          styles.itemParent,
          (adminPage === "categories" || adminPage === "products") &&
            styles.itemActive,
        )}
      >
        {localized.catalog}
      </div>

      <div
        onClick={() => {
          dispatch(setAdminPage("categories"));
          navigate("/?p=categories");
        }}
        className={clsx(
          styles.itemChild,
          adminPage === "categories" && styles.itemActive,
        )}
      >
        {localized.categories}
      </div>

      <div
        onClick={() => {
          dispatch(setAdminPage("products"));
          navigate("/?p=products");
        }}
        className={clsx(
          styles.itemChild,
          adminPage === "products" && styles.itemActive,
        )}
      >
        {localized.products}
      </div>

      <div
        onClick={() => {
          dispatch(setAdminPage("delivery"));
          navigate("/?p=delivery");
        }}
        className={clsx(
          styles.item,
          adminPage === "delivery" && styles.itemActive,
        )}
      >
        {localized.delivery}
      </div>
      <div
        onClick={() => {
          dispatch(setAdminPage("reports"));
          navigate("/?p=reports&i=" + reportsPage);
        }}
        className={clsx(
          styles.itemParent,
          adminPage === "reports" && styles.itemActive,
        )}
      >
        {localized.reports}
      </div>
      {(role === "DEALER_MANAGER" || role === "DEALER_EMPLOYEE") && (
        <div
          onClick={() => {
            dispatch(setAdminPage("company"));
            navigate("/?p=company");
          }}
          className={clsx(
            styles.item,
            (adminPage === "company" || adminPage === "newEmployee") &&
              styles.itemActive,
          )}
        >
          {localized.company}
        </div>
      )}

      {(role === "ADMIN" ||
        role === "MGC_EMPLOYEE" ||
        role === "DEALER_MANAGER") && (
        <div
          onClick={() => {
            dispatch(setAdminPage("users"));
            navigate("/?p=users&i=" + users.pageUsers);
          }}
          className={clsx(
            styles.item,
            adminPage === "users" && styles.itemActive,
          )}
        >
          {localized.users}
        </div>
      )}
      {(role === "ADMIN" || role === "MGC_EMPLOYEE") && (
        <div
          onClick={() => {
            dispatch(setAdminPage("dealers"));
            navigate("/?p=dealers&i=" + dealersPage);
          }}
          className={clsx(
            styles.item,
            adminPage === "dealers" && styles.itemActive,
          )}
        >
          {localized.dealers}
        </div>
      )}
      <div
        onClick={() => {
          dispatch(setAdminPage("support"));
          navigate("/?p=support");
        }}
        className={clsx(
          styles.item,
          adminPage === "support" && styles.itemActive,
        )}
      >
        {localized.support}
      </div>
    </div>
  );
};

export default NavigationList;
