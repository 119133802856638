import axios from "axios";

export const API_URL = `http://localhost:5000/auth`;

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

export const productsApi = {
  getExcelFile() {
    return axios({
      url: "https://api.widget-ci.mgc-loyalty.ru/templates/examples/wholesale-example-phone.xls",
      method: "GET",
      responseType: "blob",
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `ContractMyGiftCard.doc`);
          document.body.appendChild(link);
          link.click();
        }
        throw new Error(`Failed: status ${response.status}`);
      })
      .catch(function (error) {
        return error;
      });
  },

  sendExcelFile(data: any) {
    const dataFile = new FormData();
    dataFile.append("file", data.file);

    return axios({
      url: process.env.REACT_APP_URL + "/widget/order/xls",
      method: "POST",
      data: dataFile,
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response;
        }
        throw new Error(`Failed: status ${response.status}`);
      })
      .catch(function (error) {
        return error;
      });
  },

  sendYMLFile(data: any) {
    const dataFile = new FormData();
    dataFile.append("file", data.file);

    return axios({
      url: process.env.REACT_APP_URL + "/widget/order/xls",
      method: "POST",
      data: dataFile,
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response;
        }
        throw new Error(`Failed: status ${response.status}`);
      })
      .catch(function (error) {
        return error;
      });
  },
};
