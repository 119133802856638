import axios from "axios";

export const usersApi = {
  newUser({
    firstName,
    lastName,
    middleName,
    position,
    phone,
    email,
    password,
    role,
    dealerIds,
    loginType,
    token,
  }) {
    const formData = new FormData();
    formData.append("loginType", loginType);
    if (firstName) formData.append("firstName", firstName);
    if (lastName) formData.append("lastName", lastName);
    if (middleName) formData.append("middleName", middleName);
    if (position) formData.append("position", position);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("role", role);

    if (dealerIds.length) {
      for (let i = 0; i < dealerIds.length; i++) {
        formData.append("dealerIds[]", dealerIds[i]);
      }
    }

    return axios({
      url: process.env.REACT_APP_REST_API + "users",
      method: "POST",
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  changeUser({
    id = "",
    firstName = "",
    lastName = "",
    middleName = "",
    position = "",
    phone = "",
    email = "",
    role = "",
    dealerIds = [],
    token,
  }) {
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("middleName", middleName);
    formData.append("position", position);
    if (phone) formData.append("phone", phone);
    if (email) formData.append("email", email);
    if (role) formData.append("role", role);
    if (dealerIds.length > 0) {
      for (let i = 0; i < dealerIds.length; i++) {
        formData.append("dealerIds[]", dealerIds[i]);
      }
    }

    return axios({
      url: process.env.REACT_APP_REST_API + "users/" + id,
      method: "POST",
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  userInfo({ id, token }) {
    return axios({
      url: process.env.REACT_APP_REST_API + "users/" + id,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  users({
    page = 1,
    id = "",
    firstName = "",
    lastName = "",
    middleName = "",
    position = "",
    phone = "",
    email = "",
    role = "",
    dealerId = [],
    status = "",
    loginType = "",
    token,
  }) {
    return axios({
      url: process.env.REACT_APP_REST_API + `users`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      params: {
        offset: (page - 1) * 10,
        id,
        firstName,
        lastName,
        middleName,
        position,
        phone,
        email,
        role: role?.value || {},
        dealerId: dealerId?.map((a) => a.id) || [],
        status: status?.value || {},
        loginType: loginType?.value || {},
      },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  allUsers({ token }) {
    return axios({
      url: process.env.REACT_APP_REST_API + `users`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      params: {
        offset: 0,
        limit: 300,
      },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  archive({ id, token }) {
    return axios({
      url: process.env.REACT_APP_REST_API + `users/${id}/archive/in`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  unarchive({ id, token }) {
    return axios({
      url: process.env.REACT_APP_REST_API + `users/${id}/archive/out`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  resetPassword({ login, token }) {
    const formData = new FormData();
    formData.append("login", login);
    return axios({
      url: process.env.REACT_APP_REST_API + "users/password/reset",
      method: "POST",
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },
};
