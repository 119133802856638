// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RegistrationPage1_title__1GW17 {
  font-size: 26px;
  font-weight: 500;
  line-height: 31px;
}

.RegistrationPage1_elements__CFG6I {
  display: flex;
  align-items: flex-start;
}

.RegistrationPage1_el__CqEkT {
  margin-top: 25px;
  margin-right: 10px;
  width: 370px;
}

.RegistrationPage1_switch__sU-cI {
  margin-top: 38px;
}

.RegistrationPage1_elem__LIPQy {
  margin-top: 15px;
  width: 430px;
}

.RegistrationPage1_element__UEr92 {
  margin-top: 25px;
  width: 430px;
}`, "",{"version":3,"sources":["webpack://./src/modules/registration/components/RegistrationPageStep1/RegistrationPage1.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF","sourcesContent":[".title {\n  font-size: 26px;\n  font-weight: 500;\n  line-height: 31px;\n}\n\n.elements {\n  display: flex;\n  align-items: flex-start;\n}\n\n.el {\n  margin-top: 25px;\n  margin-right: 10px;\n  width: 370px;\n}\n\n.switch {\n  margin-top: 38px;\n}\n\n.elem {\n  margin-top: 15px;\n  width: 430px;\n}\n\n.element {\n  margin-top: 25px;\n  width: 430px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `RegistrationPage1_title__1GW17`,
	"elements": `RegistrationPage1_elements__CFG6I`,
	"el": `RegistrationPage1_el__CqEkT`,
	"switch": `RegistrationPage1_switch__sU-cI`,
	"elem": `RegistrationPage1_elem__LIPQy`,
	"element": `RegistrationPage1_element__UEr92`
};
export default ___CSS_LOADER_EXPORT___;
