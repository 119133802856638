import React, { FC, useEffect, useState } from "react";
import styles from "./Tree.module.scss";
import Input from "kit/input/Input";
import Modal from "kit/modal/Modal";
import localized from "helpers/localized";
import { ReactComponent as PlusIcon } from "assets/icons/Plus.svg";
import { ReactComponent as EditIcon } from "assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/Delete.svg";


const Tree: FC = () => {
  const data = [
    {
      id: 1,
      name: "Товары",
      children: [
        {
          id: 12,
          name: "Шапки",
          children: [{ id: 4, name: "Шапка меховая" }],
        },
        {
          id: 13,
          name: "Техника",
          children: [
            { id: 131, name: "Смартфоны" },
            {
              id: 132,
              name: "Стиральные машины",
              children: [
                { id: 1321, name: "Машина бош" },
                {
                  id: 1322,
                  name: "Машина JD",
                  children: [
                    { id: 13222, name: "Машина JD 2020" },
                    { id: 13223, name: "Машина JD 2024" },
                  ],
                },
                { id: 1323, name: "Машина samsung" },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "Подарки",
    },
  ];

  const filterBy = (arr, query) => {
    return query
      ? arr.reduce((acc, item) => {
          if (item.children?.length) {
            const filtered = filterBy(item.children, query);
            if (filtered.length)
              return [...acc, { ...item, children: filtered }];
          }

          const { children, ...itemWithoutChildren } = item;
          return item.name?.toLowerCase().includes(query.toLowerCase())
            ? [...acc, itemWithoutChildren]
            : acc;
        }, [])
      : arr;
  };

  const [sure, setSure] = useState(false);
  const [treeData, setTreeData] = useState(data);
  const [valueSearch, setValueSearch] = useState("");

  const sureApprove = () => {
    console.log("deleted");
    setSure(false);
  };

  const sureReject = () => {
    setSure(false);
  };

  const onQueryChange = (value) => {
    setTreeData(filterBy(data, value));
  };

  useEffect(() => {
    onQueryChange(valueSearch);
  }, [valueSearch]);

  const TreeView = ({ treeData = [] }) => {
    return (
      <ul>
        {treeData.map(
          (leaf) =>
            leaf.name && (
              <li>
                <span data-id={leaf.id}>
                  {leaf.name} <EditIcon className={styles.icon} />{" "}
                  <PlusIcon className={styles.icon} stroke={"#000000"} />{" "}
                  <DeleteIcon
                      onClick={() => {
                        setSure(true)
                      }}
                      className={styles.icon} stroke={"#000000"} />{" "}
                </span>
                {leaf.children && (
                  <TreeView key={leaf.id} treeData={leaf.children} />
                )}
              </li>
            ),
        )}
      </ul>
    );
  };

  return (
    <div className={styles.categoryTree}>
      <Modal
        show={sure}
        title={localized.delete + "?"}
        buttonTextOk={localized.yes}
        buttonTextCancel={localized.no}
        onAccept={sureApprove}
        onCancel={sureReject}
      />
      <div className={styles.wrapper}>
        <div className={styles.element}>
          <Input
            id="filtration"
            value={valueSearch}
            length={50}
            label={localized.filtration}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValueSearch(e);
            }}
          />
        </div>
        <TreeView treeData={treeData} />
      </div>
    </div>
  );
};

export default Tree;
