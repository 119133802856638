// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginPageStep2_title__p8MJ7 {
  font-size: 26px;
  font-weight: 500;
  line-height: 31px;
}

.LoginPageStep2_elem__ou8b3 {
  margin-top: 15px;
  width: 430px;
}

.LoginPageStep2_textCode__Iu0B\\+ {
  margin-top: 13px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.LoginPageStep2_element__zntfW {
  margin-top: 25px;
  width: 430px;
}`, "",{"version":3,"sources":["webpack://./src/modules/login/components/LoginPageStep2/LoginPageStep2.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF","sourcesContent":[".title {\n  font-size: 26px;\n  font-weight: 500;\n  line-height: 31px;\n}\n\n.elem {\n  margin-top: 15px;\n  width: 430px;\n}\n\n.textCode {\n  margin-top: 13px;\n  color: #0009;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n}\n\n.element {\n  margin-top: 25px;\n  width: 430px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `LoginPageStep2_title__p8MJ7`,
	"elem": `LoginPageStep2_elem__ou8b3`,
	"textCode": `LoginPageStep2_textCode__Iu0B+`,
	"element": `LoginPageStep2_element__zntfW`
};
export default ___CSS_LOADER_EXPORT___;
