import React, { FC } from "react";
import styles from "./RulesPage.module.scss";
import localized from "helpers/localized";

const RulesPage: FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Правила</div>
    </div>
  );
};

export default RulesPage;
