// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminPage_adminPage__2FqO2 {
  background-color: #fff;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  width: calc(100vw - 190px);
}

.AdminPage_wrapper__p11Yb {
  display: flex;
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/modules/admin/AdminPage.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,0BAAA;AACF;;AAEA;EACE,aAAA;EACA,aAAA;AACF","sourcesContent":[".adminPage {\n  background-color: #fff;\n  height: 100vh;\n  overflow-y: auto;\n  position: relative;\n  width: calc(100vw - 190px);\n}\n\n.wrapper {\n  display: flex;\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adminPage": `AdminPage_adminPage__2FqO2`,
	"wrapper": `AdminPage_wrapper__p11Yb`
};
export default ___CSS_LOADER_EXPORT___;
