import React, { FC, useState } from "react";
import Input from "kit/input/Input";
import localized from "helpers/localized";
import styles from "./ReportDeliveryFilter.module.scss";
import Calendar from "kit/calendar/Calendar";
import { RootState } from "store/store";
import { useSelector } from "react-redux";

const ReportDeliveryFilter: FC = () => {
  const main = useSelector((state: RootState) => state.main);

  const [number, setNumber] = useState("");
  const [status, setStatus] = useState("");
  const [id, setId] = useState("");
  const [createDateFrom, setCreateDateFrom] = useState("");
  const [createDateTo, setCreateDateTo] = useState("");
  const [changeDateFrom, setChangeDateFrom] = useState("");
  const [changeDateTo, setChangeDateTo] = useState("");
  const [costFrom, setCostFrom] = useState("");
  const [costTo, setCostTo] = useState("");

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{localized.tableFilter}</div>

      <div className={styles.elements}>
        <div className={styles.element}>
          <Input
            id="orderNumber"
            value={number}
            label={localized.orderNumber}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setNumber(e);
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            id="status"
            value={status}
            label={localized.status}
            length={20}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setStatus(e);
            }}
          />{" "}
        </div>
        <div className={styles.element}>
          <Input
            id="deliveryID"
            value={id}
            label={localized.deliveryID}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setId(e);
            }}
          />{" "}
        </div>
        <div className={styles.element}>
          <Calendar
            id="createdfrom"
            label={localized.created + " " + localized.from}
            value={createDateFrom}
            onChange={(value) => {
              setCreateDateFrom(value);
            }}
            locale={main.language}
            day={localized.day}
            month={localized.month}
            year={localized.year}
          />{" "}
        </div>
        <div className={styles.element}>
          <Calendar
            id="createdto"
            label={localized.created + " " + localized.to}
            value={createDateTo}
            onChange={(value) => {
              setCreateDateTo(value);
            }}
            locale={main.language}
            day={localized.day}
            month={localized.month}
            year={localized.year}
          />{" "}
        </div>
        <div className={styles.element}>
          <Calendar
            id="changedfrom"
            label={localized.changed + " " + localized.from}
            value={changeDateFrom}
            onChange={(value) => {
              setChangeDateFrom(value);
            }}
            locale={main.language}
            day={localized.day}
            month={localized.month}
            year={localized.year}
          />{" "}
        </div>
        <div className={styles.element}>
          <Calendar
            id="changedto"
            label={localized.changed + " " + localized.to}
            value={changeDateTo}
            onChange={(value) => {
              setChangeDateTo(value);
            }}
            locale={main.language}
            day={localized.day}
            month={localized.month}
            year={localized.year}
          />{" "}
        </div>
        <div className={styles.element}>
          <Input
            id="costFrom"
            value={costFrom}
            label={localized.costDelivery + " " + localized.from}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCostFrom(e);
            }}
          />{" "}
        </div>
        <div className={styles.element}>
          <Input
            id="costTo"
            allowed="numbers"
            value={costTo}
            length={10}
            label={localized.costDelivery + " " + localized.to}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCostTo(e);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportDeliveryFilter;
