import React, { FC, useState } from "react";
import styles from "./SupportPage.module.scss";
import localized from "helpers/localized";
import { CSSTransition } from "react-transition-group";
import SupportPopup from "./SupportPopup/SupportPopup";

const SupportPage: FC = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [activeRow, setActiveRow] = useState({});

  const openRow = (row: any) => {
    setActiveRow(row);
    setOpenPopup(true);
  };

  return (
    <>
      <CSSTransition
        in={openPopup}
        timeout={700}
        mountOnEnter
        unmountOnExit
        classNames="my-node"
      >
        <SupportPopup setOpenPopup={setOpenPopup} activeRow={activeRow} />
      </CSSTransition>

      <div className={styles.wrapper}>
        <div className={styles.title}>{localized.support}</div>

        <div className={styles.elements}>
          <div className={styles.data}>
            <div
              onClick={(e) => {
                openRow("123");
              }}
              className={styles.block}
            >
              <div className={styles.blockTitle}>Задача МС 72</div>
              <div className={styles.blockText}>До 01.01.70</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportPage;
