import localized from "./localized";

export const translateCodes = (code) => {
  switch (code) {
    case "ADMIN":
      return localized.ADMIN;
    case "MGC_EMPLOYEE":
      return localized.MGC_EMPLOYEE;
    case "DEALER_MANAGER":
      return localized.DEALER_MANAGER;
    case "DEALER_EMPLOYEE":
      return localized.DEALER_EMPLOYEE;

    case "NEW":
      return localized.NEW;
    case "REGISTERED":
      return localized.REGISTERED;
    case "ACTIVE":
      return localized.ACTIVE;
    case "DELETED":
      return localized.DELETED;

    case "ON_REVIEW":
      return localized.ON_REVIEW;
    case "APPROVED":
      return localized.APPROVED;
    case "REJECTED":
      return localized.REJECTED;

    case "INTERNAL_SERVER_ERROR":
      return localized.INTERNAL_SERVER_ERROR;
    case "DATABASE_ERROR":
      return localized.DATABASE_ERROR;
    case "USER_NOT_FOUND":
      return localized.USER_NOT_FOUND;
    case "USER_STATUS_INVALID":
      return localized.USER_STATUS_INVALID;
    case "USER_BLOCKED":
      return localized.USER_BLOCKED;
    case "USER_DELETED":
      return localized.USER_DELETED;
    case "USER_REGISTRATION_ERROR":
      return localized.USER_REGISTRATION_ERROR;
    case "USER_ALREADY_REGISTERED":
      return localized.USER_ALREADY_REGISTERED;
    case "USER_CREATE_ERROR":
      return localized.USER_CREATE_ERROR;
    case "BAD_PASSWORD":
      return localized.BAD_PASSWORD;
    case "PASSWORD_NOT_VALID":
      return localized.PASSWORD_NOT_VALID;
    case "OLD_PASSWORD_INVALID":
      return localized.OLD_PASSWORD_INVALID;
    case "FILE_NOT_SAVED":
      return localized.FILE_NOT_SAVED;
    case "FILE_NOT_EXIST":
      return localized.FILE_NOT_EXIST;
    case "FILE_FORMAT_INVALID":
      return localized.FILE_FORMAT_INVALID;
    case "FILE_SIZE_EXCEEDED":
      return localized.FILE_SIZE_EXCEEDED;
    case "INVALID_FIELDS":
      return localized.INVALID_FIELDS;
    case "INVALID_FIELD_MAX":
      return localized.INVALID_FIELD_MAX;
    case "INVALID_FIELD_MIN":
      return localized.INVALID_FIELD_MIN;
    case "SYSTEM_ERROR":
      return localized.SYSTEM_ERROR;
    case "REQUIRED_FIELDS_MISSING":
      return localized.REQUIRED_FIELDS_MISSING;
    case "ALREADY_EXISTS":
      return localized.ALREADY_EXISTS;
    case "EMAIL_NOT_VALID":
      return localized.EMAIL_NOT_VALID;
    case "REQUEST_CODE_INVALID":
      return localized.REQUEST_CODE_INVALID;
    case "REQUEST_CODE_ERROR":
      return localized.REQUEST_CODE_ERROR;
    case "SEND_REQUEST_CODE_ERROR":
      return localized.SEND_REQUEST_CODE_ERROR;
    case "TOKEN_INVALID":
      return localized.TOKEN_INVALID;
    case "SENDER_ERROR":
      return localized.SENDER_ERROR;
    case "DEALER_ALREADY_EXISTS":
      return localized.DEALER_ALREADY_EXISTS;
    case "FIELD_SIZE_EXCEEDED":
      return localized.FIELD_SIZE_EXCEEDED;
    case "PERMISSION_DENIED":
      return localized.PERMISSION_DENIED;
    case "NOT_FOUND":
      return localized.NOT_FOUND;
    case "DEALER_STATUS_DOES_NOT_MATCH":
      return localized.DEALER_STATUS_DOES_NOT_MATCH;
    case "DEALER_MODERATION_ERROR":
      return localized.DEALER_MODERATION_ERROR;
    case "INVALID_URL_FORMAT":
      return localized.INVALID_URL_FORMAT;
    case "DEALER_UPDATE_ERROR":
      return localized.DEALER_UPDATE_ERROR;
    case "ALREADY_IN_ARCHIVE":
      return localized.ALREADY_IN_ARCHIVE;
    case "ALREADY_OUT_ARCHIVE":
      return localized.ALREADY_OUT_ARCHIVE;
    case "DEALER_DUPLICATE_INN":
      return localized.DEALER_DUPLICATE_INN;
    case "MAXIMUM_ATTEMPTS_EXCEEDED":
      return localized.MAXIMUM_ATTEMPTS_EXCEEDED;
    case "RESET_PASSWORD_ERROR":
      return localized.RESET_PASSWORD_ERROR;
    case "SET_PASSWORD_ERROR":
      return localized.SET_PASSWORD_ERROR;
    case "SET_TEMPORARY_PASSWORD_ERROR":
      return localized.SET_TEMPORARY_PASSWORD_ERROR;
    case "USER_INVITE_ERROR":
      return localized.USER_INVITE_ERROR;
    case "RELATED_DEALER_COUNT_ERROR":
      return localized.RELATED_DEALER_COUNT_ERROR;
    case "USER_UPDATE_ERROR":
      return localized.USER_UPDATE_ERROR;
    case "DEALER_CREATE_ERROR":
      return localized.DEALER_CREATE_ERROR;

    default:
      return "";
  }
};
