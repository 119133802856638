import React, { FC, useState } from "react";
import styles from "./UsersList.module.scss";
import localized from "helpers/localized";
import UsersPopup from "../UsersPopup/UsersPopup";
import Button from "kit/button/Button";
import UsersFilter from "../UsersFilter/UsersFilter";
import Table from "kit/table/Table";
import Pagination from "kit/pagination/Pagination";
import { RootState } from "store/store";
import { translateCodes } from "helpers/translateCodes";
import { fetchUsers } from "modules/users/store/UsersAC";
import { validateEmail, validatePhone } from "helpers/validators";
import { useDispatch, useSelector } from "react-redux";

const UserList: FC = () => {
  const dispatch = useDispatch();

  const users = useSelector((state: RootState) => state.users);

  const [openPopup, setOpenPopup] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [rowId, setRowId] = useState({});

  const [id, setId] = useState(users.filtration.id);
  const [firstName, setFirstName] = useState(users.filtration.firstName);
  const [lastName, setLastName] = useState(users.filtration.lastName);
  const [middleName, setMiddleName] = useState(users.filtration.middleName);
  const [position, setPosition] = useState(users.filtration.position);

  const [phone, setPhone] = useState(users.filtration.phone);
  const [email, setEmail] = useState(users.filtration.email);
  const [role, setRole] = useState(users.filtration.role);
  const [dealerIds, setDealerIds] = useState(users.filtration.dealerId);
  const [status, setStatus] = useState(users.filtration.status);
  const [loginType, setLoginType] = useState(users.filtration.loginType);

  const [phoneValid, setPhoneValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);

  const openRow = (row: any) => {
    setRowId(row.id);
    setOpenPopup(true);
  };

  const changePagePagination = (page) => {
    dispatch(
      fetchUsers({
        loading: true,
        page,
        id,
        firstName,
        lastName,
        middleName,
        position,
        phone,
        email,
        role,
        dealerIds,
        status,
        loginType,
      }),
    );
  };

  const newUsers = () => {
    let newUsers = [];

    for (let i = 0; i < users.users.items.length; i++) {
      const dealersNumbers = users.users.items[i].dealers.length;
      newUsers.push({
        ...users.users.items[i],
        role: translateCodes(users.users.items[i].role),
        status: translateCodes(users.users.items[i].status),
        dealers: dealersNumbers,
        loginType:
          users.users.items[i].loginType === "phone"
            ? localized.phone
            : localized.email,
      });
    }

    return newUsers;
  };

  return (
    <>
      <UsersPopup
        show={openPopup}
        setOpenPopup={setOpenPopup}
        activeRow={users.users.items.filter((item) => item.id === rowId)[0]}
      />
      <div className={styles.wrapperTable}>
        <div className={styles.filterWrapper}>
          <div className={styles.titleWrapper}>
            <div className={styles.titleTable}>{localized.users}</div>
            <div className={styles.number}>{users.users.meta.itemCount}</div>
          </div>

          <div className={styles.filter}>
            {openFilter ? (
              <>
                <div className={styles.extraButton}>
                  <Button
                    id="close"
                    small
                    onClick={() => {
                      setOpenFilter(false);
                    }}
                  >
                    {localized.close}
                  </Button>
                </div>
                <div className={styles.extraButton}>
                  <Button
                    id="resetFilters"
                    small
                    onClick={() => {
                      setId("");
                      setFirstName("");
                      setLastName("");
                      setMiddleName("");
                      setPosition("");
                      setPhone("");
                      setEmail("");
                      setRole({});
                      setDealerIds([]);
                      setStatus({});
                      setLoginType({});
                      dispatch(
                        fetchUsers({
                          loading: false,
                          page: 1,
                          id: "",
                          firstName: "",
                          lastName: "",
                          middleName: "",
                          position: "",
                          phone: "",
                          email: "",
                          role: {},
                          dealerId: [],
                          status: {},
                          loginType: {},
                        }),
                      );
                    }}
                  >
                    {localized.resetFilters}
                  </Button>
                </div>
                <Button
                  id="apply"
                  small
                  onClick={() => {
                    setPhoneValid(validatePhone(phone, false));
                    setEmailValid(validateEmail(email, false));

                    if (
                      validatePhone(phone, false) &&
                      validateEmail(email, false)
                    ) {
                      dispatch(
                        fetchUsers({
                          loading: true,
                          page: 1,
                          id,
                          firstName,
                          lastName,
                          middleName,
                          position,
                          phone,
                          email,
                          role,
                          dealerId: dealerIds,
                          status,
                          loginType,
                        }),
                      );
                    }
                  }}
                >
                  {localized.apply}
                </Button>
              </>
            ) : (
              <Button
                id="tableFilter"
                small
                onClick={() => {
                  setOpenFilter(true);
                }}
              >
                {localized.tableFilter}
              </Button>
            )}
          </div>
        </div>
        <div className={styles.text}>{localized.doubleClickInfo}</div>

        {openFilter && (
          <UsersFilter
            id={id}
            setId={setId}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            middleName={middleName}
            setMiddleName={setMiddleName}
            position={position}
            setPosition={setPosition}
            phone={phone}
            setPhone={setPhone}
            email={email}
            setEmail={setEmail}
            role={role}
            setRole={setRole}
            dealerIds={dealerIds}
            setDealerIds={setDealerIds}
            status={status}
            setStatus={setStatus}
            loginType={loginType}
            setLoginType={setLoginType}
            phoneValid={phoneValid}
            setPhoneValid={setPhoneValid}
            emailValid={emailValid}
            setEmailValid={setEmailValid}
          />
        )}

        <div className={styles.table}>
          <Table
            onOpenRow={openRow}
            columns={[
              {
                title: "ID",
                key: "id",
                type: "string",
              },
              {
                title: localized.name,
                key: "firstName",
                type: "string",
              },
              {
                title: localized.lastName,
                key: "lastName",
                type: "string",
              },
              {
                title: localized.middleName,
                key: "middleName",
                type: "string",
              },
              {
                title: localized.position,
                key: "position",
                type: "string",
              },
              {
                title: localized.phone,
                key: "phone",
                type: "string",
              },
              {
                title: localized.email,
                key: "email",
                type: "string",
              },
              {
                title: localized.role,
                key: "role",
                type: "string",
              },
              {
                title: localized.dealers,
                key: "dealers",
                type: "string",
              },
              {
                title: localized.status,
                key: "status",
                type: "string",
              },
              {
                title: localized.login,
                key: "loginType",
                type: "string",
              },
            ]}
            rows={newUsers()}
            noData={localized.noData}
          />
        </div>
        {users.users.items.length > 0 && (
          <Pagination
            currentPage={users.page}
            lastPage={users.users.meta.pageCount}
            onAccept={changePagePagination}
          />
        )}
      </div>
    </>
  );
};

export default UserList;
