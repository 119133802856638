// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RegistrationPage4_titleCompany__1veCd {
  font-size: 26px;
  font-weight: 500;
  line-height: 31px;
  margin-bottom: 25px;
}

.RegistrationPage4_block__nkGyo {
  display: flex;
  margin-bottom: 25px;
  align-items: flex-start;
  z-index: 2;
}

.RegistrationPage4_elemCompany__FdsID {
  width: 300px;
  margin-right: 20px;
}

.RegistrationPage4_elementCompany__kLb5e {
  width: 300px;
}

.RegistrationPage4_downloadText__9F6Wv {
  font-weight: 400;
  margin-right: 15px;
}

.RegistrationPage4_download__8-MMH {
  width: 25px;
  height: 25px;
}

.RegistrationPage4_upload__\\+PgA\\+ {
  width: 25px;
  height: 25px;
  transform: rotate(180deg);
}

.RegistrationPage4_fileWrapper__1tt76 {
  height: 60px;
  width: 300px;
}

.RegistrationPage4_file__p440S {
  width: 0;
  height: 0;
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/modules/registration/components/RegistrationPageStep4/RegistrationPage4.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;AACF;;AAEA;EACE,QAAA;EACA,SAAA;EACA,UAAA;AACF","sourcesContent":[".titleCompany {\n  font-size: 26px;\n  font-weight: 500;\n  line-height: 31px;\n  margin-bottom: 25px;\n}\n\n.block {\n  display: flex;\n  margin-bottom: 25px;\n  align-items: flex-start;\n  z-index: 2;\n}\n\n.elemCompany {\n  width: 300px;\n  margin-right: 20px;\n}\n\n.elementCompany {\n  width: 300px;\n}\n\n.downloadText {\n  font-weight: 400;\n  margin-right: 15px;\n}\n\n.download {\n  width: 25px;\n  height: 25px;\n}\n\n.upload {\n  width: 25px;\n  height: 25px;\n  transform: rotate(180deg);\n}\n\n.fileWrapper {\n  height: 60px;\n  width: 300px;\n}\n\n.file {\n  width: 0;\n  height: 0;\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleCompany": `RegistrationPage4_titleCompany__1veCd`,
	"block": `RegistrationPage4_block__nkGyo`,
	"elemCompany": `RegistrationPage4_elemCompany__FdsID`,
	"elementCompany": `RegistrationPage4_elementCompany__kLb5e`,
	"downloadText": `RegistrationPage4_downloadText__9F6Wv`,
	"download": `RegistrationPage4_download__8-MMH`,
	"upload": `RegistrationPage4_upload__+PgA+`,
	"fileWrapper": `RegistrationPage4_fileWrapper__1tt76`,
	"file": `RegistrationPage4_file__p440S`
};
export default ___CSS_LOADER_EXPORT___;
