import React, { FC } from "react";
import styles from "./OrdersStatisticsPage.module.scss";
import Table from "kit/table/Table";
import localized from "helpers/localized";
import AreaChart from "kit/charts/AreaChart";

const OrdersStatisticsPage: FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{localized.statisticsOrders}</div>
      <Table
        left={true}
        columns={[
          {
            title: "Источник",
            key: "string1",
            type: "string",
          },
          {
            title: "Заказы",
            key: "string2",
            type: "string",
          },
          {
            title: "Сумма",
            key: "string3",
            type: "string",
          },
          {
            title: "Средняя сумма заказа",
            key: "string4",
            type: "string",
          },
        ]}
        rows={[
          {
            string1: "Виджет",
            string2: "4",
            string3: "22000",
            string4: "2000",
          },

          {
            string1: "Маркетплейс",
            string2: "10",
            string3: "100000",
            string4: "1000",
          },
        ]}
        noData={localized.noData}
      />

      <div className={styles.charts}>
        <div className={styles.chart}>
          <AreaChart title={"Заказы шт"} />
        </div>

        <div className={styles.chart}>
          <AreaChart title={"Заказы, руб"} />
        </div>

        <div className={styles.chart}>
          <AreaChart title={"Средний чек, руб"} />
        </div>
      </div>
    </div>
  );
};

export default OrdersStatisticsPage;
