import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  page: 1,
  photo: "",
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPhoto: (state, action) => {
      state.photo = action.payload;
    },
  },
});

export const { setPage, setPhoto } = productsSlice.actions;

export default productsSlice.reducer;
