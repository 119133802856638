import axios from "axios";

export const mainApi = {
  checkToken() {
    return axios({
      url: process.env.REACT_APP_REST_API + "test",
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  downloadFile({ url, name }) {
    return axios({
      url: url,
      method: "GET",
      responseType: "blob",
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
        }
        throw new Error(`Failed: status ${response.status}`);
      })
      .catch(function (error) {
        return error;
      });
  },
};
