import React, { FC } from "react";
import styles from "./UsersHeader.module.scss";
import localized from "helpers/localized";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { setPageUsers } from "modules/users/store/UsersSlice";
import { RootState } from "store/store";
import { useNavigate } from "react-router-dom";

const UsersHeader: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageUsers = useSelector((state: RootState) => state.users.pageUsers);

  return (
    <div className={styles.navigation}>
      <div
        onClick={() => {
          dispatch(setPageUsers("list"));
          navigate("/?p=users&i=list");
        }}
        className={clsx(
          styles.navigationItem,
          pageUsers === "list" && styles.navigationItemActive,
        )}
      >
        {localized.usersList}
      </div>
      <div
        onClick={() => {
          dispatch(setPageUsers("change"));
          navigate("/?p=users&i=change");
        }}
        className={clsx(
          styles.navigationItem,
          pageUsers === "change" && styles.navigationItemActive,
        )}
      >
        {localized.changeUser}
      </div>
      <div
        onClick={() => {
          dispatch(setPageUsers("new"));
          navigate("/?p=users&i=new");
        }}
        className={clsx(
          styles.navigationItem,
          pageUsers === "new" && styles.navigationItemActive,
        )}
      >
        {localized.addUser}
      </div>
    </div>
  );
};

export default UsersHeader;
