// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UsersHeader_navigation__Ndtfa {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

.UsersHeader_navigationItem__Sxmzv {
  transition: all 300ms ease;
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-right: 15px;
  margin-top: 15px;
  cursor: pointer;
  border-bottom: 1px solid white;
  text-decoration: none;
}

.UsersHeader_navigationItem__Sxmzv:hover {
  border-bottom: 1px solid black;
}

.UsersHeader_navigationItemActive__JbWH7 {
  border-bottom: 1px solid black;
}`, "",{"version":3,"sources":["webpack://./src/modules/users/components/UsersHeader/UsersHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,0BAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,8BAAA;EACA,qBAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,8BAAA;AACF","sourcesContent":[".navigation {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-right: 10px;\n  padding-left: 10px;\n}\n\n.navigationItem {\n  transition: all 300ms ease;\n  color: #000000;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  margin-right: 15px;\n  margin-top: 15px;\n  cursor: pointer;\n  border-bottom: 1px solid white;\n  text-decoration: none;\n}\n\n.navigationItem:hover {\n  border-bottom: 1px solid black;\n}\n\n.navigationItemActive {\n  border-bottom: 1px solid black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": `UsersHeader_navigation__Ndtfa`,
	"navigationItem": `UsersHeader_navigationItem__Sxmzv`,
	"navigationItemActive": `UsersHeader_navigationItemActive__JbWH7`
};
export default ___CSS_LOADER_EXPORT___;
