import localized from "helpers/localized";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setError, setInfo, setLoading } from "modules/main/store/MainSlice";
import { companyApi } from "./CompanyApi";
import { errorCodes } from "helpers/functions";

export const fetchNewEmployee = createAsyncThunk(
  "fetchNewEmployee",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await companyApi.newEmployee({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      if (response?.data?.data) {
        thunkAPI.dispatch(setInfo(localized.userInvitation));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
      thunkAPI.dispatch(setLoading(false));
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);
