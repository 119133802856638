// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SetPassword_title__J-0CP {
  font-size: 26px;
  font-weight: 500;
  line-height: 31px;
}

.SetPassword_elem__z7YCd {
  margin-top: 15px;
  width: 430px;
}

.SetPassword_element__R7v9b {
  margin-top: 25px;
  width: 430px;
}`, "",{"version":3,"sources":["webpack://./src/modules/login/components/SetPassword/SetPassword.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF","sourcesContent":[".title {\n  font-size: 26px;\n  font-weight: 500;\n  line-height: 31px;\n}\n\n.elem {\n  margin-top: 15px;\n  width: 430px;\n}\n\n.element {\n  margin-top: 25px;\n  width: 430px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `SetPassword_title__J-0CP`,
	"elem": `SetPassword_elem__z7YCd`,
	"element": `SetPassword_element__R7v9b`
};
export default ___CSS_LOADER_EXPORT___;
