// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportGoodsPopup_headline__Zhihe {
  font-size: 26px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-bottom: 10px;
}

.ReportGoodsPopup_content__SIQai h1 {
  margin-bottom: 26px;
  padding: 20px;
}

.ReportGoodsPopup_rows__yfQJt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.ReportGoodsPopup_row__tpA1l {
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: calc(25% - 40px);
  min-width: 250px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  margin: 0 20px;
}

@media (max-width: 1190px) {
  .ReportGoodsPopup_row__tpA1l {
    max-width: calc(33% - 37px);
  }
}
@media (max-width: 950px) {
  .ReportGoodsPopup_row__tpA1l {
    max-width: calc(50% - 40px);
  }
}
@media (max-width: 620px) {
  .ReportGoodsPopup_row__tpA1l {
    max-width: calc(100% - 40px);
  }
}
.ReportGoodsPopup_left__zl2co {
  color: rgba(0, 0, 0, 0.6);
}

.ReportGoodsPopup_right__4EW7O {
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/modules/reports/reportsGoods/ReportGoodsPopup/ReportGoodsPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,eAAA;EACA,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,2BAAA;EACA,gBAAA;EACA,2CAAA;EACA,cAAA;AACF;;AAEA;EACE;IACE,2BAAA;EACF;AACF;AAEA;EACE;IACE,2BAAA;EAAF;AACF;AAGA;EACE;IACE,4BAAA;EADF;AACF;AAIA;EACE,yBAAA;AAFF;;AAKA;EACE,gBAAA;AAFF","sourcesContent":[".headline {\n  font-size: 26px;\n  font-weight: 500;\n  line-height: 20px;\n  text-align: left;\n  margin-bottom: 10px;\n}\n\n.content h1 {\n  margin-bottom: 26px;\n  padding: 20px;\n}\n\n.rows {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  width: 100%;\n}\n\n.row {\n  min-height: 60px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  max-width: calc(25% - 40px);\n  min-width: 250px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.5);\n  margin: 0 20px;\n}\n\n@media (max-width: 1190px) {\n  .row {\n    max-width: calc(33% - 37px);\n  }\n}\n\n@media (max-width: 950px) {\n  .row {\n    max-width: calc(50% - 40px);\n  }\n}\n\n@media (max-width: 620px) {\n  .row {\n    max-width: calc(100% - 40px);\n  }\n}\n\n.left {\n  color: rgba(0, 0, 0, 0.6);\n}\n\n.right {\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline": `ReportGoodsPopup_headline__Zhihe`,
	"content": `ReportGoodsPopup_content__SIQai`,
	"rows": `ReportGoodsPopup_rows__yfQJt`,
	"row": `ReportGoodsPopup_row__tpA1l`,
	"left": `ReportGoodsPopup_left__zl2co`,
	"right": `ReportGoodsPopup_right__4EW7O`
};
export default ___CSS_LOADER_EXPORT___;
