import React, { FC, useState } from "react";
import styles from "./OrdersListPage.module.scss";
import Table from "kit/table/Table";
import localized from "helpers/localized";
import OrderListPopup from "./OrderListPopup/OrderListPopup";
import Pagination from "kit/pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import Button from "kit/button/Button";
import OrdersListFilter from "./OrdersListFilter/OrdersListFilter";

const OrdersListPage: FC = () => {
  const dispatch = useDispatch();

  const orders = useSelector((state: RootState) => state.orders);

  const [openPopup, setOpenPopup] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [activeRow, setActiveRow] = useState({});

  const openRow = (row: any) => {
    setActiveRow(row);
    setOpenPopup(true);
  };

  const changePagePagination = (page) => {};
  return (
    <>
      <OrderListPopup
        show={openPopup}
        setOpenPopup={setOpenPopup}
        activeRow={activeRow}
      />
      <div className={styles.wrapper}>
        <div className={styles.filterWrapper}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>{localized.orders}</div>
            <div className={styles.number}>12</div>
          </div>

          <div className={styles.filter}>
            {openFilter ? (
              <>
                <div className={styles.extraButton}>
                  <Button
                    id="close"
                    small
                    onClick={() => {
                      setOpenFilter(false);
                    }}
                  >
                    {localized.close}
                  </Button>
                </div>
                <Button id="apply" small onClick={() => {}}>
                  {localized.apply}
                </Button>
              </>
            ) : (
              <Button
                id="tableFilter"
                small
                onClick={() => {
                  setOpenFilter(true);
                }}
              >
                {localized.tableFilter}
              </Button>
            )}
          </div>
        </div>
        <div className={styles.text}>{localized.doubleClickInfo}</div>

        {openFilter && <OrdersListFilter />}

        <Table
          onOpenRow={openRow}
          columns={[
            {
              title: localized.order,
              key: "string1",
              type: "string",
            },
            {
              title: localized.created,
              key: "datetime1",
              type: "datetime",
            },
            {
              title: localized.changed,
              key: "datetime2",
              type: "datetime",
            },
            {
              title: localized.status,
              key: "string2",
              type: "string",
            },
            {
              title: localized.customerName,
              key: "string3",
              type: "string",
            },
            {
              title: localized.customerPhone,
              key: "string4",
              type: "string",
            },
            {
              title: localized.customerEmail,
              key: "string5",
              type: "string",
            },
            {
              title: localized.numberItems,
              key: "string6",
              type: "string",
            },
            {
              title: localized.orderAmount,
              key: "string7",
              type: "string",
            },
            {
              title: localized.deliveryAmount,
              key: "string8",
              type: "string",
            },
            {
              title: localized.total,
              key: "string9",
              type: "string",
            },
            {
              title: localized.partnerComment,
              key: "string10",
              type: "string",
            },
            {
              title: localized.supplierComment,
              key: "string11",
              type: "string",
            },
            {
              title: localized.product,
              key: "string12",
              type: "string",
            },
            {
              title: localized.trackNumber,
              key: "string13",
              type: "string",
            },
            {
              title: localized.dateDelivery,
              key: "datetime3",
              type: "datetime",
            },
            {
              title: localized.invoice,
              key: "string14",
              type: "string",
            },
            {
              title: localized.deliveryStatus,
              key: "string15",
              type: "string",
            },
          ]}
          rows={[
            {
              string1: "305123",
              datetime1: new Date(),
              datetime2: new Date(),
              string2: "Выполнен",
              string3: "Олег Петров",
              string4: "+79123123123",
              string5: "asd@mail.ru",
              string6: "1",
              string7: "100",
              string8: "3700",
              string9: "123123",
              string10: "123123123",
              string11: "123123",
              string12: "123123",
              string13: "123123",
              datetime3: new Date(),
              string14: "123123",
              string15: "123123",
            },

            {
              string1: "305123",
              datetime1: new Date(),
              datetime2: new Date(),
              string2: "Выполнен",
              string3: "Олег Петров",
              string4: "+79123123123",
              string5: "asd@mail.ru",
              string6: "1",
              string7: "100",
              string8: "3700",
              string9: "123123",
              string10: "123123123",
              string11: "123123",
              string12: "123123",
              string13: "123123",
              datetime3: new Date(),
              string14: "123123",
              string15: "123123",
            },

            {
              string1: "305123",
              datetime1: new Date(),
              datetime2: new Date(),
              string2: "Выполнен",
              string3: "Олег Петров",
              string4: "+79123123123",
              string5: "asd@mail.ru",
              string6: "1",
              string7: "100",
              string8: "3700",
              string9: "123123",
              string10: "123123123",
              string11: "123123",
              string12: "123123",
              string13: "123123",
              datetime3: new Date(),
              string14: "123123",
              string15: "123123",
            },

            {
              string1: "305123",
              datetime1: new Date(),
              datetime2: new Date(),
              string2: "Выполнен",
              string3: "Олег Петров",
              string4: "+79123123123",
              string5: "asd@mail.ru",
              string6: "1",
              string7: "100",
              string8: "3700",
              string9: "123123",
              string10: "123123123",
              string11: "123123",
              string12: "123123",
              string13: "123123",
              datetime3: new Date(),
              string14: "123123",
              string15: "123123",
            },

            {
              string1: "305123",
              datetime1: new Date(),
              datetime2: new Date(),
              string2: "Выполнен",
              string3: "Олег Петров",
              string4: "+79123123123",
              string5: "asd@mail.ru",
              string6: "1",
              string7: "100",
              string8: "3700",
              string9: "123123",
              string10: "123123123",
              string11: "123123",
              string12: "123123",
              string13: "123123",
              datetime3: new Date(),
              string14: "123123",
              string15: "123123",
            },
            {
              string1: "305123",
              datetime1: new Date(),
              datetime2: new Date(),
              string2: "Выполнен",
              string3: "Олег Петров",
              string4: "+79123123123",
              string5: "asd@mail.ru",
              string6: "1",
              string7: "100",
              string8: "3700",
              string9: "123123",
              string10: "123123123",
              string11: "123123",
              string12: "123123",
              string13: "123123",
              datetime3: new Date(),
              string14: "123123",
              string15: "123123",
            },
          ]}
          noData={localized.noData}
        />
        <Pagination
          currentPage={orders.pageList}
          lastPage={10}
          onAccept={changePagePagination}
        />
      </div>
    </>
  );
};

export default OrdersListPage;
