import React, { FC } from "react";
import styles from "./OrderListPopup.module.scss";
import localized from "helpers/localized";
import moment from "moment";
import Table from "kit/table/Table";
import Popup from "kit/popup/Popup";

const OrderListPopup: FC = ({ show, setOpenPopup, activeRow }) => {
  return (
    <Popup
      show={show}
      closeText={localized.close}
      infoText={localized.info}
      close={setOpenPopup}
    >
      <div className={styles.content}>
        <h1>{localized.order}</h1>
        <div className={styles.rows}>
          <div className={styles.row}>
            <p className={styles.left}>{localized.number}</p>
            <p className={styles.right}>{activeRow.string1}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.created}</p>
            <p className={styles.right}>
              {moment(activeRow.datetime1).format("DD.MM.YYYY, HH:mm")}
            </p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.changed}</p>
            <p className={styles.right}>
              {moment(activeRow.datetime2).format("DD.MM.YYYY, HH:mm")}
            </p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.status}</p>
            <p className={styles.right}>{activeRow.string2}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.customerName}</p>
            <p className={styles.right}>{activeRow.string3}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.customerPhone}</p>
            <p className={styles.right}>{activeRow.string4}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.customerEmail}</p>
            <p className={styles.right}>{activeRow.string5}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.numberItems}</p>
            <p className={styles.right}>{activeRow.string6}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.orderAmount}</p>
            <p className={styles.right}>{activeRow.string7}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.deliveryAmount}</p>
            <p className={styles.right}>{activeRow.string8}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.total}</p>
            <p className={styles.right}>{activeRow.string9}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.partnerComment}</p>
            <p className={styles.right}>{activeRow.string10}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.supplierComment}</p>
            <p className={styles.right}>{activeRow.string11}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.product}</p>
            <p className={styles.right}>{activeRow.string12}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.trackNumber}</p>
            <p className={styles.right}>{activeRow.string13}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.dateDelivery}</p>
            <p className={styles.right}>
              {moment(activeRow.datetime3).format("DD.MM.YYYY, HH:mm")}
            </p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.invoice}</p>
            <p className={styles.right}>{activeRow.string14}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.deliveryStatus}</p>
            <p className={styles.right}>{activeRow.string15}</p>
          </div>
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.tableContainer}>
          <div className={styles.headline}>История изменений</div>
          <Table
            left={true}
            columns={[
              {
                title: "Дата изменения",
                key: "datetime1",
                type: "datetime",
              },
              {
                title: "Что изменили",
                key: "string1",
                type: "string",
              },
              {
                title: "Кто изменил",
                key: "string2",
                type: "string",
              },
            ]}
            rows={[
              {
                datetime1: new Date(),
                string1: "Поменяли номер заказа",
                string2: "Татьяна",
              },
              {
                datetime1: new Date(),
                string1: "Поменяли номер заказа",
                string2: "Татьяна",
              },
              {
                datetime1: new Date(),
                string1: "Поменяли номер заказа",
                string2: "Татьяна",
              },
            ]}
            noData={localized.noData}
          />
        </div>
      </div>
    </Popup>
  );
};

export default OrderListPopup;
