// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrdersStatisticsPage_wrapper__SwRKA {
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100vw - 190px);
  padding: 10px;
  flex-direction: column;
}

.OrdersStatisticsPage_title__oaCth {
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  text-align: center;
  margin-bottom: 10px;
}

.OrdersStatisticsPage_charts__uDyJl {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.OrdersStatisticsPage_chart__iHBEH {
  width: 600px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/modules/orders/ordersStatistics/OrdersStatisticsPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,2BAAA;EACA,0BAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,eAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;AACF","sourcesContent":[".wrapper {\n  height: 100vh;\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  width: calc(100vw - 190px);\n  padding: 10px;\n  flex-direction: column;\n}\n\n.title {\n  font-size: 24px;\n  font-weight: 400;\n  line-height: 31px;\n  text-align: center;\n  margin-bottom: 10px;\n}\n\n.charts {\n  margin-top: 20px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.chart {\n  width: 600px;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `OrdersStatisticsPage_wrapper__SwRKA`,
	"title": `OrdersStatisticsPage_title__oaCth`,
	"charts": `OrdersStatisticsPage_charts__uDyJl`,
	"chart": `OrdersStatisticsPage_chart__iHBEH`
};
export default ___CSS_LOADER_EXPORT___;
