import React, { FC, useState } from "react";
import Input from "kit/input/Input";
import localized from "helpers/localized";
import styles from "./ReportGoodsFilter.module.scss";
import Calendar from "kit/calendar/Calendar";
import { RootState } from "store/store";
import { useSelector } from "react-redux";

const ReportGoodsFilter: FC = () => {
  const main = useSelector((state: RootState) => state.main);

  const [orderNumber, setOrderNumber] = useState("");
  const [caseNumber, setCaseNumber] = useState("");
  const [status, setStatus] = useState("");
  const [createdFrom, setCreatedFrom] = useState("");
  const [createdTo, setCreatedTo] = useState("");
  const [changedFrom, setChangedFrom] = useState("");
  const [changedTo, setChangedTo] = useState("");
  const [showcase, setShowcase] = useState("");
  const [product, setProduct] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [number, setNumber] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [fullCategoryName, setFullCategoryName] = useState("");
  const [provider, setProvider] = useState("");
  const [denominationFrom, setDenominationFrom] = useState("");
  const [denominationTo, setDenominationTo] = useState("");
  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");
  const [quantityFrom, setQuantityFrom] = useState("");
  const [quantityTo, setQuantityTo] = useState("");
  const [fullPriceFrom, setFullPriceFrom] = useState("");
  const [fullPriceTo, setFullPriceTo] = useState("");
  const [userPriceFrom, setUserPriceFrom] = useState("");
  const [userPriceTo, setUserPriceTo] = useState("");

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{localized.tableFilter}</div>

      <div className={styles.elements}>
        <div className={styles.element}>
          <Input
            id="orderNumber"
            value={orderNumber}
            label={localized.orderNumber}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setOrderNumber(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="caseNumber"
            value={caseNumber}
            label={localized.caseNumber}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCaseNumber(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="status"
            value={status}
            label={localized.status}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setStatus(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Calendar
            id="createdFrom"
            label={localized.created + " " + localized.from}
            value={createdFrom}
            onChange={(value) => {
              setCreatedFrom(value);
            }}
            locale={main.language}
            day={localized.day}
            month={localized.month}
            year={localized.year}
          />{" "}
        </div>

        <div className={styles.element}>
          <Calendar
            id="createdTo"
            label={localized.created + " " + localized.to}
            value={createdTo}
            onChange={(value) => {
              setCreatedTo(value);
            }}
            locale={main.language}
            day={localized.day}
            month={localized.month}
            year={localized.year}
          />{" "}
        </div>

        <div className={styles.element}>
          <Calendar
            id="changedFrom"
            label={localized.changed + " " + localized.from}
            value={changedFrom}
            onChange={(value) => {
              setChangedFrom(value);
            }}
            locale={main.language}
            day={localized.day}
            month={localized.month}
            year={localized.year}
          />{" "}
        </div>

        <div className={styles.element}>
          <Calendar
            id="changedTo"
            label={localized.changed + " " + localized.to}
            value={changedTo}
            onChange={(value) => {
              setChangedTo(value);
            }}
            locale={main.language}
            day={localized.day}
            month={localized.month}
            year={localized.year}
          />{" "}
        </div>

        <div className={styles.element}>
          <Input
            id="showcase"
            value={showcase}
            label={localized.showcase}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setShowcase(e);
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            id="product"
            value={product}
            label={localized.product}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setProduct(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="manufacturer"
            value={manufacturer}
            label={localized.manufacturer}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setManufacturer(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="number"
            value={number}
            label={localized.number}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setNumber(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="categoryName"
            value={categoryName}
            label={localized.categoryName}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCategoryName(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="fullCategoryName"
            value={fullCategoryName}
            label={localized.fullCategoryName}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFullCategoryName(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="provider"
            value={provider}
            label={localized.provider}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setProvider(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="denominationFrom"
            value={denominationFrom}
            label={localized.denomination + " " + localized.from}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDenominationFrom(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="denominationTo"
            value={denominationTo}
            label={localized.denomination + " " + localized.to}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDenominationTo(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="priceFrom"
            value={priceFrom}
            label={localized.price + " " + localized.from}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPriceFrom(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="priceTo"
            value={priceTo}
            label={localized.price + " " + localized.to}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPriceTo(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="quantityFrom"
            value={quantityFrom}
            label={localized.quantity + " " + localized.from}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setQuantityFrom(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="quantityTo"
            value={quantityTo}
            label={localized.quantity + " " + localized.to}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setQuantityTo(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="fullPriceFrom"
            value={fullPriceFrom}
            label={localized.fullPrice + " " + localized.from}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFullPriceFrom(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="fullPriceTo"
            value={fullPriceTo}
            label={localized.fullPrice + " " + localized.to}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFullPriceTo(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="userPriceFrom"
            value={userPriceFrom}
            label={localized.userPrice + " " + localized.from}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setUserPriceFrom(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="userPriceTo"
            value={userPriceTo}
            label={localized.userPrice + " " + localized.to}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setUserPriceTo(e);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportGoodsFilter;
