import React, { FC, useState } from "react";
import styles from "./NewUser.module.scss";
import localized from "helpers/localized";
import Input from "kit/input/Input";
import Select from "kit/select/Select";
import Button from "kit/button/Button";
import {
  validateEmail,
  validateLength,
  validatePassword,
  validatePhone,
} from "helpers/validators";
import { fetchCreateNewUser } from "modules/users/store/UsersAC";
import { useDispatch, useSelector } from "react-redux";
import { translateCodes } from "helpers/translateCodes";
import { RootState } from "store/store";

const NewUser: FC = () => {
  const dispatch = useDispatch();

  const dealers = useSelector(
    (state: RootState) => state.dealers.dealersAll.items,
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState({});
  const [dealerIds, setDealerIds] = useState([]);
  const [loginType, setLoginType] = useState({
    value: "phone",
    title: localized.phone,
  });
  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [roleValid, setRoleValid] = useState(true);

  const validateDataNewUser = () => {
    setEmailValid(validateEmail(email, true));
    setPhoneValid(validatePhone(phone, true));
    setPasswordValid(validatePassword(password));
    setRoleValid(validateLength(role.value, 0, true));

    if (
      validateEmail(email, true) &&
      validatePhone(phone, true) &&
      validatePassword(password) &&
      validateLength(role.value, 0, true)
    ) {
      dispatch(
        fetchCreateNewUser({
          firstName,
          lastName,
          middleName,
          position,
          email,
          phone,
          password,
          role: role.value,
          dealerIds: dealerIds.map((a) => a.id),
          loginType: loginType.value,
        }),
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.elements}>
        <div className={styles.headline}>{localized.addUser}</div>
        <div className={styles.firstBlock}>
          <div className={styles.element}>
            <Input
              id="firstName"
              value={firstName}
              label={localized.name}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFirstName(e);
              }}
            />
          </div>
          <div className={styles.element}>
            <Input
              id="lastName"
              value={lastName}
              label={localized.lastName}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLastName(e);
              }}
            />
          </div>
          <div className={styles.element}>
            <Input
              id="middleName"
              value={middleName}
              label={localized.middleName}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setMiddleName(e);
              }}
            />
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.element}>
            <Input
              id="position"
              value={position}
              label={localized.position}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPosition(e);
              }}
            />
          </div>
          <div className={styles.element}>
            <Input
              type="phone"
              id="phone"
              label={localized.phone + "*"}
              value={phone}
              errorMessage={localized.phoneError}
              error={!phoneValid}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPhone(e);
                setPhoneValid(true);
              }}
              onBlur={() => {
                setPhoneValid(validatePhone(phone, true));
              }}
            />
          </div>
          <div className={styles.element}>
            <Input
              type="email"
              id="email"
              errorMessage={localized.emailError}
              value={email}
              label={localized.email + "*"}
              error={!emailValid}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(e);
                setEmailValid(true);
              }}
              onBlur={() => {
                setEmailValid(validateEmail(email, true));
              }}
            />
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.element}>
            <Input
              id="password"
              error={!passwordValid}
              errorMessage={localized.PASSWORD_NOT_VALID}
              value={password}
              label={localized.password + "*"}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(e);
                setPasswordValid(true);
              }}
              onBlur={() => {
                setPasswordValid(validatePassword(password));
              }}
            />
          </div>

          <div className={styles.element}>
            <Select
              id="role"
              label={localized.role + "*"}
              value={role}
              onChange={(value) => {
                setRole(value);
                setRoleValid(true);
              }}
              error={!roleValid}
              errorMessage={localized.selectRole}
              source={"title"}
              list={[
                { value: "ADMIN", title: translateCodes("ADMIN") },
                {
                  value: "MGC_EMPLOYEE",
                  title: translateCodes("MGC_EMPLOYEE"),
                },
                {
                  value: "DEALER_MANAGER",
                  title: translateCodes("DEALER_MANAGER"),
                },
                {
                  value: "DEALER_EMPLOYEE",
                  title: translateCodes("DEALER_EMPLOYEE"),
                },
              ]}
            />
          </div>

          <div className={styles.element}>
            <Select
              id="dealers"
              multiple
              label={localized.dealers}
              value={dealerIds}
              onChange={(value) => {
                setDealerIds(value);
              }}
              source="name"
              list={dealers}
            />
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.element}>
            <Select
              id="loginType"
              label={localized.login}
              value={loginType}
              onChange={(value) => {
                setLoginType(value);
              }}
              description={localized.whatLogin}
              source={"title"}
              list={[
                { value: "phone", title: localized.phone },
                { value: "email", title: localized.email },
              ]}
            />
          </div>

          <div className={styles.element}>
            <Button
              id="addUser"
              onClick={() => {
                validateDataNewUser();
              }}
            >
              {localized.addUser}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUser;
