import React, { FC, useState } from "react";
import styles from "./SupportPopup.module.scss";
import localized from "helpers/localized";
import Input from "kit/input/Input";
import Button from "kit/button/Button";
import { ReactComponent as DeleteIcon } from "assets/icons/Delete.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/Download.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { setDownloadedFile } from "../store/SupportSlice";
import Drop from "kit/drop/Drop";

const SupportPopup: FC = ({ setOpenPopup, activeRow }) => {
  const dispatch = useDispatch();

  const [text, setText] = useState("");

  const support = useSelector((state: RootState) => state.support);

  const fileSelected = async (e: any) => {
    if (e.target.files?.length) {
      // dispatch(fetchExcelProduct(e.target.files[0]));
    }
  };

  const fileDragAndDrop = async (file: any) => {
    if (file) {
      // dispatch(fetchExcelProduct(file));
    }
  };

  const inputFileRef = React.useRef();

  return (
    <div className={styles.wrapper}>
      <Drop upload={fileDragAndDrop} />
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.contentLabel}>{localized.support}</p>
          <div
            className={styles.close}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <p>{localized.close.toLowerCase()}</p>
          </div>
        </div>

        <div className={styles.content}>
          <h1>Задача МС 71</h1>

          <div className={styles.block}>
            <div className={styles.task}>
              Выполните задачу, продайте слона, срочно
            </div>
            <div className={styles.date}>Выполнить до: 01.05.2024</div>
          </div>

          <div className={styles.block}>
            <div className={styles.headline}>Выполнение</div>

            <div className={styles.element}>
              <Input
                id="text"
                value={text}
                label={localized.message}
                length={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setText(e);
                }}
              />
            </div>

            <div className={styles.fileWrapper}>
              <Button
                id="uploadFile"
                secondary
                onClick={() => {
                  if (support.downloadedFile) {
                    dispatch(setDownloadedFile(false));
                  } else {
                    inputFileRef.current.click();
                  }
                }}
              >
                <div className={styles.downloadText}>
                  {support.downloadedFile
                    ? localized.deleteFile
                    : localized.uploadFile}
                </div>
                <div
                  className={
                    support.downloadedFile ? styles.download : styles.upload
                  }
                >
                  {support.downloadedFile ? (
                    <DeleteIcon stroke={"#000000"} />
                  ) : (
                    <DownloadIcon fill={"#000000"} />
                  )}
                </div>
              </Button>
              <input
                id="upload-file"
                className={styles.file}
                type="file"
                name="upload-file"
                value=""
                ref={inputFileRef}
                onChange={fileSelected}
              />
            </div>

            <div className={styles.element}>
              <Button id="checkSupport" onClick={() => {}}>
                Отправить на проверку
              </Button>
            </div>
          </div>

          <div className={styles.block}>
            <div className={styles.headline}>Переписка</div>
            <div className={styles.message}>
              <div className={styles.messageTitle}>
                <div className={styles.messageName}>Иванов Петр Иванович</div>
                <div className={styles.messageDate}> 29.11.23</div>
              </div>
              <div className={styles.messageText}> Точно</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportPopup;
