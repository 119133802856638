import localized from "helpers/localized";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setError, setInfo, setLoading } from "modules/main/store/MainSlice";
import { usersApi } from "./UsersApi";
import { errorCodes } from "helpers/functions";
import {
  setUserInfo,
  setUsers,
  setUsersAll,
  setPage,
  setFiltration,
} from "./UsersSlice";

export const fetchCreateNewUser = createAsyncThunk(
  "fetchCreateNewUser",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await usersApi.newUser({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      thunkAPI.dispatch(setLoading(false));
      if (response?.data?.data?.id) {
        thunkAPI.dispatch(setInfo(localized.newUser));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchChangeUser = createAsyncThunk(
  "fetchChangeUser",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await usersApi.changeUser({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      thunkAPI.dispatch(setLoading(false));
      if (response?.data?.data?.id) {
        thunkAPI.dispatch(fetchUserInfo({ id: data.id }));
        thunkAPI.dispatch(fetchUsers(thunkAPI?.getState()?.users?.filtration));
        thunkAPI.dispatch(fetchUsersAll());
        thunkAPI.dispatch(setInfo(localized.dataChanged));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchUserInfo = createAsyncThunk(
  "fetchUserInfo",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));

      const response = await usersApi.userInfo({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      thunkAPI.dispatch(setLoading(false));

      if (response?.data?.data) {
        thunkAPI.dispatch(setUserInfo(response?.data?.data));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchUsers = createAsyncThunk(
  "fetchUsers",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setFiltration(data));
      if (data.loading) thunkAPI.dispatch(setLoading(true));
      const response = await usersApi.users({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      if (response?.data?.data) {
        thunkAPI.dispatch(setUsers(response?.data?.data));
        thunkAPI.dispatch(setPage(data.page));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
      if (data.loading) thunkAPI.dispatch(setLoading(false));
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchUsersAll = createAsyncThunk(
  "fetchUsersAll",
  async (_, thunkAPI) => {
    try {
      const response = await usersApi.allUsers({
        token: thunkAPI?.getState()?.login?.token,
      });
      if (response?.data?.data) {
        thunkAPI.dispatch(setUsersAll(response?.data?.data));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchUserArchive = createAsyncThunk(
  "fetchUserArchive",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));

      const response = await usersApi.archive({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      if (response?.data?.data === null) {
        thunkAPI.dispatch(fetchUserInfo({ id: data.id }));
        thunkAPI.dispatch(fetchUsers(thunkAPI?.getState()?.users?.filtration));
        thunkAPI.dispatch(fetchUsersAll());
        thunkAPI.dispatch(setInfo("Пользователь архивирован"));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
      thunkAPI.dispatch(setLoading(false));
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchUserUnarchive = createAsyncThunk(
  "fetchUserUnarchive",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await usersApi.unarchive({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      if (response?.data?.data === null) {
        thunkAPI.dispatch(fetchUserInfo({ id: data.id }));
        thunkAPI.dispatch(fetchUsers(thunkAPI?.getState()?.users?.filtration));
        thunkAPI.dispatch(fetchUsersAll());
        thunkAPI.dispatch(setInfo("Пользователь разархивирован"));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
      thunkAPI.dispatch(setLoading(false));
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchResetPassword = createAsyncThunk(
  "fetchResetPassword",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await usersApi.resetPassword({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      if (response?.data?.data === null) {
        thunkAPI.dispatch(setInfo(localized.linkPassword));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
      thunkAPI.dispatch(setLoading(false));
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);
