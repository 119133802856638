import React, { FC, useEffect, useState } from "react";
import styles from "./ChangeUser.module.scss";
import localized from "helpers/localized";
import Input from "kit/input/Input";
import Select from "kit/select/Select";
import Button from "kit/button/Button";
import {
  validateEmail,
  validateLength,
  validatePhone,
} from "helpers/validators";
import {
  fetchChangeUser,
  fetchUserArchive,
  fetchUserInfo,
  fetchUserUnarchive,
  fetchResetPassword,
} from "modules/users/store/UsersAC";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";

import { translateCodes } from "helpers/translateCodes";

const ChangeUser: FC = () => {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.users.user);
  const dealers = useSelector(
    (state: RootState) => state.dealers.dealersAll.items,
  );

  const [id, setId] = useState("");
  const [idValid, setIdValid] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [position, setPosition] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneValid, setPhoneValid] = useState(true);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [role, setRole] = useState({});
  const [dealerIds, setDealerIds] = useState([]);
  const [status, setStatus] = useState("");

  useEffect(() => {
    setId(user.id ? String(user.id) : "");
    setFirstName(user.firstName || "");
    setLastName(user.lastName || "");
    setMiddleName(user.middleName || "");
    setPosition(user.position || "");
    setPhone(user.phone || "");
    setPhoneValid(true);
    setEmail(user.email || "");
    setEmailValid(true);

    if (user.role) {
      setRole({
        value: user.role,
        title: translateCodes(user.role),
      });
    }
    setDealerIds(user.dealers || []);
    setStatus(user.status || "");
  }, [user]);

  const validateDataNewUser = () => {
    setIdValid(validateLength(id, 0, true));
    setEmailValid(validateEmail(email, false));
    setPhoneValid(validatePhone(phone, false));

    if (
      validateLength(id, 0, true) &&
      validateEmail(email, false) &&
      validatePhone(phone, false)
    ) {
      dispatch(
        fetchChangeUser({
          id,
          firstName,
          lastName,
          middleName,
          position,
          phone,
          email,
          role: role.value,
          dealerIds: dealerIds.map((a) => a.id),
        }),
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.elements}>
        <div className={styles.headline}>{localized.changeUser}</div>
        <div className={styles.firstBlock}>
          <div className={styles.element}>
            <Input
              disabled={!status}
              id="firstNameChange"
              value={firstName}
              label={localized.name}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFirstName(e);
              }}
            />
          </div>
          <div className={styles.element}>
            <Input
              disabled={!status}
              id="lastNameChange"
              value={lastName}
              label={localized.lastName}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLastName(e);
              }}
            />
          </div>
          <div className={styles.element}>
            <Input
              disabled={!status}
              id="middleNameChange"
              value={middleName}
              label={localized.middleName}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setMiddleName(e);
              }}
            />
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.element}>
            <Input
              disabled={!status}
              id="positionChange"
              value={position}
              label={localized.position}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPosition(e);
              }}
            />
          </div>

          <div className={styles.element}>
            <Input
              disabled={!status}
              type="phone"
              id="phone"
              label={localized.phone}
              value={phone}
              errorMessage={localized.phoneError}
              error={!phoneValid}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPhone(e);
                setPhoneValid(true);
              }}
              onBlur={() => {
                setPhoneValid(validatePhone(phone, false));
              }}
            />
          </div>
          <div className={styles.element}>
            <Input
              disabled={!status}
              type="email"
              id="email"
              errorMessage={localized.emailError}
              value={email}
              label={localized.email}
              error={!emailValid}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(e);
                setEmailValid(true);
              }}
              onBlur={() => {
                setEmailValid(validateEmail(email, false));
              }}
            />
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.element}>
            <Select
              disabled={!status}
              id="roleChange"
              label={localized.role}
              value={role}
              onChange={(value) => {
                setRole(value);
              }}
              source="title"
              list={[
                { value: "ADMIN", title: translateCodes("ADMIN") },
                {
                  value: "MGC_EMPLOYEE",
                  title: translateCodes("MGC_EMPLOYEE"),
                },
                {
                  value: "DEALER_MANAGER",
                  title: translateCodes("DEALER_MANAGER"),
                },
                {
                  value: "DEALER_EMPLOYEE",
                  title: translateCodes("DEALER_EMPLOYEE"),
                },
              ]}
            />
          </div>

          <div className={styles.element}>
            <Select
              disabled={!status}
              id="dealers"
              multiple
              label={localized.dealers}
              value={dealerIds}
              onChange={(value) => {
                setDealerIds(value);
              }}
              source="name"
              list={dealers}
            />
          </div>

          <div className={styles.element}>
            <Input
              id="statusChange"
              label={localized.status}
              value={status ? translateCodes(status) : ""}
              disabled
            />
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.element}>
            <Input
              id="id"
              value={id}
              allowed="numbers"
              label={localized.userId + "*"}
              error={!idValid}
              errorMessage={localized.enterUserId}
              length={10}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setId(e);
              }}
            />
          </div>
          <div className={styles.element}>
            <Button
              id="getUserData"
              onClick={() => {
                setIdValid(validateLength(id, 0, true));

                if (validateLength(id, 0, true)) {
                  dispatch(
                    fetchUserInfo({
                      id,
                    }),
                  );
                }
              }}
            >
              {localized.getUserData}
            </Button>
          </div>
          <div className={styles.element}>
            <Button
              id="changeUser"
              disabled={!status}
              onClick={() => {
                validateDataNewUser();
              }}
            >
              {localized.changeUser}
            </Button>
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.element}>
            <Button
              id="toArchive"
              disabled={!status}
              onClick={() => {
                if (status === "DELETED") {
                  dispatch(fetchUserUnarchive({ id }));
                } else {
                  dispatch(fetchUserArchive({ id }));
                }
              }}
            >
              {status === "DELETED"
                ? localized.backArchive
                : localized.toArchive}
            </Button>
          </div>
          <div className={styles.element}>
            <Button
              id="resetPassword"
              disabled={!status}
              onClick={() => {
                dispatch(fetchResetPassword({ login: user.login }));
              }}
            >
              {localized.resetPassword}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeUser;
