import localized from "helpers/localized";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { registrationApi } from "./RegistrationApi";
import { setError, setLoading } from "modules/main/store/MainSlice";
import { checkPage, fetchData } from "modules/main/store/MainAC";
import { setTokenCode } from "./RegistrationSlice";
import { setToken } from "modules/login/store/LoginSlice";
import { setStep } from "./RegistrationSlice";
import { errorCodes } from "helpers/functions";

export const fetchRegistrationStep1 = createAsyncThunk(
  "fetchRegistrationStep1",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await registrationApi.registration(data);
      thunkAPI.dispatch(setLoading(false));

      if (response?.data?.data?.token) {
        thunkAPI.dispatch(setTokenCode(response.data.data.token));
        thunkAPI.dispatch(setStep("step2"));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchRegistrationStep2 = createAsyncThunk(
  "fetchRegistrationStep2",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await registrationApi.sendCode({
        ...data,
        token: thunkAPI?.getState()?.registration?.tokenCode,
      });
      thunkAPI.dispatch(setLoading(false));

      if (response?.data?.data?.token) {
        localStorage.setItem("token", response.data.data.token);
        thunkAPI.dispatch(setToken(response.data.data.token));
        thunkAPI.dispatch(setStep("step3"));
        thunkAPI.dispatch(setTokenCode(""));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchRegistrationResendCode = createAsyncThunk(
  "fetchRegistrationResendCode",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await registrationApi.resendCode(
        thunkAPI?.getState()?.registration?.tokenCode,
      );
      thunkAPI.dispatch(setLoading(false));

      if (response?.data?.data?.token) {
        thunkAPI.dispatch(setTokenCode(response.data.data.token));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchRegistrationCompanyData = createAsyncThunk(
  "fetchRegistrationCompanyData",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await registrationApi.sendCompanyData({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      thunkAPI.dispatch(setLoading(false));

      if (response.data.data === null) {
        await thunkAPI.dispatch(fetchData());
        await thunkAPI.dispatch(checkPage());
        thunkAPI.dispatch(setStep("step1"));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);
