// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderListPopup_headline__RUWnA {
  font-size: 26px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-bottom: 10px;
}

.OrderListPopup_content__n13DZ h1 {
  margin-bottom: 26px;
  padding: 20px;
}

.OrderListPopup_rows__RzcAr {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.OrderListPopup_row__or2Mg {
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: calc(25% - 40px);
  min-width: 250px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  margin: 0 20px;
}

@media (max-width: 1190px) {
  .OrderListPopup_row__or2Mg {
    max-width: calc(33% - 37px);
  }
}
@media (max-width: 950px) {
  .OrderListPopup_row__or2Mg {
    max-width: calc(50% - 40px);
  }
}
@media (max-width: 620px) {
  .OrderListPopup_row__or2Mg {
    max-width: calc(100% - 40px);
  }
}
.OrderListPopup_left__q1n3F {
  color: rgba(0, 0, 0, 0.6);
}

.OrderListPopup_right__b0Vxo {
  font-weight: 500;
}

.OrderListPopup_table__EVJ0u {
  padding: 20px;
  margin-top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.OrderListPopup_tableContainer__EUg2E {
  width: 75%;
}

@media (max-width: 1300px) {
  .OrderListPopup_tableContainer__EUg2E {
    width: 90%;
  }
}
@media (max-width: 1160px) {
  .OrderListPopup_tableContainer__EUg2E {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/modules/orders/ordersList/OrderListPopup/OrderListPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,eAAA;EACA,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,2BAAA;EACA,gBAAA;EACA,2CAAA;EACA,cAAA;AACF;;AAEA;EACE;IACE,2BAAA;EACF;AACF;AAEA;EACE;IACE,2BAAA;EAAF;AACF;AAGA;EACE;IACE,4BAAA;EADF;AACF;AAIA;EACE,yBAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,aAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AAFF;;AAKA;EACE,UAAA;AAFF;;AAKA;EACE;IACE,UAAA;EAFF;AACF;AAKA;EACE;IACE,WAAA;EAHF;AACF","sourcesContent":[".headline {\n  font-size: 26px;\n  font-weight: 500;\n  line-height: 20px;\n  text-align: left;\n  margin-bottom: 10px;\n}\n\n.content h1 {\n  margin-bottom: 26px;\n  padding: 20px;\n}\n\n.rows {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  width: 100%;\n}\n\n.row {\n  min-height: 60px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  max-width: calc(25% - 40px);\n  min-width: 250px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.5);\n  margin: 0 20px;\n}\n\n@media (max-width: 1190px) {\n  .row {\n    max-width: calc(33% - 37px);\n  }\n}\n\n@media (max-width: 950px) {\n  .row {\n    max-width: calc(50% - 40px);\n  }\n}\n\n@media (max-width: 620px) {\n  .row {\n    max-width: calc(100% - 40px);\n  }\n}\n\n.left {\n  color: rgba(0, 0, 0, 0.6);\n}\n\n.right {\n  font-weight: 500;\n}\n\n.table {\n  padding: 20px;\n  margin-top: 40px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n.tableContainer {\n  width: 75%;\n}\n\n@media (max-width: 1300px) {\n  .tableContainer {\n    width: 90%;\n  }\n}\n\n@media (max-width: 1160px) {\n  .tableContainer {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline": `OrderListPopup_headline__RUWnA`,
	"content": `OrderListPopup_content__n13DZ`,
	"rows": `OrderListPopup_rows__RzcAr`,
	"row": `OrderListPopup_row__or2Mg`,
	"left": `OrderListPopup_left__q1n3F`,
	"right": `OrderListPopup_right__b0Vxo`,
	"table": `OrderListPopup_table__EVJ0u`,
	"tableContainer": `OrderListPopup_tableContainer__EUg2E`
};
export default ___CSS_LOADER_EXPORT___;
