// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Message_message__MZmyP {
  z-index: 10000;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 32px 20px;
  border: solid 1px black;
}

.Message_button__pSN9G {
  width: 100%;
}

.Message_title__ygmvh {
  margin: 16px 0 16px 0px;
}

.Message_wrapper__RZTm0 {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10002;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.2);
}

.Message_error__z4C3j {
  background-color: #ffeb6b;
}

.Message_success__a7KNy {
  background-color: #d7f849;
}

@media (max-width: 370px) {
  .Message_messages__FEOt4 {
    width: 100vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/kit/message/Message.module.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,uBAAA;AADF;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,uBAAA;AADF;;AAIA;EACE,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,aAAA;EACA,oCAAA;AADF;;AAIA;EACE,yBAAA;AADF;;AAIA;EACE,yBAAA;AADF;;AAIA;EACE;IACE,YAAA;EADF;AACF","sourcesContent":["@import \"../variables\";\n\n.message {\n  z-index: 10000;\n  width: 350px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  text-align: center;\n  padding: 32px 20px;\n  border: solid 1px black;\n}\n\n.button {\n  width: 100%;\n}\n\n.title {\n  margin: 16px 0 16px 0px;\n}\n\n.wrapper {\n  position: fixed;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 10002;\n  width: 100vw;\n  height: 100vh;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  padding: 20px;\n  background-color: rgba(0, 0, 0, 0.2);\n}\n\n.error {\n  background-color: #ffeb6b;\n}\n\n.success {\n  background-color: #d7f849;\n}\n\n@media (max-width: 370px) {\n  .messages {\n    width: 100vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": `Message_message__MZmyP`,
	"button": `Message_button__pSN9G`,
	"title": `Message_title__ygmvh`,
	"wrapper": `Message_wrapper__RZTm0`,
	"error": `Message_error__z4C3j`,
	"success": `Message_success__a7KNy`,
	"messages": `Message_messages__FEOt4`
};
export default ___CSS_LOADER_EXPORT___;
