import React, { FC, useEffect, useState } from "react";
import styles from "./ProfilePage.module.scss";
import localized from "helpers/localized";
import Input from "kit/input/Input";
import Button from "kit/button/Button";
import Select from "kit/select/Select";
import Modal from "kit/modal/Modal";
import { translateCodes } from "helpers/translateCodes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  fetchChangeUser,
  fetchChangePhone,
  fetchChangeEmail,
  fetchPhoneCode,
  fetchEmailCode,
  fetchChangePassword,
} from "./store/ProfileAC";
import {
  validateEmail,
  validateLength,
  validateLengthEquals,
  validatePassword,
  validatePhone,
} from "helpers/validators";
import { setPage } from "modules/main/store/MainSlice";
import { setToken } from "modules/login/store/LoginSlice";

const ProfilePage: FC = () => {
  const dispatch = useDispatch();

  const profile = useSelector((state: RootState) => state.profile);
  const dealers = useSelector(
    (state: RootState) => state.dealers.dealersAll.items,
  );

  const [exit, setExit] = useState(false);
  const [firstName, setFirstName] = useState(profile.profile.firstName || "");
  const [lastName, setLastName] = useState(profile.profile.lastName || "");
  const [middleName, setMiddleName] = useState(
    profile.profile.middleName || "",
  );
  const [position, setPosition] = useState(profile.profile.position || "");

  const [role, setRole] = useState({
    value: profile.profile.role,
    title: translateCodes(profile.profile.role),
  });

  const [dealerIds, setDealerIds] = useState(profile.profile.dealers || []);
  const [status] = useState(profile.profile.status || {});
  const [email, setEmail] = useState(profile.profile.email || "");
  const [phone, setPhone] = useState(profile.profile.phone || "");
  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [codePhone, setCodePhone] = useState("");
  const [codeEmail, setCodeEmail] = useState("");
  const [codePhoneValid, setCodePhoneValid] = useState(true);
  const [codeEmailValid, setCodeEmailValid] = useState(true);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [oldPasswordValid, setOldPasswordValid] = useState(true);
  const [newPasswordValid, setNewPasswordValid] = useState(true);
  const [secondsPhone, setSecondsPhone] = useState(0);
  const [secondsEmail, setSecondsEmail] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (secondsPhone > 0) setSecondsPhone((secondsPhone) => secondsPhone - 1);
      if (secondsEmail > 0) setSecondsEmail((secondsEmail) => secondsEmail - 1);
    }, 1000);
    return () => clearInterval(timer);
  });

  const sendPhoneCode = () => {
    setPhoneValid(validatePhone(phone, true));
    if (validatePhone(phone, true)) {
      dispatch(fetchChangePhone({ phone }));
      setSecondsPhone(60);
    }
  };

  const sendEmailCode = () => {
    setEmailValid(validateEmail(email, true));
    if (validateEmail(email, true)) {
      dispatch(fetchChangeEmail({ email }));
      setSecondsEmail(60);
    }
  };

  const exitApprove = () => {
    localStorage.removeItem("token");
    dispatch(setToken(""));
    dispatch(setPage("login"));
  };

  const exitReject = () => {
    setExit(false);
  };

  return (
    <div className={styles.wrapper}>
      <Modal
        show={exit}
        title={localized.exit + "?"}
        buttonTextOk={localized.yes}
        buttonTextCancel={localized.no}
        onAccept={exitApprove}
        onCancel={exitReject}
      />

      <div className={styles.title}>{localized.profile}</div>
      <div className={styles.elements}>
        <div className={styles.headline}>{localized.changeProfile}</div>
        <div className={styles.firstBlock}>
          <div className={styles.element}>
            <Input
              id="name"
              value={firstName}
              label={localized.name}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFirstName(e);
              }}
            />
          </div>
          <div className={styles.element}>
            <Input
              id="lastName"
              value={lastName}
              label={localized.lastName}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLastName(e);
              }}
            />
          </div>
          <div className={styles.element}>
            <Input
              id="middleName"
              value={middleName}
              label={localized.middleName}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setMiddleName(e);
              }}
            />
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.element}>
            <Input
              id="position"
              value={position}
              label={localized.position}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPosition(e);
              }}
            />
          </div>
          <div className={styles.element}>
            <Select
              disabled
              id="role"
              label={localized.role}
              value={role}
              onChange={(value) => {
                setRole(value);
              }}
              source="title"
              list={[
                { value: "ADMIN", title: translateCodes("ADMIN") },
                {
                  value: "MGC_EMPLOYEE",
                  title: translateCodes("MGC_EMPLOYEE"),
                },
                {
                  value: "DEALER_MANAGER",
                  title: translateCodes("DEALER_MANAGER"),
                },
                {
                  value: "DEALER_EMPLOYEE",
                  title: translateCodes("DEALER_EMPLOYEE"),
                },
              ]}
            />
          </div>

          <div className={styles.element}>
            <Select
              id="dealers"
              disabled={profile.profile.role !== "MGC_EMPLOYEE"}
              multiple
              label={localized.dealers}
              value={dealerIds}
              onChange={(value) => {
                setDealerIds(value);
              }}
              source="name"
              list={dealers}
            />
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.element}>
            <Input
              id="status"
              value={translateCodes(status)}
              label={localized.status}
              disabled
            />
          </div>
          <div className={styles.element}>
            <Button
              id="changeUser"
              onClick={() => {
                dispatch(
                  fetchChangeUser({
                    id: profile.profile.id,
                    firstName,
                    lastName,
                    middleName,
                    position,
                    role: role.value,
                    dealerIds: dealerIds.map((a) => a.id),
                  }),
                );
              }}
            >
              {localized.change}
            </Button>
          </div>
        </div>

        <div className={styles.headline}>{localized.changePhone}</div>
        <div className={styles.block}>
          <div className={styles.element}>
            <Input
              type="phone"
              id="phone"
              label={localized.phone}
              value={phone}
              errorMessage={localized.phoneError}
              error={!phoneValid}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPhone(e);
                setPhoneValid(true);
              }}
              onBlur={() => {
                setPhoneValid(validatePhone(phone, true));
              }}
            />
          </div>
          <div className={styles.elementCode}>
            <Input
              id="codePhone"
              disabled={!profile.codePhone}
              error={!codePhoneValid}
              errorMessage={localized.codeError}
              value={codePhone}
              label={localized.code}
              allowed="numbers"
              length={4}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCodePhone(e);
                setCodePhoneValid(true);
              }}
              onBlur={() => {
                setCodePhoneValid(validateLengthEquals(codePhone, 4, true));
              }}
            />
            {profile.codePhone && (
              <div className={styles.textCode}>
                {secondsPhone
                  ? localized.left +
                    " " +
                    secondsPhone +
                    " " +
                    localized.secondsEnter.toLowerCase()
                  : localized.codeAgain}
              </div>
            )}
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.element}>
            <Button
              id="enterCodePhone"
              onClick={() => {
                if (profile.codePhone) {
                  setCodePhoneValid(validateLength(codePhone, 3, true));
                  if (validateLength(codePhone, 3, true)) {
                    dispatch(fetchPhoneCode({ phone, code: codePhone }));
                    setCodePhone("");
                  }
                } else {
                  sendPhoneCode();
                }
              }}
            >
              {profile.codePhone ? localized.enterCode : localized.change}
            </Button>
          </div>

          <div className={styles.element}>
            <Button
              id="resendPhone"
              disabled={!profile.codePhone}
              onClick={() => {
                sendPhoneCode();
              }}
            >
              {localized.resend}
            </Button>
          </div>
        </div>

        <div className={styles.headline}>{localized.changeEmail}</div>

        <div className={styles.block}>
          <div className={styles.element}>
            <Input
              type="email"
              id="email"
              errorMessage={localized.emailError}
              value={email}
              label={localized.email}
              error={!emailValid}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(e);
                setEmailValid(true);
              }}
              onBlur={() => {
                setEmailValid(validateEmail(email, true));
              }}
            />
          </div>
          <div className={styles.element}>
            <Input
              id="codeEmail"
              disabled={!profile.codeEmail}
              error={!codeEmailValid}
              errorMessage={localized.codeError}
              value={codeEmail}
              label={localized.code}
              allowed="numbers"
              length={4}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCodeEmail(e);
                setCodeEmailValid(true);
              }}
              onBlur={() => {
                setCodeEmailValid(validateLengthEquals(codeEmail, 4, true));
              }}
            />
            {profile.codeEmail && (
              <div className={styles.textCode}>
                {secondsEmail
                  ? localized.left +
                    " " +
                    secondsEmail +
                    " " +
                    localized.secondsEnter.toLowerCase()
                  : localized.codeAgain}
              </div>
            )}
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.element}>
            <Button
              id="enterCodeEmail"
              onClick={() => {
                if (profile.codeEmail) {
                  setCodeEmailValid(validateLength(codeEmail, 3, true));
                  if (validateLength(codeEmail, 3, true)) {
                    dispatch(fetchEmailCode({ email, code: codeEmail }));
                    setCodeEmail("");
                  }
                } else {
                  sendEmailCode();
                }
              }}
            >
              {profile.codeEmail ? localized.enterCode : localized.change}
            </Button>
          </div>

          <div className={styles.element}>
            <Button
              id="resendEmail"
              disabled={!profile.codeEmail}
              onClick={() => {
                sendEmailCode();
              }}
            >
              {localized.resend}
            </Button>
          </div>
        </div>

        <div className={styles.headline}>{localized.changePassword}</div>

        <div className={styles.block}>
          <div className={styles.element}>
            <Input
              id="oldPassword"
              error={!oldPasswordValid}
              errorMessage={localized.PASSWORD_NOT_VALID}
              value={oldPassword}
              label={localized.passwordOld}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setOldPassword(e);
                setOldPasswordValid(true);
              }}
              onBlur={() => {
                setOldPasswordValid(validatePassword(oldPassword));
              }}
            />
          </div>
          <div className={styles.element}>
            <Input
              id="newPassword"
              error={!newPasswordValid}
              errorMessage={localized.PASSWORD_NOT_VALID}
              value={newPassword}
              label={localized.passwordNew}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewPassword(e);
                setNewPasswordValid(true);
              }}
              onBlur={() => {
                setNewPasswordValid(validatePassword(newPassword));
              }}
            />
          </div>
          <div className={styles.element}>
            <Button
              id="changePassword"
              onClick={() => {
                setOldPasswordValid(validatePassword(oldPassword));
                setNewPasswordValid(validatePassword(newPassword));

                if (
                  validatePassword(oldPassword) &&
                  validatePassword(newPassword)
                ) {
                  dispatch(fetchChangePassword({ oldPassword, newPassword }));
                }
              }}
            >
              {localized.change}
            </Button>
          </div>
        </div>

        <div className={styles.exit}>
          <div className={styles.block}>
            <div className={styles.element}>
              <Button
                id="exit"
                onClick={() => {
                  setExit(true);
                }}
              >
                {localized.exit}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
