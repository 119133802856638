import React, { FC, useState } from "react";
import styles from "./AddingProduct.module.scss";
import localized from "helpers/localized";
import Input from "kit/input/Input";
import Button from "kit/button/Button";
import Select from "kit/select/Select";
import { ReactComponent as DeleteIcon } from "assets/icons/Delete.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/Download.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { fetchSendPhotoProduct, fetchSendProduct } from "../store/ProductsAC";
import { setPhoto } from "../store/ProductsSlice";
import Switch from "kit/switch/Switch";
import { validateLength, validateNumber } from "helpers/validators";
import Drop from "kit/drop/Drop";

const AddingProductInputs: FC = ({ setOpenInputs }) => {
  const dispatch = useDispatch();
  const products = useSelector((state: RootState) => state.products);

  const [category, setCategory] = useState("");
  const [categoryValid, setCategoryValid] = useState(true);
  const [productName, setProductName] = useState("");
  const [productNameValid, setProductNameValid] = useState(true);
  const [price, setPrice] = useState("");
  const [priceValid, setPriceValid] = useState(true);
  const [photoValid, setPhotoValid] = useState(true);

  const [link, setLink] = useState("");
  const [oldPrice, setOldPrice] = useState("");
  const [description, setDescription] = useState("");
  const [brand, setBrand] = useState("");
  const [archive, setArchive] = useState(false);
  const [article, setArticle] = useState("");
  const [guarantee, setGuarantee] = useState("");
  const [power, setPower] = useState("");
  const [barcode, setBarcode] = useState("");
  const [collection, setCollection] = useState("");
  const [ageFrom, setAgeFrom] = useState("");
  const [ageTo, setAgeTo] = useState("");
  const [gender, setGender] = useState("");
  const [color, setColor] = useState("");
  const [numberElements, setNumberElements] = useState("");
  const [numberNozzles, setNumberNozzles] = useState("");
  const [numberSpeeds, setNumberSpeeds] = useState("");
  const [material, setMaterial] = useState("");
  const [size, setSize] = useState("");
  const [type, setType] = useState("");
  const [dustLitres, setDustLitres] = useState("");
  const [bowlLitres, setBowlLitres] = useState("");
  const [housingMaterial, setHousingMaterial] = useState("");
  const [machinePower, setMachinePower] = useState("");

  const inputPhotoRef = React.useRef();

  const photoSelected = async (e: any) => {
    if (e.target.files?.length) {
      dispatch(fetchSendPhotoProduct(e.target.files[0]));
      setPhotoValid(true);
    }
  };

  const fileDragAndDrop = async (file: any) => {
    if (file) {
      dispatch(fetchSendPhotoProduct(file));
    }
  };

  const validateDate = () => {
    setCategoryValid(validateLength(category, 0, true));
    setProductNameValid(validateLength(productName, 0, true));
    setPriceValid(validateNumber(price, 0));

    if (products.photo) {
      setPhotoValid(true);
    } else {
      setPhotoValid(false);
    }

    if (
      validateLength(category, 0, true) &&
      validateLength(productName, 0, true) &&
      validateNumber(price, 0) &&
      products.photo
    ) {
      dispatch(fetchSendProduct());
    }
  };

  return (
    <div className={styles.modal}>
      <Drop upload={fileDragAndDrop} />
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.contentLabel}>{localized.addingManually}</p>
          <div
            className={styles.close}
            onClick={() => {
              setOpenInputs(false);
            }}
          >
            <p>{localized.close.toLowerCase()}</p>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.title}>{localized.requiredFields}</div>
          <div className={styles.elements}>
            <div className={styles.element}>
              <Select
                id="category"
                label={localized.category + "*"}
                value={category}
                onChange={(value) => {
                  setCategory(value);
                  setCategoryValid(true);
                }}
                error={!categoryValid}
                errorMessage={localized.selectCategory}
                list={[
                  "Категория 1",
                  "Категория 2",
                  "Категория 3",
                  "Категория 4",
                ]}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="productName"
                value={productName}
                label={localized.productName + "*"}
                length={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setProductName(e);
                  setProductNameValid(true);
                }}
                error={!productNameValid}
                errorMessage={localized.enterTitle}
              />
            </div>

            <div className={styles.element}>
              <Input
                id="price"
                value={price}
                label={localized.price + "*"}
                allowed="numbers"
                length={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPrice(e);
                  setPriceValid(true);
                }}
                error={!priceValid}
                errorMessage={localized.enterPrice}
              />
            </div>

            <div className={styles.fileWrapper}>
              <Button
                id="uploadPhoto"
                secondary
                onClick={() => {
                  if (products.photo) {
                    dispatch(setPhoto(""));
                  } else {
                    inputPhotoRef.current.click();
                  }
                }}
                error={!photoValid}
                errorMessage={localized.errorPhoto}
              >
                <div className={styles.downloadText}>
                  {products.photo
                    ? localized.deletePhoto
                    : localized.uploadPhoto}
                </div>
                <div
                  className={products.photo ? styles.download : styles.upload}
                >
                  {products.photo ? (
                    <DeleteIcon stroke={"#000000"} />
                  ) : (
                    <DownloadIcon fill={"#000000"} />
                  )}
                </div>
              </Button>
              <input
                id="upload-file"
                className={styles.file}
                type="file"
                name="upload-file"
                value=""
                ref={inputPhotoRef}
                onChange={photoSelected}
              />
            </div>
          </div>
          <div className={styles.title}>{localized.optionalFields}</div>
          <div className={styles.elements}>
            <div className={styles.element}>
              <Input
                id="link"
                value={link}
                label={localized.link}
                length={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLink(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="oldPrice"
                value={oldPrice}
                label={localized.oldPrice}
                allowed="numbers"
                length={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setOldPrice(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="description"
                value={description}
                label={localized.description}
                length={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDescription(e);
                }}
              />
            </div>

            <div className={styles.element}>
              <Input
                id="brand"
                value={brand}
                label={localized.brand}
                length={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setBrand(e);
                }}
              />
            </div>

            <div className={styles.element}>
              <Input
                id="article"
                value={article}
                label={localized.article}
                length={20}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setArticle(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="guarantee"
                value={guarantee}
                label={localized.guarantee}
                allowed="numbers"
                length={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setGuarantee(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="power"
                value={power}
                label={localized.power}
                allowed="numbers"
                length={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPower(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="barcode"
                value={barcode}
                label={localized.barcode}
                allowed="numbers"
                length={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setBarcode(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Select
                id="collection"
                label={localized.collection}
                value={collection}
                onChange={(value) => {
                  setCollection(value);
                }}
                list={[
                  "Коллекция 1",
                  "Коллекция 2",
                  "Коллекция 3",
                  "Коллекция 4",
                ]}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="ageFrom"
                value={ageFrom}
                label={localized.age + " " + localized.from}
                allowed="numbers"
                length={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAgeFrom(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="ageTo"
                value={ageTo}
                label={localized.age + " " + localized.to}
                allowed="numbers"
                length={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAgeTo(e);
                }}
              />
            </div>

            <div className={styles.element}>
              <Select
                id="gender"
                label={localized.gender}
                value={gender}
                onChange={(value) => {
                  setGender(value);
                }}
                list={["Мужский", "Женский"]}
              />
            </div>

            <div className={styles.element}>
              <Input
                id="color"
                value={color}
                label={localized.color}
                length={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setColor(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="numberElements"
                value={numberElements}
                label={localized.numberElements}
                allowed="numbers"
                length={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNumberElements(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="numberNozzles"
                value={numberNozzles}
                label={localized.numberNozzles}
                allowed="numbers"
                length={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNumberNozzles(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="numberSpeeds"
                value={numberSpeeds}
                label={localized.numberSpeeds}
                allowed="numbers"
                length={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNumberSpeeds(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="material"
                value={material}
                label={localized.material}
                length={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setMaterial(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="size"
                value={size}
                label={localized.size}
                allowed="numbers"
                length={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSize(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="type"
                value={type}
                label={localized.type}
                allowed="numbers"
                length={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setType(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="dustLitres"
                value={dustLitres}
                label={localized.dustLitres}
                allowed="numbers"
                length={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDustLitres(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="bowlLitres"
                value={bowlLitres}
                label={localized.bowlLitres}
                allowed="numbers"
                length={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setBowlLitres(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="housingMaterial"
                value={housingMaterial}
                label={localized.housingMaterial}
                length={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setHousingMaterial(e);
                }}
              />
            </div>
            <div className={styles.element}>
              <Input
                id="machinePower"
                value={machinePower}
                label={localized.machinePower}
                allowed="numbers"
                length={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setMachinePower(e);
                }}
              />
            </div>
            <div className={styles.switch}>
              <Switch
                id="emailSwitch"
                title={localized.archive.toLowerCase()}
                value={archive}
                onChange={() => {
                  setArchive(!archive);
                }}
              />
            </div>
          </div>

          <div className={styles.button}>
            <div className={styles.elem}>
              <Button
                id="addProduct"
                onClick={() => {
                  validateDate();
                }}
              >
                {localized.addProduct}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddingProductInputs;
