// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeliveryPage_wrapper__KGiT6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.DeliveryPage_title__UP\\+pb {
  font-size: 26px;
  font-weight: 500;
  line-height: 31px;
}

.DeliveryPage_elements__rAsdQ {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 5px;
}

.DeliveryPage_data__xgSvC {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DeliveryPage_block__-eJ2L {
  margin-right: 30px;
  margin-top: 30px;
  padding: 25px;
  width: 400px;
  background: white;
  border: 1px solid black;
  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 1;
  transition: all 300ms ease;
}

.DeliveryPage_blockTitle__GU41G {
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  margin-bottom: 10px;
}

.DeliveryPage_element__9m3An {
  width: 300px;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/modules/delivery/DeliveryPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,2BAAA;EACA,eAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;EACA,0CAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,UAAA;EACA,0BAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 30px;\n}\n\n.title {\n  font-size: 26px;\n  font-weight: 500;\n  line-height: 31px;\n}\n\n.elements {\n  display: flex;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  margin-top: 5px;\n}\n\n.data {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.block {\n  margin-right: 30px;\n  margin-top: 30px;\n  padding: 25px;\n  width: 400px;\n  background: white;\n  border: 1px solid black;\n  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.07);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  opacity: 1;\n  transition: all 300ms ease;\n}\n\n.blockTitle {\n  font-size: 22px;\n  font-weight: 500;\n  line-height: 26px;\n  text-align: left;\n  margin-bottom: 10px;\n}\n\n.element {\n  width: 300px;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `DeliveryPage_wrapper__KGiT6`,
	"title": `DeliveryPage_title__UP+pb`,
	"elements": `DeliveryPage_elements__rAsdQ`,
	"data": `DeliveryPage_data__xgSvC`,
	"block": `DeliveryPage_block__-eJ2L`,
	"blockTitle": `DeliveryPage_blockTitle__GU41G`,
	"element": `DeliveryPage_element__9m3An`
};
export default ___CSS_LOADER_EXPORT___;
