import React, { FC, useState } from "react";
import styles from "./DealersList.module.scss";
import localized from "helpers/localized";
import DealersPopup from "../DealersPopup/DealersPopup";
import Button from "kit/button/Button";
import DealersFilter from "../DealersFilter/DealersFilter";
import Table from "kit/table/Table";
import Pagination from "kit/pagination/Pagination";
import { fetchDealers } from "modules/dealers/store/DealersAC";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { translateCodes } from "helpers/translateCodes";
import { validateLengthEquals } from "helpers/validators";

const DealersList: FC = () => {
  const dispatch = useDispatch();
  const dealers = useSelector((state: RootState) => state.dealers);

  const [openPopup, setOpenPopup] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [rowId, setRowId] = useState({});

  const [id, setId] = useState(dealers.filtration.id);
  const [inn, setInn] = useState(dealers.filtration.inn);
  const [innValid, setInnValid] = useState(true);
  const [kpp, setKpp] = useState(dealers.filtration.kpp);
  const [kppValid, setKppValid] = useState(true);
  const [ogrn, setOgrn] = useState(dealers.filtration.ogrn);
  const [ogrnValid, setOgrnValid] = useState(true);
  const [legalAddress, setLegalAddress] = useState(
    dealers.filtration.legalAddress,
  );
  const [address, setAddress] = useState(dealers.filtration.address);
  const [name, setName] = useState(dealers.filtration.name);
  const [bankName, setBankName] = useState(dealers.filtration.bankName);
  const [bankAccount, setBankAccount] = useState(
    dealers.filtration.bankAccount,
  );
  const [bankAccountValid, setBankAccountValid] = useState(true);
  const [bankCorAccount, setBankCorAccount] = useState(
    dealers.filtration.bankCorAccount,
  );
  const [bankCorAccountValid, setBankCorAccountValid] = useState(true);
  const [bankBik, setBankBik] = useState(dealers.filtration.bankBik);
  const [bankBikValid, setBankBikValid] = useState(true);
  const [status, setStatus] = useState(dealers.filtration.status);

  const openRow = (row: any) => {
    setRowId(row.id);
    setOpenPopup(true);
  };

  const changePagePagination = (page) => {
    dispatch(
      fetchDealers({
        loading: true,
        page,
        id,
        inn,
        kpp,
        ogrn,
        legalAddress,
        address,
        name,
        status,
        bankName,
        bankAccount,
        bankCorAccount,
        bankBik,
      }),
    );
  };

  const newDealers = () => {
    let newDealers = [];
    for (let i = 0; i < dealers.dealers.items.length; i++) {
      const cardTitle = dealers.dealers.items[i].card && localized.downloaded;
      const contractTitle =
        dealers.dealers.items[i].contract && localized.downloaded;
      const dealerManagers = dealers.dealers.items[i].dealerManagers.length;
      newDealers.push({
        ...dealers.dealers.items[i],
        dealerManagers: dealerManagers,
        card: cardTitle,
        contract: contractTitle,
        status: translateCodes(dealers.dealers.items[i].status),
      });
    }

    return newDealers;
  };

  return (
    <>
      <DealersPopup
        show={openPopup}
        setOpenPopup={setOpenPopup}
        activeRow={
          dealers.dealers?.items?.filter((item) => item.id === rowId)[0]
        }
      />
      <div className={styles.wrapperTable}>
        <div className={styles.filterWrapper}>
          <div className={styles.titleWrapper}>
            <div className={styles.titleTable}>{localized.dealers}</div>
            <div className={styles.number}>
              {dealers.dealers.meta.itemCount}
            </div>
          </div>

          <div className={styles.filter}>
            {openFilter ? (
              <>
                <div className={styles.extraButton}>
                  <Button
                    id="close"
                    small
                    onClick={() => {
                      setOpenFilter(false);
                    }}
                  >
                    {localized.close}
                  </Button>
                </div>

                <div className={styles.extraButton}>
                  <Button
                    id="resetFilters"
                    small
                    onClick={() => {
                      setId("");
                      setInn("");
                      setKpp("");
                      setOgrn("");
                      setLegalAddress("");
                      setAddress("");
                      setName("");
                      setStatus("");

                      setInnValid(true);
                      setKppValid(true);
                      setOgrnValid(true);

                      dispatch(
                        fetchDealers({
                          loading: true,
                          page: 1,
                          id: "",
                          inn: "",
                          kpp: "",
                          ogrn: "",
                          legalAddress: "",
                          address: "",
                          name: "",
                          status: "",
                          bankName: "",
                          bankAccount: "",
                          bankCorAccount: "",
                          bankBik: "",
                        }),
                      );
                    }}
                  >
                    {localized.resetFilters}
                  </Button>
                </div>

                <Button
                  id="apply"
                  small
                  onClick={() => {
                    setInnValid(validateLengthEquals(inn, 10, false));

                    setKppValid(validateLengthEquals(kpp, 9, false));
                    setOgrnValid(validateLengthEquals(ogrn, 13, false));

                    if (
                      validateLengthEquals(inn, 10, false) &&
                      validateLengthEquals(kpp, 9, false) &&
                      validateLengthEquals(ogrn, 13, false)
                    ) {
                      dispatch(
                        fetchDealers({
                          loading: true,
                          page: 1,
                          id,
                          inn,
                          kpp,
                          ogrn,
                          legalAddress,
                          address,
                          name,
                          status,
                          bankName,
                          bankAccount,
                          bankCorAccount,
                          bankBik,
                        }),
                      );
                    }
                  }}
                >
                  {localized.apply}
                </Button>
              </>
            ) : (
              <Button
                id="tableFilter"
                small
                onClick={() => {
                  setOpenFilter(true);
                }}
              >
                {localized.tableFilter}
              </Button>
            )}
          </div>
        </div>
        <div className={styles.text}>{localized.doubleClickInfo}</div>

        {openFilter && (
          <DealersFilter
            id={id}
            setId={setId}
            inn={inn}
            setInn={setInn}
            innValid={innValid}
            setInnValid={setInnValid}
            kpp={kpp}
            setKpp={setKpp}
            kppValid={kppValid}
            setKppValid={setKppValid}
            ogrn={ogrn}
            setOgrn={setOgrn}
            ogrnValid={ogrnValid}
            setOgrnValid={setOgrnValid}
            legalAddress={legalAddress}
            setLegalAddress={setLegalAddress}
            address={address}
            setAddress={setAddress}
            name={name}
            setName={setName}
            status={status}
            setStatus={setStatus}
            bankName={bankName}
            setBankName={setBankName}
            bankAccount={bankAccount}
            setBankAccount={setBankAccount}
            bankAccountValid={bankAccountValid}
            setBankAccountValid={setBankAccountValid}
            bankCorAccount={bankCorAccount}
            setBankCorAccount={setBankCorAccount}
            bankCorAccountValid={bankCorAccountValid}
            setBankCorAccountValid={setBankCorAccountValid}
            bankBik={bankBik}
            setBankBik={setBankBik}
            bankBikValid={bankBikValid}
            setBankBikValid={setBankBikValid}
          />
        )}

        <div className={styles.table}>
          <Table
            onOpenRow={openRow}
            columns={[
              {
                title: "ID",
                key: "id",
                type: "string",
              },
              {
                title: localized.companyName,
                key: "name",
                type: "string",
              },
              {
                title: localized.inn,
                key: "inn",
                type: "string",
              },
              {
                title: localized.kpp,
                key: "kpp",
                type: "string",
              },
              {
                title: localized.ogrn,
                key: "ogrn",
                type: "string",
              },
              {
                title: localized.dealerManagers,
                key: "dealerManagers",
                type: "string",
              },
              {
                title: localized.legalAddress,
                key: "legalAddress",
                type: "string",
              },
              {
                title: localized.actualAddress,
                key: "address",
                type: "string",
              },
              {
                title: localized.bankName,
                key: "bankName",
                type: "string",
              },
              {
                title: localized.rs,
                key: "bankAccount",
                type: "string",
              },
              {
                title: localized.bik,
                key: "bankBik",
                type: "string",
              },
              {
                title: localized.cr,
                key: "bankCorAccount",
                type: "string",
              },
              {
                title: localized.contract,
                key: "contract",
                type: "string",
              },
              {
                title: localized.organizationCard,
                key: "card",
                type: "string",
              },
              {
                title: localized.status,
                key: "status",
                type: "string",
              },
            ]}
            rows={newDealers()}
            noData={localized.noData}
          />
        </div>
        {dealers.dealers.items.length > 0 && (
          <Pagination
            currentPage={dealers.page}
            lastPage={dealers.dealers.meta.pageCount}
            onAccept={changePagePagination}
          />
        )}
      </div>
    </>
  );
};

export default DealersList;
