import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  profile: {},
  codePhone: false,
  codeEmail: false,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileData: (state, action) => {
      state.profile = action.payload;
    },
    setCodePhone: (state, action) => {
      state.codePhone = action.payload;
    },
    setCodeEmail: (state, action) => {
      state.codeEmail = action.payload;
    },
    setNewPhone: (state, action) => {
      state.profile.phone = action.payload;
    },
    setNewEmail: (state, action) => {
      state.profile.email = action.payload;
    },
  },
});

export const {
  setProfileData,
  setCodePhone,
  setCodeEmail,
  setNewPhone,
  setNewEmail,
} = profileSlice.actions;

export default profileSlice.reducer;
