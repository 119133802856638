import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  page: "goods",
  pageGoods: 1,
  pageDelivery: 1,
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setPageReports: (state, action) => {
      state.page = action.payload;
    },
    setPageGoods: (state, action) => {
      state.pageGoods = action.payload;
    },
    setPageDelivery: (state, action) => {
      state.pageDelivery = action.payload;
    },
  },
});

export const { setPageReports, setPageGoods, setPageDelivery } =
  reportsSlice.actions;

export default reportsSlice.reducer;
