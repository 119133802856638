import axios from "axios";

export const registrationApi = {
  registration({ login, loginType, password }) {
    const formData = new FormData();
    formData.append("login", login);
    formData.append("loginType", loginType);
    formData.append("password", password);
    return axios({
      url: process.env.REACT_APP_REST_API + "registration/request-code",
      method: "POST",
      data: formData,
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  sendCode({ code, token }) {
    const formData = new FormData();
    formData.append("code", code);
    formData.append("type", "registration");
    return axios({
      url: process.env.REACT_APP_REST_API + "registration/confirm",
      method: "POST",
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  resendCode(token) {
    return axios({
      url: process.env.REACT_APP_REST_API + "registration/resend-code",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  sendCompanyData({
    firstName,
    lastName,
    middleName,
    position,
    companyName,
    companyINN,
    companyOGRN,
    companyKPP,
    contract,
    token,
  }) {
    const formData = new FormData();
    if (firstName) formData.append("firstName", firstName);
    if (lastName) formData.append("lastName", lastName);
    if (middleName) formData.append("middleName", middleName);
    if (position) formData.append("position", position);
    if (companyName) formData.append("companyName", companyName);
    if (companyINN) formData.append("companyINN", companyINN);
    if (companyOGRN) formData.append("companyOGRN", companyOGRN);
    if (companyKPP) formData.append("companyKPP", companyKPP);
    if (contract) formData.append("contract", contract);
    return axios({
      url: process.env.REACT_APP_REST_API + "registration",
      method: "POST",
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },
};
