// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UsersFilter_title__HwVYx {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}

.UsersFilter_wrapper__fOCcj {
  margin-bottom: 20px;
}

.UsersFilter_elements__T9pjR {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 5px;
}

.UsersFilter_element__J8jZC {
  margin-top: 10px;
  margin-right: 20px;
  width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/modules/users/components/UsersFilter/UsersFilter.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,2BAAA;EACA,eAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,YAAA;AACF","sourcesContent":[".title {\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 26px;\n  text-align: left;\n}\n\n.wrapper {\n  margin-bottom: 20px;\n}\n\n.elements {\n  display: flex;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  margin-top: 5px;\n}\n\n.element {\n  margin-top: 10px;\n  margin-right: 20px;\n  width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `UsersFilter_title__HwVYx`,
	"wrapper": `UsersFilter_wrapper__fOCcj`,
	"elements": `UsersFilter_elements__T9pjR`,
	"element": `UsersFilter_element__J8jZC`
};
export default ___CSS_LOADER_EXPORT___;
