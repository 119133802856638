import React, { FC, useState } from "react";
import styles from "./SetPassword.module.scss";
import Button from "kit/button/Button";
import Input from "kit/input/Input";
import localized from "helpers/localized";
import { useDispatch } from "react-redux";
import { validatePassword } from "helpers/validators";
import { fetchNewPassword } from "modules/login/store/LoginAC";
import { useNavigate } from "react-router-dom";

const SetPassword: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);

  const validateData = async () => {
    setPasswordValid(validatePassword(password));

    if (validatePassword(password)) {
      dispatch(
        fetchNewPassword({
          password,
          token: new URLSearchParams(window.location.search).get("token"),
        }),
      );
      navigate("/");
    }
  };

  return (
    <>
      <div className={styles.title}>{localized.passwordNew}</div>
      <div className={styles.elem}>
        <Input
          id="password"
          error={!passwordValid}
          errorMessage={localized.PASSWORD_NOT_VALID}
          value={password}
          label={localized.password}
          length={50}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e);
            setPasswordValid(true);
          }}
          onBlur={() => {
            setPasswordValid(validatePassword(password));
          }}
        />
      </div>
      <div className={styles.element}>
        <Button
          id="confirm"
          onClick={() => {
            validateData();
          }}
        >
          {localized.confirm}
        </Button>
      </div>
    </>
  );
};

export default SetPassword;
