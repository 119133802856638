// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Drop_drop__P5nI\\+ {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: grey;
  opacity: 0;
  z-index: 0;
  transition: all 300ms ease;
}

.Drop_active__lDUcS {
  opacity: 20% !important;
  z-index: 100;
}`, "",{"version":3,"sources":["webpack://./src/kit/drop/Drop.module.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,sBAAA;EACA,UAAA;EACA,UAAA;EACA,0BAAA;AADF;;AAIA;EACE,uBAAA;EACA,YAAA;AADF","sourcesContent":["@import \"../variables\";\n\n.drop {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: grey;\n  opacity: 0;\n  z-index: 0;\n  transition: all 300ms ease;\n}\n\n.active {\n  opacity: 20% !important;\n  z-index: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drop": `Drop_drop__P5nI+`,
	"active": `Drop_active__lDUcS`
};
export default ___CSS_LOADER_EXPORT___;
