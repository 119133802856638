import React, { FC } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import ReportsGoodsPage from "./reportsGoods/ReportsGoodsPage";
import ReportsDeliveryPage from "./reportsDelivery/ReportsDeliveryPage";
import ReportsHeader from "./reportsHeader/ReportsHeader";

const ReportsPage: FC = () => {
  const page = useSelector((state: RootState) => state.reports.page);
  return (
    <div>
      <ReportsHeader />
      <SwitchTransition mode="out-in">
        <CSSTransition key={page} timeout={500} classNames="fade">
          {page === "goods" ? (
            <ReportsGoodsPage />
          ) : page === "delivery" ? (
            <ReportsDeliveryPage />
          ) : (
            <div />
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default ReportsPage;
