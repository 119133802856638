// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StubMobile_background__8TsYK {
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 0.9;
  background-color: grey;
  z-index: 10001;
}

.StubMobile_wrapper__09N2k {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10002;
  width: 100vw;
  height: 100vh;
}

.StubMobile_block__slTPW {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 30px 20px;
  width: 350px;
  height: 320px;
  background-color: white;
  justify-content: center;
  align-items: center;
}

.StubMobile_title__nJUsa {
  color: #000000;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px;
  text-transform: none;
  margin-bottom: 20px;
  text-align: center;
}

.StubMobile_text__i\\+-cI {
  color: rgba(0, 0, 0, 0.6);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/kit/stubMobile/StubMobile.module.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,cAAA;AADF;;AAIA;EACE,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;AADF;;AAIA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,mBAAA;AADF;;AAIA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;AADF;;AAIA;EACE,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AADF","sourcesContent":["@import \"../variables\";\n\n.background {\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  opacity: 0.9;\n  background-color: grey;\n  z-index: 10001;\n}\n\n.wrapper {\n  position: fixed;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 10002;\n  width: 100vw;\n  height: 100vh;\n}\n\n.block {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  padding: 20px 30px 20px;\n  width: 350px;\n  height: 320px;\n  background-color: white;\n  justify-content: center;\n  align-items: center;\n}\n\n.title {\n  color: #000000;\n  font-size: 26px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 31px;\n  text-transform: none;\n  margin-bottom: 20px;\n  text-align: center;\n}\n\n.text {\n  color: #0009;\n  font-size: 17px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `StubMobile_background__8TsYK`,
	"wrapper": `StubMobile_wrapper__09N2k`,
	"block": `StubMobile_block__slTPW`,
	"title": `StubMobile_title__nJUsa`,
	"text": `StubMobile_text__i+-cI`
};
export default ___CSS_LOADER_EXPORT___;
