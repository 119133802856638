import React, { FC } from "react";
import styles from "./OrdersHeader.module.scss";
import localized from "helpers/localized";
import { useDispatch, useSelector } from "react-redux";
import { setPageOrders } from "../store/OrdersSlice";
import { RootState } from "store/store";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const OrdersHeader: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const page = useSelector((state: RootState) => state.orders.page);

  return (
    <div className={styles.navigation}>
      <div
        onClick={() => {
          dispatch(setPageOrders("list"));
          navigate("/?p=orders&i=list");
        }}
        className={clsx(
          styles.navigationItem,
          page === "list" && styles.navigationItemActive,
        )}
      >
        {localized.orders}
      </div>
      <div
        onClick={() => {
          dispatch(setPageOrders("statistics"));
          navigate("/?p=orders&i=statistics");
        }}
        className={clsx(
          styles.navigationItem,
          page === "statistics" && styles.navigationItemActive,
        )}
      >
        {localized.statisticsOrders}
      </div>
    </div>
  );
};

export default OrdersHeader;
