import React, { FC } from "react";
import localized from "helpers/localized";
import clsx from "clsx";
import LanguageMenu from "kit/languageMenu/LanguageMenu";
import styles from "./RegistrationPage.module.scss";
import RegistrationPageStep1 from "./components/RegistrationPageStep1/RegistrationPageStep1";
import RegistrationPageStep2 from "./components/RegistrationPageStep2/RegistrationPageStep2";
import RegistrationPageStep3 from "./components/RegistrationPageStep3/RegistrationPageStep3";
import RegistrationPageStep4 from "./components/RegistrationPageStep4/RegistrationPageStep4";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as UserIcon } from "assets/icons/User.svg";
import { RootState } from "store/store";
import { setLanguage } from "modules/main/store/MainSlice";
import { languages } from "helpers/const";

const RegistrationPage: FC = () => {
  const dispatch = useDispatch();

  const registration = useSelector((state: RootState) => state.registration);
  const language = useSelector((state: RootState) => state.main.language);

  const changeLanguage = (code: string) => {
    dispatch(setLanguage(code));
    localized.setLanguage(code);
  };

  return (
    <div>
      <div
        className={clsx(
          styles.modal,
          registration.step === "step4" && styles.modalStep4,
        )}
      >
        <div
          className={clsx(
            styles.auth,
            registration.step === "step4" && styles.authStep4,
          )}
        >
          <LanguageMenu
            languages={languages}
            language={language}
            changeLanguage={changeLanguage}
          />
          <div className={styles.wrap}>
            <UserIcon className={styles.icon} />
            {registration.step === "step1" && <RegistrationPageStep1 />}
            {registration.step === "step2" && <RegistrationPageStep2 />}
            {registration.step === "step3" && <RegistrationPageStep3 />}
            {registration.step === "step4" && <RegistrationPageStep4 />}
          </div>
        </div>
      </div>
      <div
        className={styles.background}
        style={{ backgroundImage: `url(/assets/img/LoginPage.jpg)` }}
      />
    </div>
  );
};

export default RegistrationPage;
