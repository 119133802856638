import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  dealer: {},
  dealers: [],
  dealersAll: [],
  page: 1,
  pageDealers: "list",
  tiberium: [],
  filtration: {
    loading: false,
    page: 0,
    id: "",
    firstName: "",
    lastName: "",
    middleName: "",
    position: "",
    phone: "",
    email: "",
    role: "",
    status: "",
    loginType: "",
  },
};

export const DealersSlice = createSlice({
  name: "dealers",
  initialState,
  reducers: {
    setDealer: (state, action) => {
      state.dealer = action.payload;
    },
    setDealers: (state, action) => {
      state.dealers = action.payload;
    },
    setDealersAll: (state, action) => {
      state.dealersAll = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setTiberium: (state, action) => {
      state.tiberium = action.payload;
    },
    setPageDealers: (state, action) => {
      state.pageDealers = action.payload;
    },
    setFiltration: (state, action) => {
      state.filtration = action.payload;
    },
  },
});

export const {
  setDealer,
  setDealers,
  setDealersAll,
  setPage,
  setTiberium,
  setPageDealers,
  setFiltration,
} = DealersSlice.actions;

export default DealersSlice.reducer;
