// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modal__qvAvK {
  z-index: 10000;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 32px 20px;
  background-color: white;
  border: solid 1px black;
}

.Modal_subtitle__1SK7C {
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.Modal_title__9KyQc {
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
}

.Modal_content__s82v- {
  width: 100%;
  text-align: center;
  margin-top: 0;
  margin-bottom: 28px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.Modal_buttons__d7V12 {
  width: 100%;
  text-align: center;
}

.Modal_yes__A0\\+rT {
  margin-bottom: 10px;
}

.Modal_wrapper__9SV96 {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10002;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width: 370px) {
  .Modal_modal__qvAvK {
    width: 100vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/kit/modal/Modal.module.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,uBAAA;EACA,uBAAA;AADF;;AAIA;EACE,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;AADF;;AAIA;EACE,WAAA;EACA,kBAAA;EACA,mBAAA;AADF;;AAIA;EACE,WAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,yBAAA;AADF;;AAIA;EACE,WAAA;EACA,kBAAA;AADF;;AAIA;EACE,mBAAA;AADF;;AAIA;EACE,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,aAAA;EACA,oCAAA;AADF;;AAIA;EACE;IACE,YAAA;EADF;AACF","sourcesContent":["@import \"../variables\";\n\n.modal {\n  z-index: 10000;\n  width: 350px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  text-align: center;\n  padding: 32px 20px;\n  background-color: white;\n  border: solid 1px black;\n}\n\n.subtitle {\n  width: 100%;\n  text-align: center;\n  margin-bottom: 16px;\n  color: rgba(0, 0, 0, 0.6);\n}\n\n.title {\n  width: 100%;\n  text-align: center;\n  margin-bottom: 16px;\n}\n\n.content {\n  width: 100%;\n  text-align: center;\n  margin-top: 0;\n  margin-bottom: 28px;\n  line-height: 20px;\n  color: rgba(0, 0, 0, 0.6);\n}\n\n.buttons {\n  width: 100%;\n  text-align: center;\n}\n\n.yes {\n  margin-bottom: 10px;\n}\n\n.wrapper {\n  position: fixed;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 10002;\n  width: 100vw;\n  height: 100vh;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  padding: 20px;\n  background-color: rgba(0, 0, 0, 0.2);\n}\n\n@media (max-width: 370px) {\n  .modal {\n    width: 100vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `Modal_modal__qvAvK`,
	"subtitle": `Modal_subtitle__1SK7C`,
	"title": `Modal_title__9KyQc`,
	"content": `Modal_content__s82v-`,
	"buttons": `Modal_buttons__d7V12`,
	"yes": `Modal_yes__A0+rT`,
	"wrapper": `Modal_wrapper__9SV96`
};
export default ___CSS_LOADER_EXPORT___;
