import React, { FC, useState } from "react";
import styles from "./RegistrationPage1.module.scss";
import Button from "kit/button/Button";
import Input from "kit/input/Input";
import Switch from "kit/switch/Switch";
import localized from "helpers/localized";
import { useDispatch, useSelector } from "react-redux";
import { fetchRegistrationStep1 } from "modules/registration/store/RegistrationAC";
import { setPage, setUserWithPhone } from "modules/main/store/MainSlice";
import { RootState } from "store/store";
import {
  validatePhone,
  validateEmail,
  validatePassword,
} from "helpers/validators";

const RegistrationPageStep1: FC = () => {
  const dispatch = useDispatch();

  const main = useSelector((state: RootState) => state.main);

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [phoneValid, setPhoneValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);

  const validateData = async () => {
    if (main.userWithPhone) {
      setPhoneValid(validatePhone(phone, true));
    } else {
      setEmailValid(validateEmail(email, true));
    }

    setPasswordValid(validatePassword(password));

    if (
      main.userWithPhone
        ? validatePhone(phone, true) && validatePassword(password)
        : validateEmail(email, true) && validatePassword(password)
    ) {
      dispatch(
        fetchRegistrationStep1({
          login: main.userWithPhone ? phone : email,
          loginType: main.userWithPhone ? "phone" : "email",
          password,
        }),
      );
    }
  };

  return (
    <>
      <div className={styles.title}>{localized.registrationTitle}</div>
      <div className={styles.elements}>
        <div className={styles.el}>
          {main.userWithPhone ? (
            <Input
              type="phone"
              id="phone"
              label={localized.phone}
              value={phone}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPhone(e);
                setPhoneValid(true);
              }}
              onBlur={() => {
                setPhoneValid(validatePhone(phone, true));
              }}
              error={!phoneValid}
              errorMessage={localized.phoneError}
            />
          ) : (
            <Input
              type="email"
              id="email"
              error={!emailValid}
              errorMessage={localized.emailError}
              value={email}
              label={localized.email}
              length={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(e);
                setEmailValid(true);
              }}
              onBlur={() => {
                setEmailValid(validateEmail(email, true));
              }}
            />
          )}
        </div>
        <div className={styles.switch}>
          <Switch
            id="emailSwitch"
            title={localized.email.toLowerCase()}
            value={!main.userWithPhone}
            onChange={() => {
              dispatch(setUserWithPhone(!main.userWithPhone));
            }}
          />
        </div>
      </div>
      <div className={styles.elem}>
        <Input
          id="password"
          error={!passwordValid}
          errorMessage={localized.PASSWORD_NOT_VALID}
          value={password}
          label={localized.password}
          length={50}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e);
            setPasswordValid(true);
          }}
          onBlur={() => {
            setPasswordValid(validatePassword(password));
          }}
        />
      </div>

      <div className={styles.element}>
        <Button
          id="registration"
          onClick={() => {
            validateData();
          }}
        >
          {localized.registration}
        </Button>
      </div>
      <div className={styles.elem}>
        <Button
          id="loginPage"
          onClick={() => {
            dispatch(setPage("login"));
          }}
        >
          {localized.loginPage}
        </Button>
      </div>
    </>
  );
};

export default RegistrationPageStep1;
