import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  user: {},
  users: [],
  usersAll: [],
  page: 1,
  pageUsers: "list",
  filtration: {
    loading: false,
    page: 0,
    id: "",
    firstName: "",
    lastName: "",
    middleName: "",
    position: "",
    phone: "",
    email: "",
    role: "",
    status: "",
    loginType: "",
  },
};

export const UsersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.user = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setUsersAll: (state, action) => {
      state.usersAll = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageUsers: (state, action) => {
      state.pageUsers = action.payload;
    },
    setFiltration: (state, action) => {
      state.filtration = action.payload;
    },
  },
});

export const {
  setUserInfo,
  setUsers,
  setUsersAll,
  setPage,
  setPageUsers,
  setFiltration,
} = UsersSlice.actions;

export default UsersSlice.reducer;
