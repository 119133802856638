// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Page404_wrapper__3qJQm {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Page404_text__SHQHc {
  color: #000000;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  text-align: center;
  width: 600px;
}`, "",{"version":3,"sources":["webpack://./src/modules/page404/Page404.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;AACF","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.text {\n  color: #000000;\n  font-size: 28px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 35px;\n  text-align: center;\n  width: 600px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Page404_wrapper__3qJQm`,
	"text": `Page404_text__SHQHc`
};
export default ___CSS_LOADER_EXPORT___;
