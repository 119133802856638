import React, { FC, useState } from "react";
import styles from "./ReportsGoodsPage.module.scss";
import localized from "helpers/localized";
import Table from "kit/table/Table";
import ReportGoodsPopup from "./ReportGoodsPopup/ReportGoodsPopup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import Pagination from "kit/pagination/Pagination";
import Button from "kit/button/Button";
import ReportGoodsFilter from "./ReportGoodsFilter/ReportGoodsFilter";

const ReportsGoodsPage: FC = () => {
  const dispatch = useDispatch();

  const reports = useSelector((state: RootState) => state.reports);

  const [openPopup, setOpenPopup] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [activeRow, setActiveRow] = useState({});

  const openRow = (row: any) => {
    setActiveRow(row);
    setOpenPopup(true);
  };

  const changePagePagination = (page) => {};

  return (
    <>
      <ReportGoodsPopup
        show={openPopup}
        setOpenPopup={setOpenPopup}
        activeRow={activeRow}
      />
      <div className={styles.wrapper}>
        <div className={styles.filterWrapper}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>{localized.turnoverReportsTitle}</div>
            <div className={styles.number}>12</div>
          </div>

          <div className={styles.filter}>
            {openFilter ? (
              <>
                <div className={styles.extraButton}>
                  <Button
                    id="close"
                    small
                    onClick={() => {
                      setOpenFilter(false);
                    }}
                  >
                    {localized.close}
                  </Button>
                </div>
                <Button
                  id="apply"
                  small
                  onClick={() => {
                    dispatch(fetchReportsGoods({ page: 1 }));
                  }}
                >
                  {localized.apply}
                </Button>
              </>
            ) : (
              <Button
                id="tableFilter"
                small
                onClick={() => {
                  setOpenFilter(true);
                }}
              >
                {localized.tableFilter}
              </Button>
            )}
          </div>
        </div>
        <div className={styles.text}>{localized.doubleClickInfo}</div>

        {openFilter && <ReportGoodsFilter />}

        <div>
          <Table
            onOpenRow={openRow}
            columns={[
              {
                title: localized.orderNumber,
                key: "string1",
                type: "string",
              },
              {
                title: localized.caseNumber,
                key: "string2",
                type: "string",
              },
              {
                title: localized.status,
                key: "string3",
                type: "string",
              },
              {
                title: localized.created,
                key: "datetime1",
                type: "datetime",
              },
              {
                title: localized.changed,
                key: "datetime2",
                type: "datetime",
              },
              {
                title: localized.showcase,
                key: "string4",
                type: "string",
              },
              {
                title: localized.product,
                key: "string5",
                type: "string",
              },
              {
                title: localized.manufacturer,
                key: "string6",
                type: "string",
              },
              {
                title: localized.number,
                key: "string7",
                type: "string",
              },
              {
                title: localized.categoryName,
                key: "string8",
                type: "string",
              },
              {
                title: localized.fullCategoryName,
                key: "string9",
                type: "string",
              },
              {
                title: localized.provider,
                key: "string10",
                type: "string",
              },
              {
                title: localized.denomination,
                key: "string11",
                type: "string",
              },
              {
                title: localized.price,
                key: "string12",
                type: "string",
              },
              {
                title: localized.quantity,
                key: "string13",
                type: "string",
              },
              {
                title: localized.fullPrice,
                key: "string14",
                type: "string",
              },
              {
                title: localized.userPrice,
                key: "string15",
                type: "string",
              },
            ]}
            rows={[
              {
                string1: "3051123",
                string2: "23123123",
                string3: "Подтвержден",
                datetime1: new Date(),
                datetime2: new Date(),
                string4: "ВТБ",
                string5: "ФЕН ATLANTA",
                string6: "ATLANTA",
                string7: "123123",
                string8: "Фены и щетки",
                string9: "Бытовая техника",
                string10: "Арсенал-БТ",
                string11: "1000",
                string12: "1010",
                string13: "1",
                string14: "1010",
                string15: "500",
              },
              {
                string1: "3051123",
                string2: "23123123",
                string3: "Подтвержден",
                datetime1: new Date(),
                datetime2: new Date(),
                string4: "ВТБ",
                string5: "ФЕН ATLANTA",
                string6: "ATLANTA",
                string7: "123123",
                string8: "Фены и щетки",
                string9: "Бытовая техника",
                string10: "Арсенал-БТ",
                string11: "1000",
                string12: "1010",
                string13: "1",
                string14: "1010",
                string15: "500",
              },
              {
                string1: "3051123",
                string2: "23123123",
                string3: "Подтвержден",
                datetime1: new Date(),
                datetime2: new Date(),
                string4: "ВТБ",
                string5: "ФЕН ATLANTA",
                string6: "ATLANTA",
                string7: "123123",
                string8: "Фены и щетки",
                string9: "Бытовая техника",
                string10: "Арсенал-БТ",
                string11: "1000",
                string12: "1010",
                string13: "1",
                string14: "1010",
                string15: "500",
              },
              {
                string1: "3051123",
                string2: "23123123",
                string3: "Подтвержден",
                datetime1: new Date(),
                datetime2: new Date(),
                string4: "ВТБ",
                string5: "ФЕН ATLANTA",
                string6: "ATLANTA",
                string7: "123123",
                string8: "Фены и щетки",
                string9: "Бытовая техника",
                string10: "Арсенал-БТ",
                string11: "1000",
                string12: "1010",
                string13: "1",
                string14: "1010",
                string15: "500",
              },
              {
                string1: "3051123",
                string2: "23123123",
                string3: "Подтвержден",
                datetime1: new Date(),
                datetime2: new Date(),
                string4: "ВТБ",
                string5: "ФЕН ATLANTA",
                string6: "ATLANTA",
                string7: "123123",
                string8: "Фены и щетки",
                string9: "Бытовая техника",
                string10: "Арсенал-БТ",
                string11: "1000",
                string12: "1010",
                string13: "1",
                string14: "1010",
                string15: "500",
              },
              {
                string1: "3051123",
                string2: "23123123",
                string3: "Подтвержден",
                datetime1: new Date(),
                datetime2: new Date(),
                string4: "ВТБ",
                string5: "ФЕН ATLANTA",
                string6: "ATLANTA",
                string7: "123123",
                string8: "Фены и щетки",
                string9: "Бытовая техника",
                string10: "Арсенал-БТ",
                string11: "1000",
                string12: "1010",
                string13: "1",
                string14: "1010",
                string15: "500",
              },
            ]}
            noData={localized.noData}
          />
        </div>
        <Pagination
          currentPage={reports.pageGoods}
          lastPage={10}
          onAccept={changePagePagination}
        />
      </div>
    </>
  );
};

export default ReportsGoodsPage;
