import { translateCodes } from "./translateCodes";

export const errorCodes = (errors) => {
  if (errors.fields) {
    let errorsArray = Object.values(errors.fields).flat();
    errorsArray.unshift(errors.code);
    let error = [];
    for (let i = 0; i < errorsArray.length; i++) {
      error.push(translateCodes(errorsArray[i]));
    }
    return error.join(", ");
  } else {
    return translateCodes(errors.code);
  }
};
