import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  step: "step1",
  tokenCode: "",
};

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setTokenCode: (state, action) => {
      state.tokenCode = action.payload;
    },
  },
});

export const { setStep, setTokenCode } = registrationSlice.actions;

export default registrationSlice.reducer;
