// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfilePage_wrapper__Pd2Ji {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0px 40px 20px;
}

.ProfilePage_title__pdTh5 {
  font-size: 26px;
  font-weight: 500;
  line-height: 31px;
  margin-bottom: -10px;
}

.ProfilePage_block__xJDIw {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.ProfilePage_firstBlock__79PJq {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.ProfilePage_elements__v2Eig {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ProfilePage_element__hdEFk {
  margin-right: 20px;
  width: 300px;
  margin-top: 20px;
}

.ProfilePage_elementCode__PDmw6 {
  margin-right: 20px;
  width: 300px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ProfilePage_exit__L\\+-RY {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
}

.ProfilePage_headline__1MVR9 {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  text-align: center;
  margin-top: 25px;
}

.ProfilePage_textCode__gwYcJ {
  margin-top: 7px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/modules/profile/ProfilePage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,2BAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 20px 0px 40px 20px;\n}\n\n.title {\n  font-size: 26px;\n  font-weight: 500;\n  line-height: 31px;\n  margin-bottom: -10px;\n}\n\n.block {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-wrap: wrap;\n}\n\n.firstBlock {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-wrap: wrap;\n}\n\n.elements {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.element {\n  margin-right: 20px;\n  width: 300px;\n  margin-top: 20px;\n}\n\n.elementCode {\n  margin-right: 20px;\n  width: 300px;\n  margin-top: 20px;\n  display: flex;\n  flex-direction: column;\n}\n\n.exit {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  margin-top: 15px;\n}\n\n.headline {\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 20px;\n  width: 100%;\n  text-align: center;\n  margin-top: 25px;\n}\n\n.textCode {\n  margin-top: 7px;\n  color: #0009;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ProfilePage_wrapper__Pd2Ji`,
	"title": `ProfilePage_title__pdTh5`,
	"block": `ProfilePage_block__xJDIw`,
	"firstBlock": `ProfilePage_firstBlock__79PJq`,
	"elements": `ProfilePage_elements__v2Eig`,
	"element": `ProfilePage_element__hdEFk`,
	"elementCode": `ProfilePage_elementCode__PDmw6`,
	"exit": `ProfilePage_exit__L+-RY`,
	"headline": `ProfilePage_headline__1MVR9`,
	"textCode": `ProfilePage_textCode__gwYcJ`
};
export default ___CSS_LOADER_EXPORT___;
