// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CategoriesPage_wrapper__jvz59 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

.CategoriesPage_title__e7kQq {
  font-size: 26px;
  font-weight: 500;
  line-height: 31px;
}`, "",{"version":3,"sources":["webpack://./src/modules/categories/CategoriesPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  margin-top: 20px;\n}\n\n.title {\n  font-size: 26px;\n  font-weight: 500;\n  line-height: 31px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CategoriesPage_wrapper__jvz59`,
	"title": `CategoriesPage_title__e7kQq`
};
export default ___CSS_LOADER_EXPORT___;
