import axios from "axios";

export const companyApi = {
  newEmployee({ login, loginType, dealerIds, token }) {
    const formData = new FormData();
    formData.append("login", login);
    formData.append("loginType", loginType);
    if (dealerIds.length) {
      for (let i = 0; i < dealerIds.length; i++) {
        formData.append("dealerIds[]", dealerIds[i]);
      }
    }
    return axios({
      url: process.env.REACT_APP_REST_API + "users/invite",
      method: "POST",
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },
};
