import React, { FC } from "react";
import styles from "./AnswersPage.module.scss";

const AnswersPage: FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Ответы</div>
    </div>
  );
};

export default AnswersPage;
