import React from "react";
import styles from "./Navigation.module.scss";
import logo from "assets/logo.png";
import { setAdminPage } from "modules/main/store/MainSlice";
import { useDispatch, useSelector } from "react-redux";
import localized from "helpers/localized";
import { RootState } from "store/store";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

let NavigationFooter = () => {
  const dispatch = useDispatch();
  const adminPage = useSelector((state: RootState) => state.main.adminPage);
  const navigate = useNavigate();
  return (
    <div className={styles.support}>
      <div className={styles.footerItems}>
        <div
          onClick={() => {
            dispatch(setAdminPage("rules"));
            navigate("/?p=rules");
          }}
          className={clsx(
            styles.footerItem,
            adminPage === "rules" && styles.itemActive,
          )}
        >
          {localized.rules}
        </div>
        <div
          onClick={() => {
            dispatch(setAdminPage("offer"));
            navigate("/?p=offer");
          }}
          className={clsx(
            styles.footerItem,
            adminPage === "offer" && styles.itemActive,
          )}
        >
          {localized.offer}
        </div>
        <div
          onClick={() => {
            dispatch(setAdminPage("answers"));
            navigate("/?p=answers");
          }}
          className={clsx(
            styles.footerItem,
            adminPage === "answers" && styles.itemActive,
          )}
        >
          {localized.answers}
        </div>
      </div>
      <div className={styles.supportTitle}>
        {localized.fr +
          " 09:00 " +
          localized.to.toLowerCase() +
          " 21:00 " +
          localized.timezone}
      </div>
      <div className={styles.description}>
        <a className={styles.phone} href="tel:+7 (495) 742 7747">
          +7 (495) 742 7747
        </a>
        <a
          className={styles.supportLogo}
          target="_blank"
          rel="noreferrer"
          href="https://mygiftcard.ru/"
        >
          <img className={styles.logoImg} src={logo} alt="logo" />
        </a>
      </div>
    </div>
  );
};

export default NavigationFooter;
