import React, { FC, useState } from "react";
import styles from "./DeliveryPage.module.scss";
import localized from "helpers/localized";
import Input from "kit/input/Input";
import Select from "kit/select/Select";
import Button from "kit/button/Button";

const DeliveryPage: FC = () => {
  const [loginCdek, setLoginCdek] = useState("");
  const [loginCdekValid, setLoginCdekValid] = useState(true);
  const [passwordCdek, setPasswordCdek] = useState("");
  const [passwordCdekValid, setPasswordCdekValid] = useState(true);
  const [pointsCdek, setPointsCdek] = useState([]);

  const [loginBoxberry, setLoginBoxberry] = useState("");
  const [loginBoxberryValid, setLoginBoxberryValid] = useState(true);
  const [passwordBoxberry, setPasswordBoxberry] = useState("");
  const [passwordBoxberryValid, setPasswordBoxberryValid] = useState(true);
  const [pointsBoxberry, setPointsBoxberry] = useState([]);

  const [loginYandex, setLoginYandex] = useState("");
  const [loginYandexValid, setLoginYandexValid] = useState(true);
  const [passwordYandex, setPasswordYandex] = useState("");
  const [passwordYandexValid, setPasswordYandexValid] = useState(true);
  const [pointsYandex, setPointsYandex] = useState([]);

  const [loginOwn, setLoginOwn] = useState("");
  const [loginOwnValid, setLoginOwnValid] = useState(true);
  const [passwordOwn, setPasswordOwn] = useState("");
  const [passwordOwnValid, setPasswordOwnValid] = useState(true);
  const [pointsOwn, setPointsOwn] = useState([]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{localized.delivery}</div>

      <div className={styles.elements}>
        <div className={styles.data}>
          <div className={styles.block}>
            <div className={styles.blockTitle}>СДЭК</div>

            <div className={styles.element}>
              <Input
                id="loginCdek"
                value={loginCdek}
                label={localized.login}
                length={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLoginCdek(e);
                }}
              />
            </div>

            <div className={styles.element}>
              <Input
                id="passwordCdek"
                value={passwordCdek}
                label={localized.password}
                length={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPasswordCdek(e);
                }}
              />
            </div>

            <div className={styles.element}>
              <Select
                id="points"
                multiple
                label={localized.pickupPoints}
                value={pointsCdek}
                onChange={(value) => {
                  setPointsCdek(value);
                }}
                source="name"
                list={[]}
              />
            </div>

            <div className={styles.element}>
              <Button id="cdekButton" onClick={() => {}}>
                {localized.confirm}
              </Button>
            </div>
          </div>

          <div className={styles.block}>
            <div className={styles.blockTitle}>Boxberry</div>

            <div className={styles.element}>
              <Input
                id="loginBoxberry"
                value={loginBoxberry}
                label={localized.login}
                length={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLoginBoxberry(e);
                }}
              />
            </div>

            <div className={styles.element}>
              <Input
                id="passwordBoxberry"
                value={passwordBoxberry}
                label={localized.password}
                length={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPasswordBoxberry(e);
                }}
              />
            </div>

            <div className={styles.element}>
              <Select
                id="points"
                multiple
                label={localized.pickupPoints}
                value={pointsBoxberry}
                onChange={(value) => {
                  setPointsBoxberry(value);
                }}
                source="name"
                list={[]}
              />
            </div>

            <div className={styles.element}>
              <Button id="boxberryButton" onClick={() => {}}>
                {localized.confirm}
              </Button>
            </div>
          </div>

          <div className={styles.block}>
            <div className={styles.blockTitle}>Yandex</div>

            <div className={styles.element}>
              <Input
                id="loginYandex"
                value={loginYandex}
                label={localized.login}
                length={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLoginYandex(e);
                }}
              />
            </div>

            <div className={styles.element}>
              <Input
                id="passwordYandex"
                value={passwordYandex}
                label={localized.password}
                length={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPasswordYandex(e);
                }}
              />
            </div>

            <div className={styles.element}>
              <Select
                id="points"
                multiple
                label={localized.pickupPoints}
                value={pointsYandex}
                onChange={(value) => {
                  setPointsYandex(value);
                }}
                source="name"
                list={[]}
              />
            </div>

            <div className={styles.element}>
              <Button id="yandexButton" onClick={() => {}}>
                {localized.confirm}
              </Button>
            </div>
          </div>

          <div className={styles.block}>
            <div className={styles.blockTitle}>{localized.ownDelivery}</div>

            <div className={styles.element}>
              <Input
                id="loginOwn"
                value={loginOwn}
                label={localized.login}
                length={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLoginOwn(e);
                }}
              />
            </div>

            <div className={styles.element}>
              <Input
                id="passwordOwn"
                value={passwordOwn}
                label={localized.password}
                length={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPasswordOwn(e);
                }}
              />
            </div>

            <div className={styles.element}>
              <Select
                id="points"
                multiple
                label={localized.pickupPoints}
                value={pointsOwn}
                onChange={(value) => {
                  setPointsOwn(value);
                }}
                source="name"
                list={[]}
              />
            </div>

            <div className={styles.element}>
              <Button id="ownButton" onClick={() => {}}>
                {localized.confirm}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPage;
