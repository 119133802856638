import localized from "helpers/localized";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setError, setInfo, setLoading } from "modules/main/store/MainSlice";
import { setCodeEmail, setCodePhone } from "./ProfileSlice";
import { profileApi } from "./ProfileApi";
import { errorCodes } from "helpers/functions";
import { setProfileData, setNewPhone, setNewEmail } from "./ProfileSlice";
import { usersApi } from "modules/users/store/UsersApi";
import { fetchUsers, fetchUsersAll } from "modules/users/store/UsersAC";

export const fetchProfileData = createAsyncThunk(
  "fetchProfileData",
  async (_, thunkAPI) => {
    try {
      const response = await profileApi.getProfile(
        thunkAPI?.getState()?.login?.token,
      );
      if (response?.data?.data) {
        thunkAPI.dispatch(setProfileData(response?.data?.data));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchChangeUser = createAsyncThunk(
  "fetchChangeUser",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await usersApi.changeUser({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      thunkAPI.dispatch(setLoading(false));
      if (response?.data?.data?.id) {
        thunkAPI.dispatch(fetchProfileData());
        thunkAPI.dispatch(fetchUsers(thunkAPI?.getState()?.users?.filtration));
        thunkAPI.dispatch(fetchUsersAll());
        thunkAPI.dispatch(setInfo(localized.dataChanged));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchChangePhone = createAsyncThunk(
  "fetchChangePhone",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await profileApi.changePhone({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      thunkAPI.dispatch(setLoading(false));
      if (response?.data?.data?.expiredTime) {
        thunkAPI.dispatch(setCodePhone(true));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchPhoneCode = createAsyncThunk(
  "fetchPhoneCode",
  async ({ phone, code }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await profileApi.sendPhoneCode({
        code,
        token: thunkAPI?.getState()?.login?.token,
      });
      thunkAPI.dispatch(setLoading(false));

      if (response.data.data === null) {
        thunkAPI.dispatch(setCodePhone(false));
        thunkAPI.dispatch(setInfo(localized.dataChanged));
        thunkAPI.dispatch(setNewPhone(phone));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchChangeEmail = createAsyncThunk(
  "fetchChangeEmail",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await profileApi.changeEmail({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      thunkAPI.dispatch(setLoading(false));
      if (response?.data?.data?.expiredTime) {
        thunkAPI.dispatch(setCodeEmail(true));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchEmailCode = createAsyncThunk(
  "fetchEmailCode",
  async ({ email, code }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await profileApi.sendEmailCode({
        code,
        token: thunkAPI?.getState()?.login?.token,
      });
      thunkAPI.dispatch(setLoading(false));

      if (response.data.data === null) {
        thunkAPI.dispatch(setCodeEmail(false));
        thunkAPI.dispatch(setInfo(localized.dataChanged));
        thunkAPI.dispatch(setNewEmail(email));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchChangePassword = createAsyncThunk(
  "fetchChangePassword",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await profileApi.changePassword({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      thunkAPI.dispatch(setLoading(false));

      if (response.data.data === null) {
        thunkAPI.dispatch(setInfo(localized.dataChanged));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);
