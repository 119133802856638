import React, { FC } from "react";
import styles from "./ProductPopup.module.scss";
import localized from "helpers/localized";
import Popup from "kit/popup/Popup";

const ProductPopup: FC = ({ show, setOpenPopup, activeRow }) => {
  return (
    <Popup
      show={show}
      closeText={localized.close}
      infoText={localized.info}
      close={setOpenPopup}
    >
      <div className={styles.content}>
        <h1>{localized.product}</h1>
        <div className={styles.rows}>
          <div className={styles.row}>
            <p className={styles.left}>{localized.number}</p>
            <p className={styles.right}>{activeRow.string1}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.productName}</p>
            <p className={styles.right}>{activeRow.string2}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.category}</p>
            <p className={styles.right}>{activeRow.string3}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.brand}</p>
            <p className={styles.right}>{activeRow.string4}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.collection}</p>
            <p className={styles.right}>{activeRow.string5}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.article}</p>
            <p className={styles.right}>{activeRow.string6}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.barcode}</p>
            <p className={styles.right}>{activeRow.string7}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.gender}</p>
            <p className={styles.right}>{activeRow.string8}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.reserve}</p>
            <p className={styles.right}>{activeRow.string9}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.remainder}</p>
            <p className={styles.right}>{activeRow.string10}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.orders}</p>
            <p className={styles.right}>{activeRow.string11}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.price}</p>
            <p className={styles.right}>{activeRow.string12}</p>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default ProductPopup;
