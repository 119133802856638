import axios from "axios";

export const loginApi = {
  login({ login, password }) {
    const formData = new FormData();
    formData.append("login", login);
    formData.append("password", password);
    return axios({
      url: process.env.REACT_APP_REST_API + "login/request-code",
      method: "POST",
      data: formData,
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  sendCode({ code, token }) {
    const formData = new FormData();
    formData.append("code", code);
    formData.append("type", "login");
    return axios({
      url: process.env.REACT_APP_REST_API + "login/confirm",
      method: "POST",
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  newPassword({ password, token }) {
    const formData = new FormData();
    formData.append("password", password);
    formData.append("token", token);
    return axios({
      url: process.env.REACT_APP_REST_API + "users/password/set",
      method: "POST",
      data: formData,
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },
};
