import React, { useEffect, useState } from "react";
import styles from "./Navigation.module.scss";
import { useDispatch, useSelector } from "react-redux";
import UserIcon from "assets/jsx/UserIcon";
import { setAdminPage, setLanguage } from "modules/main/store/MainSlice";
import LanguageMenu from "kit/languageMenu/LanguageMenu";
import { RootState } from "store/store";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import localized from "helpers/localized";
import { languages } from "helpers/const";

let NavigationHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminPage = useSelector((state: RootState) => state.main.adminPage);
  const profile = useSelector((state: RootState) => state.profile.profile);
  const language = useSelector((state: RootState) => state.main.language);

  const changeLanguage = (code: string) => {
    dispatch(setLanguage(code));
    localized.setLanguage(code);
  };

  return (
    <div className={styles.header}>
      <div
        className={styles.title}
        onClick={() => {
          dispatch(setAdminPage("profile"));
          navigate("/?p=profile");
        }}
      >
        <div
          className={clsx(
            styles.user,
            adminPage === "profile" && styles.active,
          )}
        >
          <UserIcon />
        </div>
        <div className={styles.headerUser}>
          <div>{profile.firstName || "User"}</div>
        </div>
      </div>
      <LanguageMenu
        languages={languages}
        language={language}
        changeLanguage={changeLanguage}
      />
    </div>
  );
};

export default NavigationHeader;
