// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SupportPage_wrapper__YDQ49 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.SupportPage_title__\\+VoTb {
  font-size: 26px;
  font-weight: 500;
  line-height: 31px;
}

.SupportPage_blockTitle__UQS06 {
  font-size: 25px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  margin-bottom: 10px;
}

.SupportPage_blockText__eTsjw {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-bottom: 5px;
}

.SupportPage_elements__QQ0Tc {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 5px;
}

.SupportPage_data__\\+\\+jRE {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.SupportPage_block__YhiWU {
  margin-right: 30px;
  margin-top: 30px;
  width: 300px;
  height: 150px;
  background: white;
  border: 1px solid black;
  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  opacity: 1;
  transition: all 300ms ease;
}

.SupportPage_block__YhiWU:hover {
  opacity: 0.65;
}`, "",{"version":3,"sources":["webpack://./src/modules/support/SupportPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,2BAAA;EACA,eAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,0CAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;EACA,UAAA;EACA,0BAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 20px;\n}\n\n.title {\n  font-size: 26px;\n  font-weight: 500;\n  line-height: 31px;\n}\n\n.blockTitle {\n  font-size: 25px;\n  font-weight: 500;\n  line-height: 26px;\n  text-align: left;\n  margin-bottom: 10px;\n}\n\n.blockText {\n  font-size: 18px;\n  font-weight: 500;\n  line-height: 20px;\n  text-align: left;\n  margin-bottom: 5px;\n}\n\n.elements {\n  display: flex;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  margin-top: 5px;\n}\n\n.data {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.block {\n  margin-right: 30px;\n  margin-top: 30px;\n  width: 300px;\n  height: 150px;\n  background: white;\n  border: 1px solid black;\n  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.07);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  cursor: pointer;\n  opacity: 1;\n  transition: all 300ms ease;\n}\n\n.block:hover {\n  opacity: 0.65;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `SupportPage_wrapper__YDQ49`,
	"title": `SupportPage_title__+VoTb`,
	"blockTitle": `SupportPage_blockTitle__UQS06`,
	"blockText": `SupportPage_blockText__eTsjw`,
	"elements": `SupportPage_elements__QQ0Tc`,
	"data": `SupportPage_data__++jRE`,
	"block": `SupportPage_block__YhiWU`
};
export default ___CSS_LOADER_EXPORT___;
