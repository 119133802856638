import React from "react";
import NavigationHeader from "./NavigationHeader";
import NavigationList from "./NavigationList";
import NavigationFooter from "./NavigationFooter";
import styles from "./Navigation.module.scss";

let Navigation = () => {
  return (
    <div className={styles.navigation}>
      <div className={styles.container}>
        <NavigationHeader />
        <NavigationList />
        <NavigationFooter />
      </div>
    </div>
  );
};

export default Navigation;
