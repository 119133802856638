// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DealersList_wrapperTable__nA6Ks {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100vw - 190px);
  padding: 10px;
  flex-direction: column;
}

.DealersList_titleTable__8fGZ- {
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  text-align: center;
  margin-bottom: 10px;
}

.DealersList_filterWrapper__fjr89 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DealersList_filter__epws2 {
  display: flex;
}

.DealersList_titleWrapper__P7AfP {
  display: flex;
}

.DealersList_number__aC9M7 {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.6;
}

.DealersList_text__sNO6d {
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 0;
  margin-top: 5px;
  margin-bottom: 20px;
}

.DealersList_table__GtKSo {
  width: calc(100vw - 210px);
}

.DealersList_extraButton__CSKId {
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/modules/dealers/components/DealersList/DealersList.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,2BAAA;EACA,0BAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".wrapperTable {\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  width: calc(100vw - 190px);\n  padding: 10px;\n  flex-direction: column;\n}\n\n.titleTable {\n  font-size: 24px;\n  font-weight: 400;\n  line-height: 31px;\n  text-align: center;\n  margin-bottom: 10px;\n}\n\n.filterWrapper {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.filter {\n  display: flex;\n}\n\n.titleWrapper {\n  display: flex;\n}\n\n.number {\n  margin-left: 10px;\n  font-size: 13px;\n  font-weight: 400;\n  line-height: 20px;\n  opacity: 0.6;\n}\n\n.text {\n  color: #000000;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 0;\n  margin-top: 5px;\n  margin-bottom: 20px;\n}\n\n.table {\n  width: calc(100vw - 210px);\n}\n\n.extraButton {\n  margin-right: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperTable": `DealersList_wrapperTable__nA6Ks`,
	"titleTable": `DealersList_titleTable__8fGZ-`,
	"filterWrapper": `DealersList_filterWrapper__fjr89`,
	"filter": `DealersList_filter__epws2`,
	"titleWrapper": `DealersList_titleWrapper__P7AfP`,
	"number": `DealersList_number__aC9M7`,
	"text": `DealersList_text__sNO6d`,
	"table": `DealersList_table__GtKSo`,
	"extraButton": `DealersList_extraButton__CSKId`
};
export default ___CSS_LOADER_EXPORT___;
