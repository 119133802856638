// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RegistrationPage3_title__0vMnx {
  font-size: 26px;
  font-weight: 500;
  line-height: 31px;
}

.RegistrationPage3_element__VZz1K {
  margin-top: 25px;
  width: 430px;
}

.RegistrationPage3_textFirst__nQMzD {
  margin-bottom: 20px;
  text-align: center;
  font-weight: 500;
}

.RegistrationPage3_text__n4\\+lG {
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/modules/registration/components/RegistrationPageStep3/RegistrationPage3.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,mBAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,mBAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACF","sourcesContent":[".title {\n  font-size: 26px;\n  font-weight: 500;\n  line-height: 31px;\n}\n\n.element {\n  margin-top: 25px;\n  width: 430px;\n}\n\n.textFirst {\n  margin-bottom: 20px;\n  text-align: center;\n  font-weight: 500;\n}\n\n.text {\n  margin-bottom: 10px;\n  color: #0009;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `RegistrationPage3_title__0vMnx`,
	"element": `RegistrationPage3_element__VZz1K`,
	"textFirst": `RegistrationPage3_textFirst__nQMzD`,
	"text": `RegistrationPage3_text__n4+lG`
};
export default ___CSS_LOADER_EXPORT___;
