import React, { FC } from "react";
import styles from "./Page404.module.scss";

interface Page404Props {
  text?: string;
}

const Page404: FC<Page404Props> = ({ text }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default Page404;
