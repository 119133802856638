import localized from "helpers/localized";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setError, setInfo, setLoading } from "modules/main/store/MainSlice";
import { dealersApi } from "./DealersApi";
import { errorCodes } from "helpers/functions";
import {
  setDealers,
  setDealersAll,
  setPage,
  setTiberium,
  setDealer,
} from "./DealersSlice";
import { setFiltration } from "./DealersSlice";
import { fetchProfileData } from "modules/profile/store/ProfileAC";

export const fetchDealers = createAsyncThunk(
  "fetchDealers",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setFiltration(data));
      if (data.loading) thunkAPI.dispatch(setLoading(true));
      const response = await dealersApi.dealers({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      if (response?.data?.data) {
        thunkAPI.dispatch(setDealers(response?.data?.data));
        thunkAPI.dispatch(setPage(data.page));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
      if (data.loading) thunkAPI.dispatch(setLoading(false));
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchDealersAll = createAsyncThunk(
  "fetchDealersAll",
  async (data, thunkAPI) => {
    try {
      const response = await dealersApi.allDealers({
        token: thunkAPI?.getState()?.login?.token,
      });
      if (response?.data?.data) {
        thunkAPI.dispatch(setDealersAll(response?.data?.data));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchDealerApprove = createAsyncThunk(
  "fetchDealerApprove",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await dealersApi.dealerApprove({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      thunkAPI.dispatch(setLoading(false));
      if (response?.data?.data === null) {
        thunkAPI.dispatch(setInfo(localized.dealerApprove));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchDealerReject = createAsyncThunk(
  "fetchDealerReject",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await dealersApi.dealerReject({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      thunkAPI.dispatch(setLoading(false));
      if (response?.data?.data === null) {
        thunkAPI.dispatch(setInfo(localized.dealerReject));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchTiberiumData = createAsyncThunk(
  "fetchTiberiumData",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await dealersApi.tiberiumData({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      thunkAPI.dispatch(setLoading(false));

      if (response?.data?.data) {
        thunkAPI.dispatch(setTiberium(response?.data?.data));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchNewTiberiumData = createAsyncThunk(
  "fetchNewTiberiumData",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await dealersApi.newTiberiumData({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      thunkAPI.dispatch(setLoading(false));

      if (response?.data?.data) {
        thunkAPI.dispatch(setInfo(localized.dataSaved));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchCreateNewDealer = createAsyncThunk(
  "fetchCreateNewDealer",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await dealersApi.newDealer({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      thunkAPI.dispatch(setLoading(false));
      if (response?.data?.data?.id) {
        thunkAPI.dispatch(setInfo(localized.newDealer));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchChangeDealer = createAsyncThunk(
  "fetchChangeDealer",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await dealersApi.changeDealer({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });

      thunkAPI.dispatch(setLoading(false));
      if (response?.data?.data?.id) {
        if (thunkAPI?.getState()?.main?.adminPage === "company") {
          thunkAPI.dispatch(fetchProfileData());
        } else {
          thunkAPI.dispatch(fetchDealerInfo({ id: data.id }));
        }

        thunkAPI.dispatch(
          fetchDealers(thunkAPI?.getState()?.dealers?.filtration),
        );
        thunkAPI.dispatch(fetchDealersAll());
        thunkAPI.dispatch(setInfo(localized.dataChanged));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchDealerInfo = createAsyncThunk(
  "fetchDealerInfo",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));

      const response = await dealersApi.dealerInfo({
        ...data,
        token: thunkAPI?.getState()?.login?.token,
      });
      thunkAPI.dispatch(setLoading(false));

      if (response?.data?.data) {
        thunkAPI.dispatch(setDealer(response?.data?.data));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);
