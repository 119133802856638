// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pagination_pagination__axJcp {
  display: flex;
  height: 60px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  padding-bottom: 25px;
}

.Pagination_page__801PC {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 60px;
  width: 310px;
  background-color: #000000;
  color: #ffffff;
}

.Pagination_button__-2BWH {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 60px;
  height: 60px;
}

.Pagination_button__-2BWH:hover {
  opacity: 0.8;
}

.Pagination_button__-2BWH:active {
  opacity: 1;
}

.Pagination_form__YAOHg {
  color: #fff;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Pagination_right__KxqnE {
  transform: rotate(180deg);
}

.Pagination_form__YAOHg input {
  background-color: #000000;
  border: 1px solid rgb(255, 255, 255);
  width: 36px;
  height: 36px;
  color: #ffffff;
  text-align: center;
}

.Pagination_svg__xLGUr svg path {
  fill: rgb(255, 255, 255);
}

.Pagination_white__n5hrn svg path {
  fill: rgba(255, 255, 255, 0.4);
}`, "",{"version":3,"sources":["webpack://./src/kit/pagination/Pagination.module.scss","webpack://./src/kit/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,WAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,oBAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,QAAA;EACA,YAAA;EACA,YAAA;EACA,yBCnBY;EDoBZ,cCnBa;ADkBf;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,UAAA;AADF;;AAIA;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AADF;;AAIA;EACE,yBAAA;AADF;;AAIA;EACE,yBCrDY;EDsDZ,oCAAA;EACA,WAAA;EACA,YAAA;EACA,cCxDa;EDyDb,kBAAA;AADF;;AAKE;EACE,wBAAA;AAFJ;;AAOE;EACE,8BAAA;AAJJ","sourcesContent":["@import \"../variables\";\n\n.pagination {\n  display: flex;\n  height: 60px;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  margin-top: 35px;\n  padding-bottom: 25px;\n}\n\n.page {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 8px;\n  height: 60px;\n  width: 310px;\n  background-color: $FIRST_COLOR;\n  color: $SECOND_COLOR;\n}\n\n.button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  width: 60px;\n  height: 60px;\n}\n\n.button:hover {\n  opacity: 0.8;\n}\n\n.button:active {\n  opacity: 1;\n}\n\n.form {\n  color: #fff;\n  width: 80px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.right {\n  transform: rotate(180deg);\n}\n\n.form input {\n  background-color: $FIRST_COLOR;\n  border: 1px solid rgba(255, 255, 255, 1);\n  width: 36px;\n  height: 36px;\n  color: $SECOND_COLOR;\n  text-align: center;\n}\n\n.svg {\n  svg path {\n    fill: rgba(255, 255, 255, 1);\n  }\n}\n\n.white {\n  svg path {\n    fill: rgba(255, 255, 255, 0.4);\n  }\n}\n","$FIRST_COLOR: #000000;\n$SECOND_COLOR: #ffffff;\n$BASE_FONT: GothamPro, sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `Pagination_pagination__axJcp`,
	"page": `Pagination_page__801PC`,
	"button": `Pagination_button__-2BWH`,
	"form": `Pagination_form__YAOHg`,
	"right": `Pagination_right__KxqnE`,
	"svg": `Pagination_svg__xLGUr`,
	"white": `Pagination_white__n5hrn`
};
export default ___CSS_LOADER_EXPORT___;
