import { combineReducers, configureStore } from "@reduxjs/toolkit";
import mainReducer from "../modules/main/store/MainSlice";
import loginReducer from "../modules/login/store/LoginSlice";
import registrationReducer from "../modules/registration/store/RegistrationSlice";
import profileReducer from "../modules/profile/store/ProfileSlice";
import ordersReducer from "../modules/orders/store/OrdersSlice";
import productsReducer from "../modules/products/store/ProductsSlice";
import reportsReducer from "../modules/reports/store/ReportsSlice";
import dashboardReducer from "../modules/dashboard/store/DashboardSlice";
import usersReducer from "../modules/users/store/UsersSlice";
import supportReducer from "../modules/support/store/SupportSlice";
import dealersReducer from "../modules/dealers/store/DealersSlice";

const rootReducer = combineReducers({
  main: mainReducer,
  login: loginReducer,
  registration: registrationReducer,
  profile: profileReducer,
  orders: ordersReducer,
  products: productsReducer,
  reports: reportsReducer,
  dashboard: dashboardReducer,
  users: usersReducer,
  support: supportReducer,
  dealers: dealersReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
