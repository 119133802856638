// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewUser_wrapper__BhWqc {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0px 40px 20px;
}

.NewUser_elements__0Cxqe {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.NewUser_element__M8FAf {
  margin-right: 20px;
  width: 300px;
  margin-top: 20px;
}

.NewUser_headline__0a2Lc {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.NewUser_firstBlock__FiTnS {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.NewUser_block__CWPgF {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/modules/users/components/NewUser/NewUser.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,2BAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,eAAA;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 20px 0px 40px 20px;\n}\n\n.elements {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  margin-top: 10px;\n}\n\n.element {\n  margin-right: 20px;\n  width: 300px;\n  margin-top: 20px;\n}\n\n.headline {\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 20px;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px;\n}\n\n.firstBlock {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-wrap: wrap;\n}\n\n.block {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `NewUser_wrapper__BhWqc`,
	"elements": `NewUser_elements__0Cxqe`,
	"element": `NewUser_element__M8FAf`,
	"headline": `NewUser_headline__0a2Lc`,
	"firstBlock": `NewUser_firstBlock__FiTnS`,
	"block": `NewUser_block__CWPgF`
};
export default ___CSS_LOADER_EXPORT___;
