import { createAsyncThunk } from "@reduxjs/toolkit";
import { setError, setInfo, setLoading } from "modules/main/store/MainSlice";
import localized from "helpers/localized";

export const fetchDashboard = createAsyncThunk(
  "fetchDashboard",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      // const response = await authApi.login(data);
      // await localStorage.setItem("token", response.data.token);
      // await thunkAPI.dispatch(setToken(response.data.token));

      // заглушка пока нет бэка
      setTimeout(() => thunkAPI.dispatch(setLoading(false)), 400);
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));

      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);
