import React, { FC } from "react";
import styles from "./ReportsHeader.module.scss";
import localized from "helpers/localized";
import { useDispatch, useSelector } from "react-redux";
import { setPageReports } from "../store/ReportsSlice";
import { RootState } from "store/store";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const ReportsHeader: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const page = useSelector((state: RootState) => state.reports.page);

  return (
    <div className={styles.navigation}>
      <div
        onClick={() => {
          dispatch(setPageReports("goods"));
          navigate("/?p=reports&i=goods");
        }}
        className={clsx(
          styles.navigationItem,
          page === "goods" && styles.navigationItemActive,
        )}
      >
        {localized.turnoverReports}
      </div>
      <div
        onClick={() => {
          dispatch(setPageReports("delivery"));
          navigate("/?p=reports&i=delivery");
        }}
        className={clsx(
          styles.navigationItem,
          page === "delivery" && styles.navigationItemActive,
        )}
      >
        {localized.deliveryReports}
      </div>
    </div>
  );
};

export default ReportsHeader;
