import { createAsyncThunk } from "@reduxjs/toolkit";
import { setToken } from "modules/login/store/LoginSlice";
import { setAdminPage, setError, setLoading, setPage } from "./MainSlice";
import localized from "helpers/localized";
import { mainApi } from "./MainApi";
import { fetchProfileData } from "modules/profile/store/ProfileAC";
import { fetchUsers, fetchUsersAll } from "modules/users/store/UsersAC";
import { fetchDealers, fetchDealersAll } from "../../dealers/store/DealersAC";
import { setPageOrders } from "modules/orders/store/OrdersSlice";
import { setPageReports } from "modules/reports/store/ReportsSlice";
import { setPageUsers } from "modules/users/store/UsersSlice";
import { setPageDealers } from "modules/dealers/store/DealersSlice";

export const fetchCheckToken = createAsyncThunk(
  "fetchCheckToken",
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      thunkAPI.dispatch(setLoading(true));
      const response = await mainApi.checkToken();

      if (response.status === 200) {
        await thunkAPI.dispatch(setToken(token));
        await thunkAPI.dispatch(fetchData());
        setTimeout(() => thunkAPI.dispatch(checkPage()), 500);
      } else {
        thunkAPI.dispatch(setLoading(false));
        thunkAPI.dispatch(setPage("login"));
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchData = createAsyncThunk("fetchData", async (_, thunkAPI) => {
  try {
    // получаем данные для всей админки
    // получение данных профиля
    await thunkAPI.dispatch(fetchProfileData());

    if (thunkAPI?.getState()?.profile?.profile.role !== "DEALER_EMPLOYEE") {
      thunkAPI.dispatch(
        fetchUsers({
          loading: false,
          page: 1,
          id: "",
          firstName: "",
          lastName: "",
          middleName: "",
          position: "",
          phone: "",
          email: "",
          role: {},
          dealerId: [],
          status: {},
          loginType: {},
        }),
      );

      thunkAPI.dispatch(fetchUsersAll());
    }

    thunkAPI.dispatch(
      fetchDealers({
        loading: false,
        page: 1,
        id: "",
        inn: "",
        kpp: "",
        ogrn: "",
        legalAddress: "",
        address: "",
        name: "",
        status: "",
        bankName: "",
        bankAccount: "",
        bankCorAccount: "",
        bankBik: "",
      }),
    );
    thunkAPI.dispatch(fetchDealersAll());
  } catch (e: any) {
    thunkAPI.dispatch(setLoading(false));
    thunkAPI.dispatch(setError(localized.errorCommon));
  }
});

export const checkPage = createAsyncThunk("checkPage", async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(setPage("admin"));
    const page = new URLSearchParams(window.location.search).get("p");
    const pageInside = new URLSearchParams(window.location.search).get("i");
    if (page) {
      thunkAPI.dispatch(setAdminPage(page));
      if (page === "orders") {
        thunkAPI.dispatch(setPageOrders(pageInside));
      } else if (page === "reports") {
        thunkAPI.dispatch(setPageReports(pageInside));
      } else if (page === "users") {
        thunkAPI.dispatch(setPageUsers(pageInside));
      } else if (page === "dealers") {
        thunkAPI.dispatch(setPageDealers(pageInside));
      }
    }
    thunkAPI.dispatch(setLoading(false));
  } catch (e: any) {
    thunkAPI.dispatch(setError(localized.errorCommon));
  }
});
