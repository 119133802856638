import LocalizedStrings from "react-localization";

const localized = new LocalizedStrings({
  ru: {
    loginTitle: "Войти в личный кабинет",
    registrationTitle: "Зарегистрироваться",
    login: "Логин",
    phone: "Телефон",
    email: "Почта",
    password: "Пароль",
    passwordOld: "Старый пароль",
    passwordNew: "Новый пароль",
    enter: "Войти",
    confirm: "Подтвердить",
    code: "Код подтверждения",
    registration: "Зарегистрироваться",
    registrationPage: "Страница регистрации",
    loginPage: "Страница входа",
    confirmationTitle: "Подтверждение",
    phoneError: "Введите корректный телефон",
    codeError: "Введите отправленный код",
    emailError: "Введите корректную почту",
    dashboard: " Дашбоард",
    orders: "Заказы",
    catalog: "Каталог",
    categories: "Категории",
    products: "Товары",
    delivery: "Доставка",
    reports: "Отчёты",
    turnoverReports: "По товарообороту",
    deliveryReports: "По доставкам",
    turnoverReportsTitle: "Отчёты по товарообороту",
    deliveryReportsTitle: "Отчёты по доставкам",
    company: "Компания",
    support: "Поддержка",
    rules: "Правила",
    offer: "Оферта",
    answers: "Вопрос-ответ",
    from: "От",
    fr: "С",
    to: "До",
    timezone: "МСК",
    companyTitle: "Данные о компании",
    companyName: "Название компании",
    inn: "ИНН",
    ogrn: "ОГРН",
    kpp: "КПП",
    name: "Имя",
    position: "Должность",
    save: "Сохранить",
    skip: "Пропустить",
    next: "Далее",
    congratulations: "Поздравляем!",
    registrationInfo1: "Вы успешно зарегистрировались!",
    registrationInfo2:
      "Пожалуйста, на следующем шаге введите данные о Вашей компании",
    registrationInfo3:
      "Они являются необязательными при регистрации, но обязательными для интеграции с Тибериумом",
    registrationInfo4:
      "Без ввода этих данных, Вы можете получить доступ в Личный Кабинет для ознакомления и загрузки данных о товарах",
    registrationInfo5:
      "Однако полноценная работа с заказами возможна только после верификации данных о компании Оператором Дарить легко",
    innError: "ИНН это 10 символов",
    ogrnError: "ОГРН это 13 символов",
    kppError: "КПП это 9 символов",
    downloadContract: "Скачать шаблон договора",
    uploadContract: "Загрузить договор",
    deleteContract: "Удалить договор",
    mobileErrorTitle: "Страница недоступна",
    mobileErrorText:
      "Работать в личном кабинете с мобильной версии не получится. Откройте эту страницу в полномасштабном экране",
    contractErrorDoc: "Расширение файла должно быть .doc или .docx",
    error: "Ошибка",
    order: "Заказ",
    created: "Создан",
    changed: "Изменён",
    status: "Статус",
    customerName: "ФИО клиента",
    customerPhone: "Телефон клиента",
    customerEmail: "Email клиента",
    numberItems: "Кол-во товаров",
    orderAmount: "Сумма заказа",
    deliveryAmount: "Сумма доставки",
    total: "Итого",
    partnerComment: "Партнёр коммент",
    supplierComment: "Поставщик коммент",
    product: "Товар",
    trackNumber: "Трек номер",
    dateDelivery: "Дата доставки",
    date: "Дата",
    invoice: "Накладная",
    deliveryStatus: "Статус доставки",
    number: "Номер",
    statisticsOrders: "Статистика по заказам",
    responsiblePerson: "Данные ответственного лица",
    legalAddress: "Юридический адрес",
    actualAddress: "Фактический адрес",
    downloadOrganizationCard: "Загрузить карточку организации",
    deleteOrganizationCard: "Удалить карточку организации",
    paymentDetails: "Платежные данные",
    rs: "Расчётный счет",
    bankName: "Название банка",
    bik: "БИК",
    cr: "Корреспондентский счет",
    addEmployee: "Добавить сотрудника",
    successfully: "Успешно",
    dataSaved: "Новые данные сохранены",
    newEmployee: "Сотрудник добавлен",
    profile: "Профиль",
    doubleClickInfo: "Двойной клик покажет всю информацию",
    productName: "Название",
    category: "Категория",
    brand: "Бренд",
    collection: "Коллекция",
    article: "Артикул",
    barcode: "Штрихкод",
    gender: "Пол",
    reserve: "Резерв",
    remainder: "Остаток",
    price: "Цена",
    close: "Закрыть",
    info: "Информация",
    orderNumber: "Номер заказа",
    caseNumber: "Номер заказа витрины",
    showcase: "Витрина",
    manufacturer: "Производитель",
    categoryName: "Наименование категории",
    fullCategoryName: "Полное наименование категории",
    provider: "Поставщик",
    denomination: "Номинал",
    quantity: "Количество",
    userPrice: "Цена пользователя",
    deliveryID: "ID доставки",
    costDelivery: "Стоимость доставки",
    report: "Отчёт",
    day: "День",
    month: "Месяц",
    year: "Год",
    tableFilter: "Фильтр по таблице",
    cancel: "Отменить",
    apply: "Применить",
    change: "Изменить",
    enterCode: "Ввести код",
    exit: "Выйти",
    changePhone: "Изменить телефон",
    changeEmail: "Изменить email",
    changePassword: "Изменить пароль",
    fullPrice: "Стоимость",
    orderStatistics: "Статистика",
    orderList: "Список",
    link: "Ссылка",
    oldPrice: "Старая цена",
    description: "Описание",
    deletePhoto: "Удалить фото",
    uploadPhoto: "Загрузить фото",
    photoErrorDoc: "Расширение файла должно быть jpg, .jpeg или .png",
    excelError: "Расширение файла должно быть xls или .xlsx",
    ymlError: "Расширение файла должно быть .xml",
    archive: "Архив",
    requiredFields: "Обязательные поля",
    optionalFields: "Необязательные поля",
    guarantee: "Гарантия",
    power: "Мощность",
    age: "Возраст",
    color: "Цвет",
    numberElements: "Кол-во элементов",
    numberNozzles: "Кол-во насадок",
    numberSpeeds: "Кол-во скоростей",
    material: "Материал",
    size: "Размер",
    type: "Тип",
    dustLitres: "Объем пылесборника л.",
    bowlLitres: "Объем чаши л.",
    housingMaterial: "Материал корпуса",
    machinePower: "Питание",
    addProduct: "Добавить товар",
    productAdded: "Товар успешно добавлен",
    productsAdded: "Товары успешно добавлен",
    errorBIK: "БИК это 9 символов",
    errorRS: "Расчётный счёт это 20 символов",
    errorCor: "Корр. счёт это 20 символов",
    errorPhoto: "Загрузите фото",
    selectCategory: "Выберите категорию",
    enterTitle: "Введите название",
    enterPrice: "Введите цену",
    addingManually: "Добавление товара вручную",
    addingExcel: "Добавление товара excel",
    addingYML: "Добавление товара YML",
    resend: "Отправить повторно",
    downloadTemplate: "Скачать шаблон",
    uploadFile: "Загрузить файл",
    deleteFile: "Удалить файл",
    errorCommon: "Попробуйте еще раз или обновите страницу",
    left: "Осталось",
    secondsEnter: "Секунд на ввод кода",
    codeAgain: "Отправьте код еще раз",
    lastName: "Фамилия",
    middleName: "Отчество",
    downloadExcel:
      "Скачайте шаблон, заполните его нужными товарами, загрузите его, товары добавятся в ваш каталог",
    infoYML:
      "YML (Yandex Market Language) — собственный стандарт Яндекса, основанный на XML. В YML-файлах можно целиком описать каталог магазина в формате, удобном для автоматической генерации",
    openManual: "Открыть инструкцию",
    changeProfile: "Изменить данные о пользователе",
    role: "Роль",
    selectRole: "Выберите роль",
    dealers: "Поставщики",
    newUser: "Новый пользователь создан",
    userId: "ID пользователя",
    changeUser: "Изменить пользователя",
    dataChanged: "Данные были изменены",
    addUser: "Добавить пользователя",
    usersList: "Список пользователей",
    enterUserId: "Введите ID пользователя",
    userData: "Получить данные о пользователе",
    noData: "Нет данных",
    users: "Пользователи",
    user: "Пользователь",
    message: "Сообщение",
    dealerManagers: "Менеджеры поставщика",
    organizationCard: "Карточка организации",
    contract: "Договор",
    resetFilters: "Сбросить фильтры",
    apiCode: "Код",
    url: "Урл",
    errorEmpty: "Введите данные",
    commentReason: "Причина отклонения",
    reject: "Отклонить",
    dealerApprove: "Поставщик одобрен",
    dealerReject: "Поставщик отклонён",
    download: "Скачать",
    tiberiumTitle: "Доступ к API Тибериума",
    get: "Получить",
    copy: "Скопировать",
    update: "Обновить",
    copySuccess: "Данные скопированы",
    downloaded: "Загружен",
    toArchive: "В архив",
    error404:
      "Извините, вы открыли несуществующую страницу, выберите страницу из списка слева",
    resetPassword: "Сбросить пароль",
    backArchive: "Разархивировать",
    getUserData: "Получить данные о пользователе",
    whatLogin: "Что будет логином",
    addDealer: "Добавить поставщика",
    changeDealer: "Изменить поставщика",
    listDealers: "Список поставщиков",
    dealerData: "Получить данные о поставщике",
    idDealer: "id поставщика",
    saveDataUser: "Сохранить данные о пользователе",
    saveDataCompany: "Сохранить данные о компании",
    newEmp: "Добавление сотрудника",
    userInvitation: "Пользователь приглашён",
    contractTemplate: "Шаблон договора",
    linkPassword: "Отправлена ссылка на сброс пароля",
    newDealer: "Поставщик добавлен",
    enterNewPass: "Пожалуйста, теперь войдите в личный кабинет с вашим паролем",
    yes: "Да",
    no: "Нет",
    pickupPoints: "Пункты выдачи",
    ownDelivery: "Собственная доставка",
    filtration: "Фильтрация",
    delete: "Удалить",

    ADMIN: "Админ",
    MGC_EMPLOYEE: "Сотрудник MGC",
    DEALER_MANAGER: "Ответственный поставщика",
    DEALER_EMPLOYEE: "Сотрудник поставщика",
    NEW: "Новый",
    REGISTERED: "Зарегистрирован",
    ACTIVE: "Активен",
    DELETED: "Удален",
    INTERNAL_SERVER_ERROR: "Внутренняя ошибка сервера",
    DATABASE_ERROR: "Ошибка базы данных",
    USER_NOT_FOUND: "Пользователь не найден",
    USER_STATUS_INVALID: "Запрещено для пользователя с таким статусом",
    USER_BLOCKED: "Пользователь заблокирован",
    USER_DELETED: "Пользователь удален",
    USER_REGISTRATION_ERROR: "Не удалось зарегистрировать пользователя",
    USER_ALREADY_REGISTERED: "Пользователь уже зарегистрирован",
    USER_CREATE_ERROR: "Не удалось создать пользователя",
    BAD_PASSWORD: "Неверный пароль",
    PASSWORD_NOT_VALID:
      "Пароль должен быть длиной не менее 10 символов и содержать буквы в разных регистрах, цифры и символы",
    OLD_PASSWORD_INVALID: "Старый пароль указан неверно",
    FILE_NOT_SAVED: "Не удалось сохранить файл",
    FILE_NOT_EXIST: "Отсутствует файл",
    FILE_FORMAT_INVALID: "Неверный формат файла",
    FILE_SIZE_EXCEEDED: "Превышен максимальный размер файла",
    INVALID_FIELDS: "Некорректные поля в запросе",
    INVALID_FIELD_MAX: "Поле должно содержать меньше символов",
    INVALID_FIELD_MIN: "Поле должно содержать больше символов",
    SYSTEM_ERROR: "Системная ошибка",
    REQUIRED_FIELDS_MISSING: "Отсутствуют обязательные поля",
    ALREADY_EXISTS: "Уже существует",
    EMAIL_NOT_VALID:
      "Указан неправильный адрес электронной почты, адрес должен быть вида XXX@XXXX.XXX",
    REQUEST_CODE_INVALID: "Неверный код подтверждения",
    REQUEST_CODE_ERROR: "Не удалось проверить код подтверждения",
    SEND_REQUEST_CODE_ERROR: "Не удалось отправить код подтверждения",
    TOKEN_INVALID: "Неверный токен",
    SENDER_ERROR: "Ошибка пр отправке сообщения",
    DEALER_ALREADY_EXISTS: "Данный поставщик уже был зарегистрирован",
    FIELD_SIZE_EXCEEDED: "Некорректная длина данных",
    PERMISSION_DENIED: "Доступ запрещен",
    ON_REVIEW: "На проверке",
    APPROVED: "Активен",
    REJECTED: "Заблокирован",
    NOT_FOUND: "Не найдено",
    DEALER_STATUS_DOES_NOT_MATCH: "Статус поставщика не соответствует действию",
    DEALER_MODERATION_ERROR: "Не удалось выполнить модерацию поставщика",
    INVALID_URL_FORMAT: "Некорректный url",
    DEALER_UPDATE_ERROR: "Не удалось обновить поставщика",
    ALREADY_IN_ARCHIVE: "Пользователь уже отправлен в архив",
    ALREADY_OUT_ARCHIVE: "Пользователь не находится в архиве",
    DEALER_DUPLICATE_INN: "Поставщик с таким ИНН уже существует",
    MAXIMUM_ATTEMPTS_EXCEEDED: "Превышено максимальное количество попыток",
    RESET_PASSWORD_ERROR: "Не удалось сбросить пароль пользователя",
    SET_PASSWORD_ERROR: "Не удалось установить новый пароль пользователя",
    SET_TEMPORARY_PASSWORD_ERROR:
      "Не удалось установить временный пароль пользователя",
    USER_INVITE_ERROR: "Не удалось пригласить пользователя",
    RELATED_DEALER_COUNT_ERROR:
      "Сотрудник поставщика не может относиться более чем к 1 компании",
    USER_UPDATE_ERROR: "Не удалось обновить пользователя",
    DEALER_CREATE_ERROR: "Не удалось создать поставщика",
  },
  en: {
    loginTitle: "Login to account",
    registrationTitle: "Register",
    login: "Login",
    phone: "Phone",
    email: "Email",
    password: "Password",
    passwordOld: "Old password",
    passwordNew: "New password",
    enter: "Enter",
    confirm: "Confirm",
    code: "Confirmation code",
    registration: "Register",
    registrationPage: "Registration page",
    loginPage: "Login page",
    confirmationTitle: "Confirmation",
    phoneError: "Login cannot be empty",
    codeError: "Enter the code sent",
    emailError: "Enter a valid phone number",
    dashboard: "Dashboard",
    orders: "Orders",
    catalog: "Catalog",
    categories: "Categories",
    products: "Goods",
    delivery: "Delivery",
    reports: "Reports",
    turnoverReports: "turnover",
    deliveryReports: "delivery",
    turnoverReportsTitle: "Turnover reports",
    deliveryReportsTitle: "Delivery reports",
    company: "Company",
    support: "Support",
    rules: "Rules",
    offer: "Offer",
    answers: "Question-answer",
    from: "From",
    fr: "From",
    to: "To",
    timezone: "",
    companyTitle: "Company information",
    companyName: "Company name",
    inn: "INN",
    ogrn: "OGRN",
    kpp: "KPP",
    name: "Your name",
    position: "Position",
    save: "Save",
    skip: "Skip",
    next: "Next",
    congratulations: "Congratulations!",
    registrationInfo1: "You have successfully registered!",
    registrationInfo2: "Please enter your company information in the next step",
    registrationInfo3:
      "They are optional for registration, but required for integration with Tiberium",
    registrationInfo4:
      "Without entering this data, you can access your Personal Account to view and download product data",
    registrationInfo5:
      "However, full-fledged work with orders is possible only after verification of company data by the Operator Its easy to give",
    innError: "INN is 10 characters",
    ogrnError: "ORGN is 13 characters",
    kppError: "KPP is 9 characters",
    downloadContract: "Download contract template",
    uploadContract: "Upload contract",
    deleteContract: "Delete contract",
    mobileErrorTitle: "Page unavailable",
    mobileErrorText:
      "You won't be able to work in your personal account from the mobile version. Open this page in full screen",
    contractErrorDoc: "The file extension must be .doc or .docx",
    error: "Error",
    order: "Order",
    created: "Created",
    changed: "Changed",
    status: "Status",
    customerName: "Customer's name",
    customerPhone: "Customer phone number",
    customerEmail: "Customer Email",
    numberItems: "Number of items",
    orderAmount: "Order Amount",
    deliveryAmount: "Delivery amount",
    total: "Total",
    partnerComment: "Partner comment",
    supplierComment: "Supplier comment",
    product: "Product",
    trackNumber: "Track number",
    dateDelivery: "Date delivery",
    date: "Date",
    invoice: "Invoice",
    deliveryStatus: "Delivery status",
    number: "Number",
    statisticsOrders: "Statistics on orders",
    responsiblePerson: "Responsible person details",
    legalAddress: "Legal address",
    actualAddress: "Actual address",
    downloadOrganizationCard: "Download the organization card",
    deleteOrganizationCard: "Delete the organization card",
    paymentDetails: "Payment details",
    rs: "Checking account",
    bankName: "Bank's name",
    bik: "BIK",
    cr: "Correspondent account",
    addEmployee: "Add an employee",
    successfully: "Successfully",
    dataSaved: "New data saved",
    newEmployee: "Employee added",
    profile: "Profile",
    doubleClickInfo: "Double click will show all information",
    productName: "Name",
    category: "Category",
    brand: "Brand",
    collection: "Collection",
    article: "Article",
    barcode: "Barcode",
    gender: "Gender",
    reserve: "Reserve",
    remainder: "Remainder",
    price: "Price",
    close: "Close",
    info: "Information",
    orderNumber: "Order number",
    caseNumber: "Display case order number",
    showcase: "Showcase",
    manufacturer: "Manufacturer",
    categoryName: "Category name",
    fullCategoryName: "Full category name",
    provider: "Provider",
    denomination: "Denomination",
    quantity: "Quantity",
    userPrice: "User price",
    deliveryID: "Delivery ID",
    costDelivery: "Cost of delivery",
    report: "Report",
    day: "Day",
    month: "Month",
    year: "Year",
    tableFilter: "Filter by table",
    cancel: "Cancel",
    apply: "Apply",
    change: "Change",
    enterCode: "Enter code",
    exit: "Exit",
    changePhone: "Change phone",
    changeEmail: "Change email",
    changePassword: "Change password",
    fullPrice: "Full Price",
    orderStatistics: "Statistics",
    orderList: "List",
    link: "Link",
    oldPrice: "Old price",
    description: "Description",
    deletePhoto: "Delete photo",
    uploadPhoto: "Upload photo",
    photoErrorDoc: "The file extension must be .jpg, .jpeg or .png",
    excelError: "The file extension must be .xls or .xlsx",
    ymlError: "The file extension must be .xml",
    archive: "Archive",
    requiredFields: "Required fields",
    optionalFields: "Optional fields",
    guarantee: "Guarantee",
    power: "Power",
    age: "Age",
    color: "Color",
    numberElements: "Number of elements",
    numberNozzles: "Number of nozzles",
    numberSpeeds: "Number of speeds",
    material: "Material",
    size: "Size",
    type: "Type",
    dustLitres: "Dust container capacity l.",
    bowlLitres: "Bowl volume l.",
    housingMaterial: "Housing material",
    machinePower: "Power",
    addProduct: "Add product",
    productAdded: "Product added successfully",
    productsAdded: "Products added successfully",
    errorBIK: "BIK is 9 characters",
    errorRS: "CA is 20 characters",
    errorCor: "CA is 20 characters",
    errorPhoto: "Upload photo",
    selectCategory: "Select a category",
    enterTitle: "Enter the title",
    enterPrice: "Enter price",
    addingManually: "Adding a product manually",
    addingExcel: "Adding excel product",
    addingYML: "Adding YML product",
    resend: "Resend code",
    downloadTemplate: "Download template",
    uploadFile: "Upload file",
    deleteFile: "Delete file",
    errorCommon: "Try again or refresh the page",
    left: "Left",
    secondsEnter: "Seconds to enter the code",
    codeAgain: "Send the code again",
    lastName: "Last name",
    middleName: "Middle name",
    downloadExcel:
      "Download the template, fill it with the necessary products, upload it, the products will be added to your catalog",
    infoYML:
      "YML (Yandex Market Language) is Yandex's own standard, based on XML. YML files can completely describe the store catalog in a format convenient for automatic generation",
    openManual: "Open instructions",
    changeProfile: "Change user details",
    role: "Role",
    selectRole: "Select a role",
    dealers: "Dealers",
    newUser: "New user created",
    userId: "User ID",
    changeUser: "Change the user",
    dataChanged: "The data was changed",
    addUser: "Add the user",
    usersList: "List of users",
    enterUserId: "Enter user ID",
    userData: "Get user data",
    noData: "No data",
    users: "Users",
    user: "User",
    message: "Message",
    dealerManagers: "Dealer managers",
    organizationCard: "Organization card",
    contract: "Contract",
    resetFilters: "Reset filters",
    apiCode: "Code",
    url: "Url",
    errorEmpty: "Enter data",
    commentReason: "Reason for rejection",
    reject: "Reject",
    dealerApprove: "Dealer approved",
    dealerReject: "Dealer rejected",
    download: "Download",
    tiberiumTitle: "Access to Tiberium API",
    get: "Get",
    copy: "Copy",
    update: "Update",
    copySuccess: "Data copied",
    downloaded: "Downloaded",
    toArchive: "To archive",
    error404:
      "Sorry, you have opened a non-existent page, please select a page from the list on the left",
    resetPassword: "Reset password",
    backArchive: "Unarchive",
    getUserData: "Get user data",
    whatLogin: "What will be the login",
    addDealer: "Add supplier",
    changeDealer: "Change supplier",
    listDealers: "List of suppliers",
    dealerData: "Get supplier data",
    idDealer: "vendor id",
    saveDataUser: "Save user data",
    saveDataCompany: "Save company data",
    newEmp: "Adding employee",
    userInvitation: "User invited",
    contractTemplate: "Contract template",
    linkPassword: "Password reset link sent",
    newDealer: "Dealer added",
    enterNewPass:
      "Please now log in to your personal account with your password",
    yes: "Yes",
    no: "No",
    pickupPoints: "Pickup points",
    ownDelivery: "Own delivery",
    filtration: "Filtration",
    delete: "Delete",

    ADMIN: "Admin",
    MGC_EMPLOYEE: "MGC Employee",
    DEALER_MANAGER: "Dealer in Charge",
    DEALER_EMPLOYEE: "Supplier Employee",
    NEW: "New",
    REGISTERED: "Registered",
    ACTIVE: "Active",
    DELETED: "Deleted",
    INTERNAL_SERVER_ERROR: "Internal server error",
    DATABASE_ERROR: "Database error",
    USER_NOT_FOUND: "User not found",
    USER_STATUS_INVALID: "Forbidden for user with this status",
    USER_BLOCKED: "User is blocked",
    USER_DELETED: "User deleted",
    USER_REGISTRATION_ERROR: "Failed to register user",
    USER_ALREADY_REGISTERED: "User is already registered",
    USER_CREATE_ERROR: "Could not create user",
    BAD_PASSWORD: "Invalid password",
    PASSWORD_NOT_VALID:
      "The password must be at least 10 characters long and contain mixed-case letters, numbers and symbols",
    OLD_PASSWORD_INVALID: "The old password is incorrect",
    FILE_NOT_SAVED: "Failed to save file",
    FILE_NOT_EXIST: "File is missing",
    FILE_FORMAT_INVALID: "Invalid file format",
    FILE_SIZE_EXCEEDED: "Maximum file size exceeded",
    INVALID_FIELDS: "Invalid fields in the request",
    INVALID_FIELD_MAX: "The field must contain fewer characters",
    INVALID_FIELD_MIN: "The field must contain more characters",
    SYSTEM_ERROR: "System error",
    REQUIRED_FIELDS_MISSING: "Required fields are missing",
    ALREADY_EXISTS: "Already exists",
    EMAIL_NOT_VALID:
      "The email address specified is incorrect, the email address should be XXX@XXXX.XXX",
    REQUEST_CODE_INVALID: "Invalid confirmation code",
    REQUEST_CODE_ERROR: "The confirmation code could not be verified",
    SEND_REQUEST_CODE_ERROR: "Failed to send confirmation code",
    TOKEN_INVALID: "Invalid token",
    SENDER_ERROR: "Error sending message",
    DEALER_ALREADY_EXISTS: "This supplier has already been registered",
    FIELD_SIZE_EXCEEDED: "Invalid data length",
    PERMISSION_DENIED: "Access is denied",
    ON_REVIEW: "On review",
    APPROVED: "Approved",
    REJECTED: "Rejected",
    NOT_FOUND: "Not found",
    DEALER_STATUS_DOES_NOT_MATCH: "The vendor status does not match the action",
    DEALER_MODERATION_ERROR: "Failed to moderate the vendor",
    INVALID_URL_FORMAT: "Invalid url",
    DEALER_UPDATE_ERROR: "Failed to update supplier",
    ALREADY_IN_ARCHIVE: "User has already been archived",
    ALREADY_OUT_ARCHIVE: "User is not in the archive",
    DEALER_DUPLICATE_INN: "A supplier with the same INN already exists",
    MAXIMUM_ATTEMPTS_EXCEEDED: "Maximum number of attempts exceeded",
    RESET_PASSWORD_ERROR: "Failed to reset user password",
    SET_PASSWORD_ERROR: "Could not set new user password",
    SET_TEMPORARY_PASSWORD_ERROR: "Failed to set temporary user password",
    USER_INVITE_ERROR: "Failed to invite user",
    RELATED_DEALER_COUNT_ERROR:
      "A supplier employee cannot belong to more than 1 company",
    USER_UPDATE_ERROR: "Failed to update user",
    DEALER_CREATE_ERROR: "Failed to create supplier",
  },
  kz: {
    loginTitle: "Жеке шотыңызға кіріңіз",
    registrationTitle: "Тіркелу",
    login: "Кіру",
    phone: "Телефон",
    email: "Пошта",
    password: "Пароль",
    passwordOld: "Ескі құпия сөз",
    passwordNew: "Жаңа құпия сөз",
    enter: "Кіру",
    confirm: "Растау",
    code: "Растау коды",
    registration: "Тіркелу",
    registrationPage: "Тіркеу парағы",
    loginPage: "Кіру парағы",
    confirmationTitle: "Растау",
    phoneError: "Телефон нөмірін енгізіңіз",
    codeError: "Жіберілген кодты енгізіңіз",
    emailError: "Электрондық поштаны енгізіңіз",
    dashboard: "Бақылау тақтасы",
    orders: "Тапсырыстар",
    catalog: "Каталог",
    categories: "Категории",
    products: "Товары",
    delivery: "Жеткізу",
    reports: "Есептер",
    turnoverReports: "айналым туралы",
    deliveryReports: "жеткізу туралы",
    turnoverReportsTitle: "Айналым туралы есептер",
    deliveryReportsTitle: "Жеткізу есептері",
    company: "Компания",
    support: "Қолдау",
    rules: "Ережелер",
    offer: "Ұсыныс",
    answers: "Сұрақ-жауап",
    from: "От",
    fr: "С",
    to: "до",
    timezone: "МСК",
    companyTitle: "Компания туралы ақпарат",
    companyName: "Компанияның атауы",
    inn: "ИНН",
    ogrn: "ОГРН",
    kpp: "ОГРН",
    name: "Аты",
    position: "Устанымыңыз",
    save: "Сақтау",
    skip: "Өткізіп жіберу",
    next: "Әрі қарай",
    congratulations: "Құттықтаймыз!",
    registrationInfo1: "Сіз сәтті тіркелдіңіз!",
    registrationInfo2: "Келесі қадамда компанияңыз туралы ақпаратты енгізіңіз",
    registrationInfo3:
      "Олар тіркелу үшін міндетті емес, бірақ Тибериуммен біріктіру үшін қажет",
    registrationInfo4:
      "Бұл деректерді енгізбестен, өнім деректерін көру және жүктеп алу үшін жеке тіркелгіңізге кіруге болады",
    registrationInfo5:
      "Алайда, тапсырыстармен толыққанды жұмыс Оператор компания деректерін тексергеннен кейін ғана мүмкін болады",
    innError: "ИНН 10 таңба",
    ogrnError: "ОГРН 13 таңба",
    kppError: "КПП 9 таңба",
    downloadContract: "Келісімшарт үлгісін жүктеп алу",
    uploadContract: "Жүктеп салу шарты",
    deleteContract: "Келісімшартты жою",
    mobileErrorTitle: "Бет қолжетімді емес",
    mobileErrorText:
      "Сіз мобильді нұсқадан жеке кабинетте жұмыс істей алмайсыз. Бұл бетті толық экранда ашыңыз",
    contractErrorDoc: "Файл кеңейтімі .doc немесе .docx болуы керек",
    error: "Кате",
    order: "Тапсырыс",
    created: "Жасалған",
    changed: "Өзгертілді",
    status: "Күй",
    customerName: "Тұтынушының аты",
    customerPhone: "Тұтынушының телефон нөмірі",
    customerEmail: "Тұтынушының электрондық поштасы",
    numberItems: "Элементтердің саны",
    orderAmount: "Тапсырыс сомасы",
    deliveryAmount: "Жеткізу сомасы",
    total: "Барлығы",
    partnerComment: "Серіктестің пікірі",
    supplierComment: "Жеткізушіден түсініктеме",
    product: "Өнім",
    trackNumber: "Трек нөмірі",
    dateDelivery: "Жеткізіп беру күні",
    date: "Күні",
    invoice: "Шот-фактура",
    deliveryStatus: "Жеткізу күйі",
    number: "Сан",
    statisticsOrders: "Тапсырыстар бойынша статистика",
    responsiblePerson: "Жауапты тұлға туралы мәліметтер",
    legalAddress: "Заңды мекен-жайы",
    actualAddress: "Нақты мекен-жайы",
    downloadOrganizationCard: "Ұйым картасын жүктеп алыңыз",
    deleteOrganizationCard: "Ұйым картасын жойыңыз",
    paymentDetails: "Төлем мәліметтері",
    rs: "Корреспонденттік шот",
    bankName: "Банктің атауы",
    bik: "БИК",
    cr: "Корреспонденттік шот",
    addEmployee: "Қызметкерді қосыңыз",
    successfully: "Сәтті",
    dataSaved: "Жаңа деректер сақталды",
    newEmployee: "Қызметкер қосылды",
    profile: "Профиль",
    doubleClickInfo: "Екі рет басу барлық ақпаратты көрсетеді",
    productName: "Атауы",
    category: "Санат",
    brand: "Бренд",
    collection: "Жинау",
    article: "Бап",
    barcode: "Штрих-код",
    gender: "Гендер",
    reserve: "Резерв",
    remainder: "Қалдық",
    price: "Баға",
    close: "Жабық",
    info: "Ақпарат",
    orderNumber: "Тапсырыс нөмірі",
    caseNumber: "Көрсеткіш корпусының тапсырыс нөмірі",
    showcase: "Көрме",
    manufacturer: "Өндіруші",
    categoryName: "Санат атауы",
    fullCategoryName: "Толық санат атауы",
    provider: "Провайдер",
    denomination: "Номиналы",
    quantity: "Саны",
    userPrice: "Пайдаланушы бағасы",
    deliveryID: "Жеткізу ID",
    costDelivery: "Жеткізу құны",
    report: "Есеп беру",
    day: "Күн",
    month: "Ай",
    year: "Жыл",
    tableFilter: "Кесте бойынша сүзу",
    cancel: "Болдырмау",
    apply: "Применить",
    change: "Өзгерту",
    enterCode: "Кодты енгізіңіз",
    exit: "Шығу",
    changePhone: "Телефонды өзгерту",
    changeEmail: "Электрондық поштаны өзгерту",
    changePassword: "Құпия сөзді өзгерту",
    fullPrice: "Толық баға",
    orderStatistics: "Статистикасы",
    orderList: "Тізімі",
    link: "Сілтеме",
    oldPrice: "Ескі баға",
    description: "Сипаттама",
    deletePhoto: "Фотосуретті жүктеп салу",
    uploadPhoto: "Фотосуретті жою",
    photoErrorDoc: "Файл кеңейтімі jpg, .jpeg немесе .png болуы керек",
    excelError: "Файл кеңейтімі .xls немесе .xlsx болуы керек",
    ymlError: "Файл кеңейтімі xls болуы керек",
    archive: "Мұрағат",
    requiredFields: "Міндетті өрістер",
    optionalFields: "Қосымша өрістер",
    guarantee: "Кепілдік",
    power: "Қуат",
    age: "Жас",
    color: "Түс",
    numberElements: "Элементтер саны",
    numberNozzles: "Саңылаулар саны",
    numberSpeeds: "Жылдамдық саны",
    material: "Материал",
    size: "Өлшем",
    type: "Түрі",
    dustLitres: "Шаң контейнерінің сыйымдылығы l.",
    bowlLitres: "Ыдыс көлемі l.",
    housingMaterial: "Тұрғын үй материалы",
    machinePower: "Қуаты",
    addProduct: "Өнімді қосыңыз",
    productAdded: "Өнім сәтті қосылды",
    productsAdded: "Өнімдер сәтті қосылды",
    errorBIK: "БИК - 9 таңба",
    errorRS: "КШ - 20 таңба",
    errorCor: "КШ - 20 таңба",
    errorPhoto: "Фотосуретті жүктеп салу",
    selectCategory: "Санатты таңдаңыз",
    enterTitle: "Тақырыпты енгізіңіз",
    enterPrice: "Бағаны енгізіңіз",
    addingManually: "Өнімді қолмен қосу",
    addingExcel: "Excel өнімін қосу",
    addingYML: "YML өнімін қосу",
    resend: "Қайта жіберу",
    downloadTemplate: "Үлгіні жүктеп алу",
    uploadFile: "Файлды жүктеп салу",
    deleteFile: "Файлды жою",
    errorCommon: "Қайталап көріңіз немесе бетті жаңартыңыз",
    left: "Кодты енгізуге",
    secondsEnter: "Секунд қалды",
    codeAgain: "Кодты қайта жіберіңіз",
    lastName: "Тегі",
    middleName: "Екінші аты",
    downloadExcel:
      "Үлгіні жүктеп алыңыз, оны қажетті өнімдермен толтырыңыз, жүктеңіз, өнімдер каталогыңызға қосылады",
    infoYML:
      "YML (Yandex Market Language) - XML ​​негізіндегі Яндекстің өзіндік стандарты. YML файлдары дүкен каталогын автоматты түрде құруға ыңғайлы форматта толығымен сипаттай алады",
    openManual: "Ашық нұсқаулар",
    changeProfile: "Пайдаланушы мәліметтерін өзгерту",
    role: "Рөл",
    selectRole: "Рөлді таңдау",
    dealers: "Жабдықтаушылар",
    newUser: "Жаңа пайдаланушы жасалды",
    userId: "Қолданушының ID",
    changeUser: "Пайдаланушыны өзгерту",
    dataChanged: "Деректер өзгертілді",
    addUser: "Пайдаланушыны қосу",
    usersList: "Пайдаланушылар тізімі",
    enterUserId: "Пайдаланушы ID енгізіңіз",
    userData: "Пайдаланушы деректерін алу",
    noData: "Дерек жоқ",
    users: "Пайдаланушылар",
    user: "Пайдаланушы",
    message: "Хабар",
    dealerManagers: "Жабдықтаушы менеджерлер",
    organizationCard: "Ұйымдастыру картасы",
    contract: "Келісім",
    resetFilters: "Сүзгілерді қалпына келтіру",
    apiCode: "Код",
    url: "Урл",
    errorEmpty: "Мәліметтерді енгізіңіз",
    commentReason: "Қабылданбау себебі",
    reject: "Қабылдамау",
    dealerApprove: "Жеткізуші мақұлдады",
    dealerReject: "Жеткізуші қабылданбады",
    download: "Жүктеу",
    tiberiumTitle: "Tiberium API қолжетімділігі",
    get: "Алу",
    copy: "Көшіру",
    update: "Жаңарту",
    copySuccess: "Дерек көшірілді",
    downloaded: "Жүктелді",
    toArchive: "Мұрағатқа",
    error404:
      "Кешіріңіз, сіз жоқ бетті аштыңыз, сол жақтағы тізімнен бетті таңдаңыз",
    resetPassword: "Құпия сөзді қалпына келтіру",
    backArchive: "Архивтен шығару",
    getUserData: "Пайдаланушы деректерін алу",
    whatLogin: "Кіру қандай болады",
    addDealer: "Жеткізуші қосу",
    changeDealer: "Жабдықтаушыны өзгерту",
    listDealers: "Жеткізушілер тізімі",
    dealerData: "Жеткізуші деректерін алу",
    idDealer: "Сатушы идентификаторы",
    saveDataUser: "Пайдаланушы деректерін сақтау",
    saveDataCompany: "Компания деректерін сақтау",
    newEmp: "Қызметкерді қосу",
    userInvitation: "Пайдаланушы шақырылды",
    contractTemplate: "Келісім үлгісі",
    linkPassword: "Құпия сөзді қалпына келтіру сілтемесі жіберілді",
    newDealer: "Жеткізуші қосылды",
    enterNewPass: "Енді құпия сөзбен жеке тіркелгіңізге кіріңіз",
    yes: "Иә",
    no: "Жоқ",
    pickupPoints: "Қабылдау нүктелері",
    ownDelivery: "Жеке жеткізу",
    filtration: "Сүзу",
    delete: "Жою",

    ADMIN: "Әкімші",
    MGC_EMPLOYEE: "MGC қызметкері",
    DEALER_MANAGER: "Жауапты дилер",
    DEALER_EMPLOYEE: "Жеткізуші қызметкер",
    NEW: "Жаңа",
    REGISTERED: "Тіркелген",
    ACTIVE: "Белсенді",
    DELETED: "Жойылған",
    INTERNAL_SERVER_ERROR: "Ішкі сервер қатесі",
    DATABASE_ERROR: "Дерекқор қатесі",
    USER_NOT_FOUND: "Пайдаланушы табылмады",
    USER_STATUS_INVALID: "Осы күйі бар пайдаланушыға тыйым салынған",
    USER_BLOCKED: "Пайдаланушы бұғатталған",
    USER_DELETED: "Пайдаланушы жойылды",
    USER_REGISTRATION_ERROR: "Пайдаланушыны тіркеу мүмкін болмады",
    USER_ALREADY_REGISTERED: "Пайдаланушы әлдеқашан тіркелген",
    USER_CREATE_ERROR: "Пайдаланушыны жасау мүмкін емес",
    BAD_PASSWORD: "Жарамсыз құпия сөз",
    PASSWORD_NOT_VALID:
      "Құпия сөздің ұзындығы кемінде 10 таңбадан тұруы және аралас әріптерден, сандардан және таңбалардан тұруы керек",
    OLD_PASSWORD_INVALID: "Ескі құпия сөз дұрыс емес",
    FILE_NOT_SAVED: "Файлды сақтау сәтсіз аяқталды",
    FILE_NOT_EXIST: "Файл жоқ",
    FILE_FORMAT_INVALID: "Файл пішімі жарамсыз",
    FILE_SIZE_EXCEEDED: "Файлдың ең үлкен өлшемінен асты",
    INVALID_FIELDS: "Сұраудағы жарамсыз өрістер",
    INVALID_FIELD_MAX: "Өріс азырақ таңбалардан тұруы керек",
    INVALID_FIELD_MIN: "Өрісте көбірек таңбалар болуы керек",
    SYSTEM_ERROR: "Жүйе қатесі",
    REQUIRED_FIELDS_MISSING: "Міндетті өрістер жоқ",
    ALREADY_EXISTS: "Бұрын бар",
    EMAIL_NOT_VALID:
      "Көрсетілген электрондық пошта мекенжайы дұрыс емес, электрондық пошта мекенжайы XXX@XXXX.XXX болуы керек",
    REQUEST_CODE_INVALID: "Жарамсыз растау коды",
    REQUEST_CODE_ERROR: "Растау кодын тексеру мүмкін емес",
    SEND_REQUEST_CODE_ERROR: "Растау кодын жіберу мүмкін болмады",
    TOKEN_INVALID: "Жарамсыз белгі",
    SENDER_ERROR: "Хабар жіберу қатесі",
    DEALER_ALREADY_EXISTS: "Бұл жеткізуші бұрыннан тіркелген",
    FIELD_SIZE_EXCEEDED: "Дерек ұзындығы жарамсыз",
    PERMISSION_DENIED: "Кіруге тыйым салынды",
    ON_REVIEW: "Қаралуда",
    APPROVED: "Белсенді",
    REJECTED: "Блокталған",
    NOT_FOUND: "Табылмады",
    DEALER_STATUS_DOES_NOT_MATCH: "Жеткізуші күйі әрекетке сәйкес келмейді",
    DEALER_MODERATION_ERROR: "Жеткізушіні модерациялау мүмкін болмады",
    INVALID_URL_FORMAT: "url жарамсыз",
    DEALER_UPDATE_ERROR: "Жеткізуші жаңартылмады",
    ALREADY_IN_ARCHIVE: "Пайдаланушы әлдеқашан мұрағатталған",
    ALREADY_OUT_ARCHIVE: "Пайдаланушы мұрағатта жоқ",
    DEALER_DUPLICATE_INN: "Бірдей INN бар жеткізуші бұрыннан бар",
    MAXIMUM_ATTEMPTS_EXCEEDED: "Әрекеттердің ең көп санынан асты",
    RESET_PASSWORD_ERROR:
      "Пайдаланушы құпия сөзін қалпына келтіру мүмкін болмады",
    SET_PASSWORD_ERROR: "Жаңа пайдаланушы құпия сөзін орнату мүмкін болмады",
    SET_TEMPORARY_PASSWORD_ERROR:
      "Уақытша пайдаланушы құпия сөзін орнату сәтсіз аяқталды",
    USER_INVITE_ERROR: "Пайдаланушыны шақыру мүмкін болмады",
    RELATED_DEALER_COUNT_ERROR:
      "Жабдықтаушы қызметкер 1-ден көп компанияға кіре алмайды",
    USER_UPDATE_ERROR: "Пайдаланушыны жаңарту мүмкін болмады",
    DEALER_CREATE_ERROR: "Жеткізуші жасау мүмкін болмады",
  },
});

export default localized;
