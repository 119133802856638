// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChangeUser_wrapper__PRHsg {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0px 40px 20px;
}

.ChangeUser_elements__2rZc\\+ {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.ChangeUser_element__QanMc {
  margin-right: 20px;
  width: 300px;
  margin-top: 20px;
}

.ChangeUser_headline__hsRMC {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.ChangeUser_firstBlock__EatlR {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.ChangeUser_block__3mYLU {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/modules/users/components/ChangeUser/ChangeUser.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,2BAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,eAAA;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 20px 0px 40px 20px;\n}\n\n.elements {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  margin-top: 10px;\n}\n\n.element {\n  margin-right: 20px;\n  width: 300px;\n  margin-top: 20px;\n}\n\n.headline {\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 20px;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px;\n}\n\n.firstBlock {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-wrap: wrap;\n}\n\n.block {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ChangeUser_wrapper__PRHsg`,
	"elements": `ChangeUser_elements__2rZc+`,
	"element": `ChangeUser_element__QanMc`,
	"headline": `ChangeUser_headline__hsRMC`,
	"firstBlock": `ChangeUser_firstBlock__EatlR`,
	"block": `ChangeUser_block__3mYLU`
};
export default ___CSS_LOADER_EXPORT___;
