import React, { FC } from "react";
import styles from "./UsersPopup.module.scss";
import localized from "helpers/localized";
import Popup from "kit/popup/Popup";
import Button from "kit/button/Button";
import Table from "kit/table/Table";
import { setUserInfo, setPageUsers } from "modules/users/store/UsersSlice";
import { useNavigate } from "react-router-dom";
import { translateCodes } from "helpers/translateCodes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";

const UsersPopup: FC = ({ show, setOpenPopup, activeRow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const role = useSelector((state: RootState) => state.profile.profile.role);

  const newDealers = () => {
    let newDealers = [];
    for (let i = 0; i < activeRow?.dealers.length; i++) {
      const cardTitle = activeRow?.dealers[i].card && localized.downloaded;
      const contractTitle =
        activeRow?.dealers[i].contract && localized.downloaded;
      const dealerManagers = activeRow?.dealers[i].dealerManagers.length;
      newDealers.push({
        ...activeRow?.dealers[i],
        dealerManagers: dealerManagers,
        card: cardTitle,
        contract: contractTitle,
        status: translateCodes(activeRow?.dealers[i].status),
      });
    }

    return newDealers;
  };

  return (
    <Popup
      show={show}
      closeText={localized.close}
      infoText={localized.info}
      close={setOpenPopup}
    >
      <div className={styles.hwrapper}>
        <div className={styles.h}>{localized.user}</div>
      </div>

      <div className={styles.rows}>
        {activeRow?.id && (
          <div className={styles.row}>
            <p className={styles.left}>ID</p>
            <p className={styles.right}>{activeRow?.id}</p>
          </div>
        )}
        {activeRow?.firstName && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.name}</p>
            <p className={styles.right}>{activeRow?.firstName}</p>
          </div>
        )}
        {activeRow?.lastName && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.lastName}</p>
            <p className={styles.right}>{activeRow?.lastName}</p>
          </div>
        )}
        {activeRow?.middleName && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.middleName}</p>
            <p className={styles.right}>{activeRow?.middleName}</p>
          </div>
        )}
        {activeRow?.position && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.position}</p>
            <p className={styles.right}>{activeRow?.position}</p>
          </div>
        )}
        {activeRow?.phone && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.phone}</p>
            <p className={styles.right}>{activeRow?.phone}</p>
          </div>
        )}
        {activeRow?.email && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.email}</p>
            <p className={styles.right}>{activeRow?.email}</p>
          </div>
        )}
        {activeRow?.role && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.role}</p>
            <p className={styles.right}>{translateCodes(activeRow?.role)}</p>
          </div>
        )}
        {activeRow?.status && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.status}</p>
            <p className={styles.right}>{translateCodes(activeRow?.status)}</p>
          </div>
        )}
        {activeRow?.loginType && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.login}</p>
            <p className={styles.right}>
              {activeRow?.loginType === "phone"
                ? localized.phone
                : localized.email}
            </p>
          </div>
        )}
      </div>

      <div className={styles.table}>
        <div className={styles.tableContainer}>
          <div className={styles.headline}>{localized.dealers}</div>
          <Table
            left={true}
            columns={[
              {
                title: "ID",
                key: "id",
                type: "string",
              },
              {
                title: localized.companyName,
                key: "name",
                type: "string",
              },
              {
                title: localized.inn,
                key: "inn",
                type: "string",
              },
              {
                title: localized.kpp,
                key: "kpp",
                type: "string",
              },
              {
                title: localized.ogrn,
                key: "ogrn",
                type: "string",
              },
              {
                title: localized.dealerManagers,
                key: "dealerManagers",
                type: "string",
              },
              {
                title: localized.legalAddress,
                key: "legalAddress",
                type: "string",
              },
              {
                title: localized.actualAddress,
                key: "address",
                type: "string",
              },
              {
                title: localized.bankName,
                key: "bankName",
                type: "string",
              },
              {
                title: localized.rs,
                key: "bankAccount",
                type: "string",
              },
              {
                title: localized.bik,
                key: "bankBik",
                type: "string",
              },
              {
                title: localized.cr,
                key: "bankCorAccount",
                type: "string",
              },
              {
                title: localized.contract,
                key: "contract",
                type: "string",
              },
              {
                title: localized.organizationCard,
                key: "card",
                type: "string",
              },
              {
                title: localized.status,
                key: "status",
                type: "string",
              },
            ]}
            rows={newDealers()}
            noData={localized.noData}
          />
        </div>
      </div>

      <div className={styles.buttonWrapper}>
        {role !== "DEALER_EMPLOYEE" && (
          <>
            <div className={styles.els}>
              <div className={styles.element}>
                <Button
                  id="change"
                  onClick={() => {
                    dispatch(setUserInfo(activeRow));
                    dispatch(setPageUsers("change"));
                    navigate("/?p=users&i=change");
                  }}
                >
                  {localized.change}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Popup>
  );
};

export default UsersPopup;
