import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setError,
  setInfo,
  setLoading,
  setPossibilityUpload,
} from "modules/main/store/MainSlice";
import localized from "helpers/localized";
import { setPage } from "./ProductsSlice";
import { setPhoto } from "./ProductsSlice";

export const fetchProducts = createAsyncThunk(
  "fetchProducts",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      thunkAPI.dispatch(setPage(data.page));
      // const response = await authApi.login(data);
      // await localStorage.setItem("token", response.data.token);
      // await thunkAPI.dispatch(setToken(response.data.token));

      // заглушка пока нет бэка
      setTimeout(() => thunkAPI.dispatch(setLoading(false)), 400);
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));

      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchSendPhotoProduct = createAsyncThunk(
  "fetchSendPhotoProduct",
  async (data, thunkAPI) => {
    try {
      if (thunkAPI?.getState()?.main?.possibilityUpload) {
        if (
          data.name?.toLowerCase().split(".").pop() === "jpg" ||
          data?.name?.toLowerCase().split(".").pop() === "jpeg" ||
          data?.name?.toLowerCase().split(".").pop() === "png"
        ) {
          thunkAPI.dispatch(setLoading(true));
          setTimeout(() => thunkAPI.dispatch(setPossibilityUpload(true)), 3000);
          // const response = await authApi.login(data);
          // await localStorage.setItem("token", response.data.token);
          // await thunkAPI.dispatch(setToken(response.data.token));

          // заглушка пока нет бэка
          setTimeout(() => thunkAPI.dispatch(setLoading(false)), 400);
          setTimeout(() => thunkAPI.dispatch(setPhoto("photoName")), 400);
        } else {
          thunkAPI.dispatch(setError(localized.photoErrorDoc));
        }
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchSendProduct = createAsyncThunk(
  "fetchSendProduct",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      // const response = await authApi.login(data);
      // await localStorage.setItem("token", response.data.token);
      // await thunkAPI.dispatch(setToken(response.data.token));

      // заглушка пока нет бэка

      setTimeout(() => thunkAPI.dispatch(setLoading(false)), 400);
      setTimeout(() => thunkAPI.dispatch(setInfo(localized.productAdded)), 400);
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchExcelProduct = createAsyncThunk(
  "fetchExcelProduct",
  async (data, thunkAPI) => {
    try {
      if (thunkAPI?.getState()?.main?.possibilityUpload) {
        if (
          data.name?.toLowerCase().split(".").pop() === "xls" ||
          data?.name?.toLowerCase().split(".").pop() === "xlsx"
        ) {
          thunkAPI.dispatch(setLoading(true));
          setTimeout(() => thunkAPI.dispatch(setPossibilityUpload(true)), 3000);
          // const response = await authApi.login(data);
          // await localStorage.setItem("token", response.data.token);
          // await thunkAPI.dispatch(setToken(response.data.token));

          // заглушка пока нет бэка

          setTimeout(() => thunkAPI.dispatch(setLoading(false)), 400);
          setTimeout(
            () => thunkAPI.dispatch(setInfo(localized.productsAdded)),
            400,
          );
        } else {
          thunkAPI.dispatch(setError(localized.excelError));
        }
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchYMLProduct = createAsyncThunk(
  "fetchYMLProduct",
  async (data, thunkAPI) => {
    try {
      if (thunkAPI?.getState()?.main?.possibilityUpload) {
        if (data.name?.toLowerCase().split(".").pop() === "xml") {
          thunkAPI.dispatch(setLoading(true));
          setTimeout(() => thunkAPI.dispatch(setPossibilityUpload(true)), 3000);
          // const response = await authApi.login(data);
          // await localStorage.setItem("token", response.data.token);
          // await thunkAPI.dispatch(setToken(response.data.token));

          // заглушка пока нет бэка

          setTimeout(() => thunkAPI.dispatch(setLoading(false)), 400);
          setTimeout(
            () => thunkAPI.dispatch(setInfo(localized.productsAdded)),
            400,
          );
        } else {
          thunkAPI.dispatch(setError(localized.ymlError));
        }
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);
