import React, { FC, useState } from "react";
import Input from "kit/input/Input";
import localized from "helpers/localized";
import styles from "./OrdersListFilter.module.scss";
import Calendar from "kit/calendar/Calendar";
import { RootState } from "store/store";
import { useSelector } from "react-redux";

const OrdersListFilter: FC = () => {
  const main = useSelector((state: RootState) => state.main);

  const [order, setOrder] = useState("");
  const [createdFrom, setCreatedFrom] = useState("");
  const [createdTo, setCreatedTo] = useState("");
  const [changedFrom, setChangedFrom] = useState("");
  const [changedTo, setChangedTo] = useState("");
  const [status, setStatus] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [numberItemsFrom, setNumberItemsFrom] = useState("");
  const [numberItemsTo, setNumberItemsTo] = useState("");
  const [orderAmountFrom, setOrderAmountFrom] = useState("");
  const [orderAmountTo, setOrderAmountTo] = useState("");
  const [deliveryAmountFrom, setDeliveryAmountFrom] = useState("");
  const [deliveryAmountTo, setDeliveryAmountTo] = useState("");
  const [totalFrom, setTotalFrom] = useState("");
  const [totalTo, setTotalTo] = useState("");
  const [partnerComment, setPartnerComment] = useState("");
  const [supplierComment, setSupplierComment] = useState("");
  const [product, setProduct] = useState("");
  const [trackNumber, setTrackNumber] = useState("");
  const [dateDeliveryFrom, setDateDeliveryFrom] = useState("");
  const [dateDeliveryTo, setDateDeliveryTo] = useState("");
  const [invoice, setInvoice] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState("");

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{localized.tableFilter}</div>

      <div className={styles.elements}>
        <div className={styles.element}>
          <Input
            id="order"
            value={order}
            label={localized.order}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setOrder(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Calendar
            id="createdFrom"
            label={localized.created + " " + localized.from}
            value={createdFrom}
            onChange={(value) => {
              setCreatedFrom(value);
            }}
            locale={main.language}
            day={localized.day}
            month={localized.month}
            year={localized.year}
          />{" "}
        </div>

        <div className={styles.element}>
          <Calendar
            id="createdTo"
            label={localized.created + " " + localized.to}
            value={createdTo}
            onChange={(value) => {
              setCreatedTo(value);
            }}
            locale={main.language}
            day={localized.day}
            month={localized.month}
            year={localized.year}
          />{" "}
        </div>

        <div className={styles.element}>
          <Calendar
            id="changedFrom"
            label={localized.changed + " " + localized.from}
            value={changedFrom}
            onChange={(value) => {
              setChangedFrom(value);
            }}
            locale={main.language}
            day={localized.day}
            month={localized.month}
            year={localized.year}
          />{" "}
        </div>

        <div className={styles.element}>
          <Calendar
            id="changedTo"
            label={localized.changed + " " + localized.to}
            value={changedTo}
            onChange={(value) => {
              setChangedTo(value);
            }}
            locale={main.language}
            day={localized.day}
            month={localized.month}
            year={localized.year}
          />{" "}
        </div>

        <div className={styles.element}>
          <Input
            id="status"
            value={status}
            label={localized.status}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setStatus(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="customerName"
            value={customerName}
            label={localized.customerName}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCustomerName(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            type="phone"
            id="customerPhone"
            label={localized.customerPhone}
            value={customerPhone}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCustomerPhone(e);
            }}
          />
        </div>
        <div className={styles.element}>
          <Input
            type="email"
            id="email"
            value={customerEmail}
            label={localized.customerEmail}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCustomerEmail(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="numberItemsFrom"
            value={numberItemsFrom}
            label={localized.numberItems + " " + localized.from}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setNumberItemsFrom(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="numberItemsTo"
            value={numberItemsTo}
            label={localized.numberItems + " " + localized.to}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setNumberItemsTo(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="orderAmountFrom"
            value={orderAmountFrom}
            label={localized.orderAmount + " " + localized.from}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setOrderAmountFrom(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="orderAmountTo"
            value={orderAmountTo}
            label={localized.orderAmount + " " + localized.to}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setOrderAmountTo(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="deliveryAmountFrom"
            value={deliveryAmountFrom}
            label={localized.deliveryAmount + " " + localized.from}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDeliveryAmountFrom(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="deliveryAmountTo"
            value={deliveryAmountTo}
            label={localized.deliveryAmount + " " + localized.to}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDeliveryAmountTo(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="totalFrom"
            value={totalFrom}
            label={localized.total + " " + localized.from}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTotalFrom(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="totalTo"
            value={totalTo}
            label={localized.total + " " + localized.to}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTotalTo(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="partnerComment"
            value={partnerComment}
            label={localized.partnerComment}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPartnerComment(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="supplierComment"
            value={supplierComment}
            label={localized.supplierComment}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSupplierComment(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="product"
            value={product}
            label={localized.product}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setProduct(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="trackNumber"
            value={trackNumber}
            label={localized.trackNumber}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTrackNumber(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Calendar
            id="dateDeliveryFrom"
            label={localized.dateDelivery + " " + localized.from}
            value={dateDeliveryFrom}
            onChange={(value) => {
              setDateDeliveryFrom(value);
            }}
            locale={main.language}
            day={localized.day}
            month={localized.month}
            year={localized.year}
          />{" "}
        </div>

        <div className={styles.element}>
          <Calendar
            id="dateDeliveryTo"
            label={localized.dateDelivery + " " + localized.to}
            value={dateDeliveryTo}
            onChange={(value) => {
              setDateDeliveryTo(value);
            }}
            locale={main.language}
            day={localized.day}
            month={localized.month}
            year={localized.year}
          />{" "}
        </div>

        <div className={styles.element}>
          <Input
            id="invoice"
            value={invoice}
            label={localized.invoice}
            allowed="numbers"
            length={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInvoice(e);
            }}
          />
        </div>

        <div className={styles.element}>
          <Input
            id="deliveryStatus"
            value={deliveryStatus}
            label={localized.deliveryStatus}
            length={50}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDeliveryStatus(e);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OrdersListFilter;
