// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanyPage_wrapper__ENWVe {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

.CompanyPage_block__SHo0u {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.CompanyPage_blockEmployee__dxDZ\\+ {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -60px;
}

.CompanyPage_switch__Wceu4 {
  margin-top: 35px;
  margin-right: 10px;
}

.CompanyPage_titleFirst__8iZRi {
  font-size: 26px;
  font-weight: 500;
  line-height: 31px;
}

.CompanyPage_title__26Du3 {
  font-size: 26px;
  font-weight: 500;
  line-height: 31px;
  margin-top: 20px;
}

.CompanyPage_element__XWGMG {
  margin-top: 20px;
  margin-right: 20px;
  width: 300px;
}

.CompanyPage_download__LqiNu {
  width: 25px;
  height: 25px;
}

.CompanyPage_upload__TKYj5 {
  width: 25px;
  height: 25px;
  transform: rotate(180deg);
}

.CompanyPage_downloadText__\\+ZGl1 {
  font-weight: 400;
  margin-right: 15px;
}

.CompanyPage_file__q3GYt {
  width: 0;
  height: 0;
  opacity: 0;
}

.CompanyPage_fileWrapper__aVXi0 {
  height: 60px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/modules/company/CompanyPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,QAAA;EACA,SAAA;EACA,UAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 20px;\n}\n\n.block {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-wrap: wrap;\n}\n\n.blockEmployee {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-wrap: wrap;\n  margin-left: -60px;\n}\n\n.switch {\n  margin-top: 35px;\n  margin-right: 10px;\n}\n\n.titleFirst {\n  font-size: 26px;\n  font-weight: 500;\n  line-height: 31px;\n}\n\n.title {\n  font-size: 26px;\n  font-weight: 500;\n  line-height: 31px;\n  margin-top: 20px;\n}\n\n.element {\n  margin-top: 20px;\n  margin-right: 20px;\n  width: 300px;\n}\n\n.download {\n  width: 25px;\n  height: 25px;\n}\n\n.upload {\n  width: 25px;\n  height: 25px;\n  transform: rotate(180deg);\n}\n\n.downloadText {\n  font-weight: 400;\n  margin-right: 15px;\n}\n\n.file {\n  width: 0;\n  height: 0;\n  opacity: 0;\n}\n\n.fileWrapper {\n  height: 60px;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CompanyPage_wrapper__ENWVe`,
	"block": `CompanyPage_block__SHo0u`,
	"blockEmployee": `CompanyPage_blockEmployee__dxDZ+`,
	"switch": `CompanyPage_switch__Wceu4`,
	"titleFirst": `CompanyPage_titleFirst__8iZRi`,
	"title": `CompanyPage_title__26Du3`,
	"element": `CompanyPage_element__XWGMG`,
	"download": `CompanyPage_download__LqiNu`,
	"upload": `CompanyPage_upload__TKYj5`,
	"downloadText": `CompanyPage_downloadText__+ZGl1`,
	"file": `CompanyPage_file__q3GYt`,
	"fileWrapper": `CompanyPage_fileWrapper__aVXi0`
};
export default ___CSS_LOADER_EXPORT___;
