// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Calendar_calendar__tblRv {
  position: relative;
  height: 60px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: #000000;
  background-color: transparent;
}

.Calendar_label__C2SAf {
  position: absolute;
  font-size: 13px !important;
  line-height: 17px !important;
  margin: 0 !important;
  top: 9px;
  left: 16px;
  color: rgba(0, 0, 0, 0.6);
  text-transform: lowercase;
}

.Calendar_wrapper__OJNV- {
  font-family: GothamPro, sans-serif;
  height: 58px;
  padding-top: 20px;
  position: absolute;
  width: 100%;
  padding-left: 14px;
  background-color: transparent;
  border: none !important;
  transition: all 300ms ease;
  stroke: #666 !important;
}

.Calendar_clearIcon__JE5xT {
  transition: all 300ms ease;
}

.Calendar_clearIcon__JE5xT:hover {
  opacity: 0.4 !important;
}

.Calendar_modal__WDPok {
  font-family: GothamPro, sans-serif;
  margin-top: 4px;
}

.Calendar_icon__ssXSd path {
  transition: all 300ms ease;
}

.Calendar_icon__ssXSd rect {
  transition: all 300ms ease;
}

.Calendar_icon__ssXSd:hover path {
  stroke: #666 !important;
}

.Calendar_icon__ssXSd:hover rect {
  stroke: #666 !important;
}

.Calendar_wrapper__OJNV- > div {
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/kit/calendar/Calendar.module.scss","webpack://./src/kit/variables.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,oCAAA;EACA,cAAA;EACA,6BAAA;AADF;;AAIA;EACE,kBAAA;EACA,0BAAA;EACA,4BAAA;EACA,oBAAA;EACA,QAAA;EACA,UAAA;EACA,yBAAA;EACA,yBAAA;AADF;;AAIA;EACE,kCCrBU;EDsBV,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,6BAAA;EACA,uBAAA;EACA,0BAAA;EACA,uBAAA;AADF;;AAIA;EACE,0BAAA;AADF;;AAIA;EACE,uBAAA;AADF;;AAIA;EACE,kCC1CU;ED2CV,eAAA;AADF;;AAIA;EACE,0BAAA;AADF;;AAIA;EACE,0BAAA;AADF;;AAIA;EACE,uBAAA;AADF;;AAIA;EACE,uBAAA;AADF;;AAIA;EACE,YAAA;AADF","sourcesContent":["@import \"../variables\";\n\n.calendar {\n  position: relative;\n  height: 60px;\n  width: 100%;\n  border: 1px solid rgba(0, 0, 0, 0.5);\n  color: #000000;\n  background-color: transparent;\n}\n\n.label {\n  position: absolute;\n  font-size: 13px !important;\n  line-height: 17px !important;\n  margin: 0 !important;\n  top: 9px;\n  left: 16px;\n  color: rgba(0, 0, 0, 0.6);\n  text-transform: lowercase;\n}\n\n.wrapper {\n  font-family: $BASE_FONT;\n  height: 58px;\n  padding-top: 20px;\n  position: absolute;\n  width: 100%;\n  padding-left: 14px;\n  background-color: transparent;\n  border: none !important;\n  transition: all 300ms ease;\n  stroke: #666 !important;\n}\n\n.clearIcon {\n  transition: all 300ms ease;\n}\n\n.clearIcon:hover {\n  opacity: 0.4 !important;\n}\n\n.modal {\n  font-family: $BASE_FONT;\n  margin-top: 4px;\n}\n\n.icon path {\n  transition: all 300ms ease;\n}\n\n.icon rect {\n  transition: all 300ms ease;\n}\n\n.icon:hover path {\n  stroke: #666 !important;\n}\n\n.icon:hover rect {\n  stroke: #666 !important;\n}\n\n.wrapper > div {\n  border: none;\n}\n\n//.wrapper > div > div > input   {\n//  background-color: #ffffff!important;\n//  height: 14px;\n//  margin-top: 10px\n//}\n","$FIRST_COLOR: #000000;\n$SECOND_COLOR: #ffffff;\n$BASE_FONT: GothamPro, sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendar": `Calendar_calendar__tblRv`,
	"label": `Calendar_label__C2SAf`,
	"wrapper": `Calendar_wrapper__OJNV-`,
	"clearIcon": `Calendar_clearIcon__JE5xT`,
	"modal": `Calendar_modal__WDPok`,
	"icon": `Calendar_icon__ssXSd`
};
export default ___CSS_LOADER_EXPORT___;
