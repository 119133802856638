// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginPageStep1_title__HW3A8 {
  font-size: 26px;
  font-weight: 500;
  line-height: 31px;
}

.LoginPageStep1_elements__Qcamt {
  display: flex;
  align-items: flex-start;
}

.LoginPageStep1_el__ZXg\\+i {
  margin-top: 25px;
  margin-right: 10px;
  width: 370px;
}

.LoginPageStep1_switch__ZpV2A {
  margin-top: 38px;
}

.LoginPageStep1_elem__fvNWo {
  margin-top: 15px;
  width: 430px;
}

.LoginPageStep1_element__0RL4\\+ {
  margin-top: 25px;
  width: 430px;
}`, "",{"version":3,"sources":["webpack://./src/modules/login/components/LoginPageStep1/LoginPageStep1.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF","sourcesContent":[".title {\n  font-size: 26px;\n  font-weight: 500;\n  line-height: 31px;\n}\n\n.elements {\n  display: flex;\n  align-items: flex-start;\n}\n\n.el {\n  margin-top: 25px;\n  margin-right: 10px;\n  width: 370px;\n}\n\n.switch {\n  margin-top: 38px;\n}\n\n.elem {\n  margin-top: 15px;\n  width: 430px;\n}\n\n.element {\n  margin-top: 25px;\n  width: 430px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `LoginPageStep1_title__HW3A8`,
	"elements": `LoginPageStep1_elements__Qcamt`,
	"el": `LoginPageStep1_el__ZXg+i`,
	"switch": `LoginPageStep1_switch__ZpV2A`,
	"elem": `LoginPageStep1_elem__fvNWo`,
	"element": `LoginPageStep1_element__0RL4+`
};
export default ___CSS_LOADER_EXPORT___;
