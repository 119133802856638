import React, { FC } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import OrdersListPage from "./ordersList/OrdersListPage";
import OrdersStatisticsPage from "./ordersStatistics/OrdersStatisticsPage";
import OrdersHeader from "./ordersHeader/OrdersHeader";

const OrdersPage: FC = () => {
  const page = useSelector((state: RootState) => state.orders.page);

  return (
    <div>
      <OrdersHeader />
      <SwitchTransition mode="out-in">
        <CSSTransition key={page} timeout={500} classNames="fade">
          {page === "list" ? (
            <OrdersListPage />
          ) : page === "statistics" ? (
            <OrdersStatisticsPage />
          ) : (
            <div />
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default OrdersPage;
