import React, { FC, useState } from "react";
import styles from "./ReportsDeliveryPage.module.scss";
import localized from "helpers/localized";
import Table from "kit/table/Table";
import ReportDeliveryPopup from "./ReportDeliveryPopup/ReportDeliveryPopup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import Pagination from "kit/pagination/Pagination";
import ReportDeliveryFilter from "./ReportDeliveryFilter/ReportDeliveryFilter";
import Button from "kit/button/Button";

const ReportsDeliveryPage: FC = () => {
  const dispatch = useDispatch();

  const reports = useSelector((state: RootState) => state.reports);

  const [openPopup, setOpenPopup] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [activeRow, setActiveRow] = useState({});

  const openRow = (row: any) => {
    setActiveRow(row);
    setOpenPopup(true);
  };

  const changePagePagination = (page) => {};

  return (
    <>
      <ReportDeliveryPopup
        show={openPopup}
        setOpenPopup={setOpenPopup}
        activeRow={activeRow}
      />
      <div className={styles.wrapper}>
        <div className={styles.filterWrapper}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>{localized.deliveryReportsTitle}</div>
            <div className={styles.number}>12</div>
          </div>

          <div className={styles.filter}>
            {openFilter ? (
              <>
                <div className={styles.extraButton}>
                  <Button
                    id="close"
                    small
                    onClick={() => {
                      setOpenFilter(false);
                    }}
                  >
                    {localized.close}
                  </Button>
                </div>
                <Button
                  id="apply"
                  small
                  onClick={() => {
                    dispatch(fetchReportsDelivery({ page: 1 }));
                  }}
                >
                  {localized.apply}
                </Button>
              </>
            ) : (
              <Button
                id="tableFilter"
                small
                onClick={() => {
                  setOpenFilter(true);
                }}
              >
                {localized.tableFilter}
              </Button>
            )}
          </div>
        </div>
        <div className={styles.text}>{localized.doubleClickInfo}</div>

        {openFilter && <ReportDeliveryFilter />}

        <Table
          onOpenRow={openRow}
          columns={[
            {
              title: localized.orderNumber,
              key: "string1",
              type: "string",
            },
            {
              title: localized.status,
              key: "string2",
              type: "string",
            },
            {
              title: localized.created,
              key: "datetime1",
              type: "datetime",
            },
            {
              title: localized.changed,
              key: "datetime2",
              type: "datetime",
            },
            {
              title: localized.deliveryID,
              key: "string3",
              type: "string",
            },
            {
              title: localized.costDelivery,
              key: "string4",
              type: "string",
            },
          ]}
          rows={[
            {
              string1: "301123",
              string2: "Подтвержден",
              datetime1: new Date(),
              datetime2: new Date(),
              string3: "1231233",
              string4: "3500",
            },
            {
              string1: "301123",
              string2: "Подтвержден",
              datetime1: new Date(),
              datetime2: new Date(),
              string3: "1231233",
              string4: "3500",
            },
            {
              string1: "301123",
              string2: "Подтвержден",
              datetime1: new Date(),
              datetime2: new Date(),
              string3: "1231233",
              string4: "3500",
            },
            {
              string1: "301123",
              string2: "Подтвержден",
              datetime1: new Date(),
              datetime2: new Date(),
              string3: "1231233",
              string4: "3500",
            },
            {
              string1: "301123",
              string2: "Подтвержден",
              datetime1: new Date(),
              datetime2: new Date(),
              string3: "1231233",
              string4: "3500",
            },
            {
              string1: "301123",
              string2: "Подтвержден",
              datetime1: new Date(),
              datetime2: new Date(),
              string3: "1231233",
              string4: "3500",
            },
            {
              string1: "301123",
              string2: "Подтвержден",
              datetime1: new Date(),
              datetime2: new Date(),
              string3: "1231233",
              string4: "3500",
            },
            {
              string1: "301123",
              string2: "Подтвержден",
              datetime1: new Date(),
              datetime2: new Date(),
              string3: "1231233",
              string4: "3500",
            },
          ]}
          noData={localized.noData}
        />
        <Pagination
          currentPage={reports.pageDelivery}
          lastPage={10}
          onAccept={changePagePagination}
        />
      </div>
    </>
  );
};

export default ReportsDeliveryPage;
