import React, { FC, useState } from "react";
import styles from "./DashboardPage.module.scss";
import localized from "helpers/localized";
import Button from "kit/button/Button";
import Calendar from "kit/calendar/Calendar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { fetchDashboard } from "./store/DashboardAC";
import AreaChart from "kit/charts/AreaChart";

const DashboardPage: FC = () => {
  const dispatch = useDispatch();

  const main = useSelector((state: RootState) => state.main);

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{localized.dashboard}</div>

      <div className={styles.elements}>
        <div className={styles.element}>
          <Calendar
            id="dateFrom"
            label={localized.date + " " + localized.from}
            value={dateFrom}
            onChange={(value: any) => {
              setDateFrom(value);
            }}
            locale={main.language}
            day={localized.day}
            month={localized.month}
            year={localized.year}
          />{" "}
        </div>

        <div className={styles.element}>
          <Calendar
            id="dateTo"
            label={localized.date + " " + localized.to}
            value={dateTo}
            onChange={(value: any) => {
              setDateTo(value);
            }}
            locale={main.language}
            day={localized.day}
            month={localized.month}
            year={localized.year}
          />{" "}
        </div>
        <div className={styles.button}>
          <Button
            id="apply"
            secondary
            onClick={() => {
              dispatch(fetchDashboard());
            }}
          >
            применить
          </Button>
        </div>
        <div className={styles.button}>
          <Button
            id="week"
            secondary
            onClick={() => {
              dispatch(fetchDashboard());
            }}
          >
            За неделю
          </Button>
        </div>
        <div className={styles.button}>
          <Button
            id="month"
            secondary
            onClick={() => {
              dispatch(fetchDashboard());
            }}
          >
            За месяц
          </Button>
        </div>
        <div className={styles.button}>
          <Button
            id="quarter"
            secondary
            onClick={() => {
              dispatch(fetchDashboard());
            }}
          >
            За квартал
          </Button>
        </div>
        <div className={styles.button}>
          <Button
            id="year"
            secondary
            onClick={() => {
              dispatch(fetchDashboard());
            }}
          >
            За год
          </Button>
        </div>
      </div>

      <div className={styles.data}>
        <div className={styles.block}>
          <div className={styles.blockTitle}>12 000</div>
          <div className={styles.blockText}>Продажи, руб</div>
        </div>
        <div className={styles.block}>
          <div className={styles.blockTitle}>4</div>
          <div className={styles.blockText}>Продажи, шт</div>
        </div>
        <div className={styles.block}>
          <div className={styles.blockTitle}>3000</div>
          <div className={styles.blockText}>Средний чек, руб</div>
        </div>
        <div className={styles.block}>
          <div className={styles.blockTitle}>4</div>
          <div className={styles.blockText}>Заказы, шт</div>
        </div>
      </div>

      <div className={styles.charts}>
        <div className={styles.chart}>
          <AreaChart title={"Продажи, руб"} />
        </div>

        <div className={styles.chart}>
          <AreaChart title={"Продажи, шт"} />
        </div>

        <div className={styles.chart}>
          <AreaChart title={"Средний чек, руб"} />
        </div>

        <div className={styles.chart}>
          <AreaChart title={"Заказы шт"} />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
