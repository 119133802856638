import React, { FC, useEffect, useState } from "react";
import styles from "./DealersPopup.module.scss";
import localized from "helpers/localized";
import Button from "kit/button/Button";
import Table from "kit/table/Table";
import Input from "kit/input/Input";
import Popup from "kit/popup/Popup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { validateLength, validatePassword } from "helpers/validators";
import {
  fetchDealerApprove,
  fetchDealerReject,
  fetchTiberiumData,
  fetchNewTiberiumData,
} from "modules/dealers/store/DealersAC";
import { setInfo } from "modules/main/store/MainSlice";
import { setPageDealers, setDealer } from "modules/dealers/store/DealersSlice";
import { useNavigate } from "react-router-dom";
import { translateCodes } from "helpers/translateCodes";
import { mainApi } from "modules/main/store/MainApi";

const DealersPopup: FC = ({ show, setOpenPopup, activeRow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tiberium = useSelector((state: RootState) => state.dealers.tiberium);
  const role = useSelector((state: RootState) => state.profile.profile.role);

  const [comment, setComment] = useState("");
  const [commentValid, setCommentValid] = useState(true);

  const [apiLogin, setApiLogin] = useState("");
  const [apiLoginValid, setApiLoginValid] = useState(true);
  const [apiPassword, setApiPassword] = useState("");
  const [apiPasswordValid, setApiPasswordValid] = useState(true);

  const newManagers = () => {
    let newManagers = [];
    for (let i = 0; i < activeRow?.dealerManagers?.length; i++) {
      newManagers.push({
        ...activeRow?.dealerManagers[i],
        role: translateCodes(activeRow?.dealerManagers[i]?.role),
        status: translateCodes(activeRow?.dealerManagers[i]?.status),
      });
    }

    return newManagers;
  };

  const validateData = async () => {
    setApiLoginValid(validateLength(apiLogin, 0, true));
    setApiPasswordValid(validatePassword(apiPassword));

    if (validateLength(apiLogin, 0, true) && validatePassword(apiPassword)) {
      dispatch(
        fetchNewTiberiumData({
          id: activeRow?.id,
          apiLogin,
          apiPassword,
        }),
      );
    }
  };

  useEffect(() => {
    setApiLogin(tiberium.apiLogin);
    setApiLoginValid(true);
    setApiPassword(tiberium.apiPassword);
    setApiPasswordValid(true);
  }, [tiberium]);

  return (
    <Popup
      show={show}
      closeText={localized.close}
      infoText={localized.info}
      close={setOpenPopup}
    >
      <div className={styles.hwrapper}>
        <div className={styles.h}>{localized.provider}</div>
        {role === "ADMIN" && activeRow?.status === "ON_REVIEW" && (
          <div className={styles.els}>
            <div className={styles.el}>
              <Button
                id="confirm"
                onClick={() => {
                  dispatch(fetchDealerApprove({ id: activeRow?.id }));
                }}
              >
                {localized.confirm}
              </Button>
            </div>
            <div className={styles.el}>
              <Button
                id="reject"
                onClick={() => {
                  setCommentValid(validateLength(comment, 0, true));
                  if (validateLength(comment, 0, true))
                    dispatch(fetchDealerReject({ id: activeRow?.id, comment }));
                }}
              >
                {localized.reject}
              </Button>
            </div>
            <div className={styles.elinput}>
              <Input
                id="comment"
                value={comment}
                label={localized.commentReason + "*"}
                error={!commentValid}
                errorMessage={localized.errorEmpty}
                length={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setComment(e);
                  setCommentValid(true);
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles.rows}>
        {activeRow?.id && (
          <div className={styles.row}>
            <p className={styles.left}>ID</p>
            <p className={styles.right}>{activeRow?.id}</p>
          </div>
        )}
        {activeRow?.name && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.companyName}</p>
            <p className={styles.right}>{activeRow?.name}</p>
          </div>
        )}
        {activeRow?.inn && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.inn}</p>
            <p className={styles.right}>{activeRow?.inn}</p>
          </div>
        )}
        {activeRow?.kpp && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.kpp}</p>
            <p className={styles.right}>{activeRow?.kpp}</p>
          </div>
        )}
        {activeRow?.ogrn && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.ogrn}</p>
            <p className={styles.right}>{activeRow?.ogrn}</p>
          </div>
        )}
        {activeRow?.legalAddress && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.legalAddress}</p>
            <p className={styles.right}>{activeRow?.legalAddress}</p>
          </div>
        )}
        {activeRow?.address && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.actualAddress}</p>
            <p className={styles.right}>{activeRow?.address}</p>
          </div>
        )}
        {activeRow?.bankName && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.bankName}</p>
            <p className={styles.right}>{activeRow?.bankName}</p>
          </div>
        )}
        {activeRow?.bankAccount && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.rs}</p>
            <p className={styles.right}>{activeRow?.bankAccount}</p>
          </div>
        )}
        {activeRow?.bankBik && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.bik}</p>
            <p className={styles.right}>{activeRow?.bankBik}</p>
          </div>
        )}
        {activeRow?.bankCorAccount && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.cr}</p>
            <p className={styles.right}>{activeRow?.bankCorAccount}</p>
          </div>
        )}
        {activeRow?.contract && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.contract}</p>
            <Button
              id="downloadContract"
              small
              secondary
              onClick={() => {
                mainApi.downloadFile({
                  url: activeRow?.contract,
                  name: localized.contract + ".doc",
                });
              }}
            >
              {localized.download}
            </Button>
          </div>
        )}
        {activeRow?.card && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.organizationCard}</p>
            <Button
              id="downloadOrganizationCard"
              small
              secondary
              onClick={() => {
                mainApi.downloadFile({
                  url: activeRow?.card,
                  name: localized.organizationCard + ".doc",
                });
              }}
            >
              {localized.download}
            </Button>
          </div>
        )}
        {activeRow?.status && (
          <div className={styles.row}>
            <p className={styles.left}>{localized.status}</p>
            <p className={styles.right}>{translateCodes(activeRow?.status)}</p>
          </div>
        )}
      </div>

      <div className={styles.table}>
        <div className={styles.tableContainer}>
          <div className={styles.headline}>{localized.dealerManagers}</div>
          <Table
            left={true}
            columns={[
              {
                title: "ID",
                key: "id",
                type: "string",
              },
              {
                title: localized.name,
                key: "firstName",
                type: "string",
              },
              {
                title: localized.lastName,
                key: "lastName",
                type: "string",
              },
              {
                title: localized.middleName,
                key: "middleName",
                type: "string",
              },
              {
                title: localized.position,
                key: "position",
                type: "string",
              },
              {
                title: localized.phone,
                key: "phone",
                type: "string",
              },
              {
                title: localized.email,
                key: "email",
                type: "string",
              },
              {
                title: localized.role,
                key: "role",
                type: "string",
              },
              {
                title: localized.status,
                key: "status",
                type: "string",
              },
            ]}
            rows={newManagers()}
            noData={localized.noData}
          />
        </div>
      </div>

      {role === "ADMIN" && activeRow?.status === "APPROVED" && (
        <div className={styles.table}>
          <div className={styles.tableContainer}>
            <div className={styles.headline}>{localized.tiberiumTitle}</div>
            <div className={styles.elements}>
              <div className={styles.element}>
                <Input
                  id="apiLogin"
                  value={apiLogin}
                  label={localized.login}
                  error={!apiLoginValid}
                  errorMessage={localized.errorEmpty}
                  length={50}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setApiLogin(e);
                    setApiLoginValid(true);
                  }}
                  onBlur={() => {
                    setApiLoginValid(validateLength(apiLogin, 0, true));
                  }}
                />
              </div>

              <div className={styles.element}>
                <Input
                  id="apiPassword"
                  value={apiPassword}
                  label={localized.password}
                  error={!apiPasswordValid}
                  errorMessage={localized.PASSWORD_NOT_VALID}
                  length={50}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setApiPassword(e);
                    setApiPasswordValid(true);
                  }}
                  onBlur={() => {
                    setApiPasswordValid(validatePassword(apiPassword));
                  }}
                />
              </div>
              <div className={styles.element}>
                <Button
                  id="get"
                  onClick={() => {
                    dispatch(fetchTiberiumData({ id: activeRow?.id }));
                  }}
                >
                  {localized.get}
                </Button>
              </div>
              <div className={styles.element}>
                <Button
                  id="copy"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const tempInput = document.createElement("textarea");
                    tempInput.value = `apiLogin: ${apiLogin}, apiPassword: ${apiPassword}`;
                    dispatch(setInfo(localized.copySuccess));
                    document.body.appendChild(tempInput);
                    tempInput.select();
                    document.execCommand("copy");
                    document.body.removeChild(tempInput);
                  }}
                >
                  {localized.copy}
                </Button>
              </div>
              <div className={styles.element}>
                <Button
                  id="update"
                  onClick={() => {
                    validateData();
                  }}
                >
                  {localized.update}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={styles.buttonWrapper}>
        {role !== "DEALER_EMPLOYEE" && (
          <>
            <div className={styles.els}>
              <div className={styles.element}>
                <Button
                  id="change"
                  onClick={() => {
                    dispatch(setDealer(activeRow));
                    dispatch(setPageDealers("change"));
                    navigate("/?p=dealers&i=change");
                  }}
                >
                  {localized.change}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Popup>
  );
};

export default DealersPopup;
