import React, { FC } from "react";
import styles from "./ReportDeliveryPopup.module.scss";
import localized from "helpers/localized";
import moment from "moment";
import Popup from "kit/popup/Popup";

const ReportDeliveryPopup: FC = ({ show, setOpenPopup, activeRow }) => {
  return (
    <Popup
      show={show}
      closeText={localized.close}
      infoText={localized.info}
      close={setOpenPopup}
    >
      <div className={styles.content}>
        <h1>{localized.report}</h1>
        <div className={styles.rows}>
          <div className={styles.row}>
            <p className={styles.left}>{localized.orderNumber}</p>
            <p className={styles.right}>{activeRow.string1}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.status}</p>
            <p className={styles.right}>{activeRow.string2}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.created}</p>
            <p className={styles.right}>
              {moment(activeRow.datetime1).format("DD.MM.YYYY, HH:mm")}
            </p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.changed}</p>
            <p className={styles.right}>
              {moment(activeRow.datetime2).format("DD.MM.YYYY, HH:mm")}
            </p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.deliveryID}</p>
            <p className={styles.right}>{activeRow.string3}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{localized.costDelivery}</p>
            <p className={styles.right}>{activeRow.string4}</p>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default ReportDeliveryPopup;
