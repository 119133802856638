import axios from "axios";

export const dealersApi = {
  dealers({
    page = 1,
    id = "",
    inn = "",
    kpp = "",
    ogrn = "",
    legalAddress = "",
    address = "",
    name = "",
    status = "",
    bankName = "",
    bankAccount = "",
    bankCorAccount = "",
    bankBik = "",
    token,
  }) {
    return axios({
      url: process.env.REACT_APP_REST_API + `dealers`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      params: {
        offset: (page - 1) * 10,
        id,
        inn,
        kpp,
        ogrn,
        legalAddress,
        address,
        name,
        bankName,
        bankAccount,
        bankCorAccount,
        bankBik,
        status: status.value,
      },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  allDealers({ token }) {
    return axios({
      url: process.env.REACT_APP_REST_API + `dealers`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      params: {
        offset: 0,
        limit: 300,
      },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  dealerApprove({ id, token }) {
    return axios({
      url: process.env.REACT_APP_REST_API + `dealers/${id}/moderation/approve`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  dealerReject({ id, comment, token }) {
    const formData = new FormData();
    formData.append("comment", comment);
    return axios({
      url: process.env.REACT_APP_REST_API + `dealers/${id}/moderation/reject`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: formData,
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  tiberiumData({ id, token }) {
    return axios({
      url: process.env.REACT_APP_REST_API + `dealers/${id}/credentials`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  newTiberiumData({ id, apiLogin, apiPassword, apiCode, apiUrl, token }) {
    const formData = new FormData();
    formData.append("apiLogin", apiLogin);
    formData.append("apiPassword", apiPassword);
    formData.append("apiCode", apiCode);
    formData.append("apiUrl", apiUrl);
    return axios({
      url: process.env.REACT_APP_REST_API + `dealers/${id}/credentials`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: formData,
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  newDealer({
    inn,
    kpp,
    ogrn,
    legalAddress,
    address,
    name,
    bankName,
    bankAccount,
    bankCorAccount,
    bankBik,
    userIds,
    contract,
    card,
    token,
  }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("inn", inn);
    formData.append("ogrn", ogrn);
    formData.append("kpp", kpp);
    if (address) formData.append("address", address);
    if (legalAddress) formData.append("legalAddress", legalAddress);
    if (bankName) formData.append("bankName", bankName);
    if (bankAccount) formData.append("bankAccount", bankAccount);
    if (bankCorAccount) formData.append("bankCorAccount", bankCorAccount);
    if (bankBik) formData.append("bankBik", bankBik);
    if (contract) formData.append("contract", contract);
    if (card) formData.append("card", card);

    if (userIds.length) {
      for (let i = 0; i < userIds.length; i++) {
        formData.append("userIds[]", userIds[i]);
      }
    }

    return axios({
      url: process.env.REACT_APP_REST_API + "dealers",
      method: "POST",
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  changeDealer({
    id,
    inn,
    kpp,
    ogrn,
    legalAddress,
    address,
    name,
    bankName,
    bankAccount,
    bankCorAccount,
    bankBik,
    userIds,
    contract,
    card,
    token,
  }) {
    const formData = new FormData();
    if (inn) formData.append("inn", inn);
    if (kpp) formData.append("kpp", kpp);
    if (ogrn) formData.append("ogrn", ogrn);
    formData.append("legalAddress", legalAddress);
    formData.append("address", address);
    if (name) formData.append("name", name);
    formData.append("bankName", bankName);
    formData.append("bankAccount", bankAccount);
    formData.append("bankCorAccount", bankCorAccount);
    formData.append("bankBik", bankBik);
    if (contract) formData.append("contract", contract);
    if (card) formData.append("card", card);

    for (let i = 0; i < userIds.length; i++) {
      formData.append("userIds[]", userIds[i]);
    }

    return axios({
      url: process.env.REACT_APP_REST_API + "dealers/" + id,
      method: "POST",
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },

  dealerInfo({ id, token }) {
    return axios({
      url: process.env.REACT_APP_REST_API + "dealers/" + id,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return error;
      });
  },
};
