import localized from "helpers/localized";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setStep, setLogin, setToken } from "./LoginSlice";
import { setError, setInfo, setLoading } from "modules/main/store/MainSlice";
import { checkPage, fetchData } from "modules/main/store/MainAC";
import { loginApi } from "./LoginApi";
import { setTokenCode } from "./LoginSlice";
import { errorCodes } from "helpers/functions";

export const fetchLoginStep1 = createAsyncThunk(
  "fetchLoginStep1",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await loginApi.login(data);
      thunkAPI.dispatch(setLoading(false));
      if (response?.data?.data?.token) {
        thunkAPI.dispatch(setLogin(data));
        thunkAPI.dispatch(setTokenCode(response.data.data.token));
        thunkAPI.dispatch(setStep("step2"));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchResendLogin = createAsyncThunk(
  "fetchResendLogin",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await loginApi.login({
        login: thunkAPI?.getState()?.login?.login,
        password: thunkAPI?.getState()?.login?.password,
      });
      thunkAPI.dispatch(setLoading(false));

      if (response?.data?.data?.token) {
        thunkAPI.dispatch(setTokenCode(response.data.data.token));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchLoginStep2 = createAsyncThunk(
  "fetchLoginStep2",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await loginApi.sendCode({
        ...data,
        token: thunkAPI?.getState()?.login?.tokenCode,
      });
      thunkAPI.dispatch(setLoading(false));

      if (response?.data?.data?.token) {
        localStorage.setItem("token", response.data.data.token);
        thunkAPI.dispatch(setToken(response.data.data.token));
        await thunkAPI.dispatch(fetchData());
        await thunkAPI.dispatch(checkPage());
        thunkAPI.dispatch(setTokenCode(""));
        thunkAPI.dispatch(setStep("step1"));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);

export const fetchNewPassword = createAsyncThunk(
  "fetchNewPassword",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await loginApi.newPassword(data);
      thunkAPI.dispatch(setLoading(false));
      if (response?.data?.data === null) {
        thunkAPI.dispatch(setInfo(localized.enterNewPass));
        thunkAPI.dispatch(setStep("step1"));
      } else {
        thunkAPI.dispatch(
          setError(errorCodes(response?.response?.data?.error)),
        );
      }
    } catch (e: any) {
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setError(localized.errorCommon));
    }
  },
);
