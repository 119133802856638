import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  downloadedFile: false,
};

export const SupportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    setDownloadedFile: (state, action) => {
      state.downloadedFile = action.payload;
    },
  },
});

export const { setDownloadedFile } = SupportSlice.actions;

export default SupportSlice.reducer;
