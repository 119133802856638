import React, { FC } from "react";
import styles from "./AddingProduct.module.scss";
import localized from "helpers/localized";
import Button from "kit/button/Button";
import { useDispatch } from "react-redux";
import { fetchExcelProduct } from "../store/ProductsAC";
import Drop from "kit/drop/Drop";
import { productsApi } from "../store/ProductsApi";
import { ReactComponent as DownloadIcon } from "assets/icons/Download.svg";

const AddingProductExcel: FC = ({ setOpenExcel }) => {
  const dispatch = useDispatch();

  const fileSelected = async (e: any) => {
    if (e.target.files?.length) {
      dispatch(fetchExcelProduct(e.target.files[0]));
    }
  };

  const fileDragAndDrop = async (file: any) => {
    if (file) {
      dispatch(fetchExcelProduct(file));
    }
  };

  const inputFileRef = React.useRef();

  return (
    <div className={styles.wrapper}>
      <Drop upload={fileDragAndDrop} />
      <div className={styles.block}>
        <div className={styles.header}>
          <p className={styles.contentLabel}>{localized.addingExcel}</p>
          <div
            className={styles.close}
            onClick={() => {
              setOpenExcel(false);
            }}
          >
            <p>{localized.close.toLowerCase()}</p>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.textWrapper}>
            <div className={styles.text}>{localized.downloadExcel}</div>
          </div>
          <div className={styles.rows}>
            <div className={styles.elem}>
              <Button
                id="downloadTemplate"
                onClick={() => {
                  productsApi.getExcelFile();
                }}
              >
                {localized.downloadTemplate}
              </Button>
            </div>

            <div className={styles.wrapperFile}>
              <Button
                id="uploadFile"
                secondary
                onClick={() => {
                  inputFileRef.current.click();
                }}
              >
                <div className={styles.downloadText}>
                  {localized.uploadFile}
                </div>
                <div className={styles.upload}>
                  <DownloadIcon fill={"#000000"} />
                </div>
              </Button>
              <input
                id="upload-file"
                className={styles.file}
                type="file"
                name="upload-file"
                value=""
                ref={inputFileRef}
                onChange={fileSelected}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddingProductExcel;
