import React, { FC, useEffect, useState } from "react";
import styles from "./LoginPageStep2.module.scss";
import Button from "kit/button/Button";
import Input from "kit/input/Input";
import localized from "helpers/localized";
import { useDispatch } from "react-redux";
import { fetchLoginStep2, fetchResendLogin } from "modules/login/store/LoginAC";
import { validateLengthEquals } from "helpers/validators";

const LoginPageStep2: FC = () => {
  const dispatch = useDispatch();

  const [code, setCode] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [codeValid, setCodeValid] = useState(true);

  const validateData = async () => {
    setCodeValid(validateLengthEquals(code, 4, true));

    if (validateLengthEquals(code, 4, true)) {
      dispatch(fetchLoginStep2({ code }));
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) setSeconds((seconds) => seconds - 1);
    }, 1000);
    return () => clearInterval(timer);
  });

  return (
    <>
      <div className={styles.title}>{localized.confirmationTitle}</div>
      <div className={styles.elem}>
        <Input
          id="code"
          error={!codeValid}
          errorMessage={localized.codeError}
          value={code}
          label={localized.code}
          allowed="numbers"
          length={4}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setCode(e);
            setCodeValid(true);
          }}
          onBlur={() => {
            setCodeValid(validateLengthEquals(code, 4, true));
          }}
        />
      </div>
      <div className={styles.textCode}>
        {seconds
          ? localized.left +
            " " +
            seconds +
            " " +
            localized.secondsEnter.toLowerCase()
          : localized.codeAgain}
      </div>
      <div className={styles.element}>
        <Button
          id="confirm"
          onClick={() => {
            validateData();
          }}
        >
          {localized.confirm}
        </Button>
      </div>
      <div className={styles.elem}>
        <Button
          id="resend"
          onClick={() => {
            dispatch(fetchResendLogin());
            setSeconds(60);
          }}
        >
          {localized.resend}
        </Button>
      </div>
    </>
  );
};

export default LoginPageStep2;
